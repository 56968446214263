@import 'node_modules/@syncfusion/ej2-angular-navigations/styles/material.css';
@import 'node_modules/@syncfusion/ej2-base/styles/material.css';
@import 'node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import 'node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import 'node_modules/@syncfusion/ej2-angular-grids/styles/material.css';
@import 'node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import 'node_modules/@syncfusion/ej2-angular-dropdowns/styles/material.css';
@import 'node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import 'node_modules/@syncfusion/ej2-popups/styles/material.css';
@import 'node_modules/@syncfusion/ej2-lists/styles/material.css';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root{
    --primary-color : #C70039;
    --site-family : "Poppins", sans-serif;
    --text-color : #242424;
    --heading-color : #070B1C;
    --dark-color : #000000;
    --light-color : #ffffff;
    --bg-light: #FAFAFA;
    --voilet-color : #502488;
    --input-bg : #FDFDFD;
    --input-border : 1px solid #EBEBEB;
    --placeholder-input : #A9A9A9;
    --green-color: #0DB95D;
    --pink-color: #F7545F;
    --orange-color : #F9521D;
    --blue-color : #4E7D98;
    --yellow-color : #FF8A00;
    --menu-light : #aeaeae;
    --delete-color : #FF4848;
    --table-bg-box : #F6F6F6;
    --winners : #0aafc4;
}

*,html,body{
  box-sizing: border-box;
}
html,body{
    margin: 0;
    padding: 0;
}
body{
    font-family: var(--site-family);
}
.container, .container-new{
    max-width: 1300px !important;
    margin: auto;
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x)* 0.5);
    padding-left: calc(var(--bs-gutter-x)* 0.5);
}
.containers .row , .container-new .row{
  width: 100% !important;
}
.btn.custom-btn{
    padding: 15px 40px;
    font-size: clamp(0.8125rem, 0.7128rem + 0.3191vw, 1rem);
    color: var(--light-color);
    background-color: var(--voilet-color);
}
.btn.custom-btn.greenBtn{
    background-color: var(--green-color);
}
.btn.custom-btn.orangeBtn{
    background-color: var(--orange-color) !important;
}
.btn.custom-btn.blueBtn{
    background-color: var(--blue-color) !important;
}
.btn.custom-btn.lightBtn{
    background-color: #f2f2f2;
    color: var(--heading-color);
}
.btn.custom-btn.pinkBtn{
    background-color: var(--pink-color);
}
.btn.custom-btn:hover,.btn.custom-btn:focus{
    color: var(--light-color);
    background-color: var(--primary-color);
    box-shadow: none;
}
.light-text{
    font-weight: 300;
}
.sm-voilet-btn{
    font-size: clamp(0.8125rem, 0.7793rem + 0.1064vw, 0.875rem);
    font-weight: 500;
    color: var(--light-color);
    background-color: var(--voilet-color);
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    text-decoration: none;
}
.blue-txt{
    color: var(--blue-color) !important;
}
.green-txt{
    color: var(--green-color) !important;
}
.yellow-txt{
    color: var(--yellow-color) !important;
}
.pink-txt{
    color: var(--pink-color) !important;
}
.voilet-txt{
    color: var(--voilet-color) !important;
}

.blue-bg{
    color: white !important;
    background-color: var(--blue-color) !important;
}
.green-bg{
    color: white !important;
    background-color: var(--green-color) !important;
}
.yellow-bg{
    color: var(--heading-color) !important;
    background-color: var(--yellow-color) !important;
}
.pink-bg{
    color: white !important;
    background-color: var(--pink-color) !important;
}
.voilet-bg{
    color: white !important;
    background-color: var(--voilet-color) !important;
}
.login-register-action .btn-text {
    display: block;
}
.login-register-action .btn-icon {
    display: none;
}
.sm-voilet-btn:hover, .sm-voilet-btn:focus{
    box-shadow: none;
    border: none;
    background-color: var(--primary-color);
    color: var(--light-color);
}
.custom-field{
    font-size: clamp(0.8125rem, 0.7793rem + 0.1064vw, 0.875rem);
    padding: 15px 20px;
    background-color: var(--input-bg);
    border: var(--input-border);
}


.menu-link{
    font-size: clamp(0.8125rem, 0.7128rem + 0.3191vw, 1rem);
    color: var(--heading-color);
    text-decoration: none;
    padding: 40px 20px;
    font-family: inherit;
    font-weight: 500;
    display: flex;
    position: relative;
    align-items: center;
}
.banner-title {
    font-size: clamp(1.875rem, 1.5426rem + 1.0638vw, 2.5rem);
    font-weight: bold;
    color: var(--heading-color);
}
/* Header & Nav Style Start */
.top-navbar{
    background: var(--light-color);
    position: relative;
}
.top-navbar .brand-logo .brand-image {
  max-width: 200px !important;
  width: calc(100% - 0px);
  max-height: 100px;
  object-fit: contain;
  object-position: left;
}
.video-slider .video-card.ng-star-inserted p {
  max-width: 300px;
  white-space: nowrap;
  margin: auto;
  text-overflow: ellipsis;
  overflow: hidden;
}
.noteabout-us p {
  font-size: 14px;
  line-height: 24px;
}
.campBoxContent .sub-para {
  font-size: 15px;
  line-height: normal;
  color: var(--text-color);
}
.campBoxContent .sub-para span.readTxt {
  color: var(--voilet-color);
  cursor: pointer;
}

.top-navbar .primary-menu .primary-menu-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 15px;
}
.top-navbar .primary-menu .primary-menu-list .list-group-item{
    padding: 0px;
    border: none;
    position: unset;
}
.top-navbar .primary-menu .primary-menu-list .list-group-item a{
    font-size: clamp(0.8125rem, 0.5793rem + 0.7463vw, 0.9375rem);
    color: var(--menu-light);
    text-decoration: none;
    padding: 40px 20px;
    font-family: inherit;
    font-weight: 500;
    display: flex;
    position: relative;
    align-items: center;
    text-transform: capitalize;
}
.top-navbar .primary-menu .primary-menu-list .list-group-item a.active, .top-navbar .primary-menu .primary-menu-list .list-group-item a:hover{
    color: var(--voilet-color);
}
.top-navbar .primary-menu .primary-menu-list .list-group-item a.parent-link i.bi{
    font-size: 10px;
}
.top-navbar .all-top-action-menu {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-end;
}
.top-navbar .search-box {
    position: absolute;
    background-color: var(--light-color);
    box-shadow: 0px 8px 27px -9px rgb(179 179 179 / 20%);
    border: 1px solid var(--bg-light);
    min-width: 260px;
    right: 0;
    top: 150%;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    transition: all 400ms ease-in-out;
}
.top-navbar .search-box.view-popup{
    top: 100%;
    opacity: 1;
    visibility: visible;
    z-index: 1;
    transition: all 400ms ease-in-out;
}
.top-navbar .search-btn .search-action-btn{
    border: none;
    padding: 0;
}
.top-navbar .search-box .search-form {
    padding: 15px;
    position: relative;
}
.top-navbar .toggle-menu {
    display: none;
}
.top-navbar .toggle-menu .toggle-action-btn {
    border: none;
}
.top-navbar .sub-child-menu .sub-menu-image {
    max-width: 30px;
    min-width: 30px;
    max-height: 30px;
    min-height: 30px;
    object-fit: contain;
    object-position: center;
    margin-right: 10px;
}
.top-navbar .sub-child-menu {
    background: rgba(46, 13, 88, .8);
    position: absolute;
    width: 100%;
    left: 50%;
    top: 100%;
    z-index: 1;
    backdrop-filter: blur(10px);
    max-width: 880px;
    margin: auto !important;
    transform: translateX(-50%);
}
.top-navbar .primary-menu .primary-menu-list .list-group-item .sub-child-menu .sub-menu-link{
    color: white;
    font-size: 14px;
    padding: 15px 0px 0px;
}
.top-navbar .primary-menu .primary-menu-list .list-group-item .sub-child-menu .sub-menu-link:hover{
    text-decoration: underline;
}
.top-navbar .primary-menu .primary-menu-list .list-group-item .sub-child-menu .sub-menu-link:last-child{
    padding-bottom: 30px;
}
.top-navbar .primary-menu .primary-menu-list .list-group-item .sub-child-menu .sub-menu-link:first-child{
    padding-top: 30px;
}
.have-child .sub-child-menu{
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    top: 150%;
    transition: all 400ms linear;

}
.content-extra-menu .extra-menu-box-images {
    width: 100%;
    max-height: 180px;
    min-height: 180px;
    object-fit: cover;
    object-position: center;
    margin-bottom: 20px;
}
.extra-menu-box-addon {
    padding: 20px 0px;
}

/* Header & Nav Style End */

/* Auth Login and Register */
.auth-form-secrtion{
    padding: 80px 20px;
    /* background: url('../src/assets/images/login-bg.png'); */
    background-size: cover;
    min-height: 85vh;
    height: 100%;
    align-items: center;
}
.page-main-title{
    font-size: clamp(1.875rem, 1.3431rem + 1.7021vw, 2.875rem);
    color: var(--heading-color);
    font-weight: bold;
    text-transform: capitalize;
}
.page-main-title .helloEmoji {
    max-width: 42px;
}
.sub-main-line{
    font-size: clamp(1.125rem, 0.859rem + 0.8511vw, 1.625rem);
    color: var(--heading-color);
}
.side-auth-form .form-link {
    font-size: 14px;
    display: block;
    margin: 10px 0px 20px;
    text-align: right;
    text-decoration: none;
    color: var(--heading-color);
}
.side-auth-form .form-link:hover{
    color: var(--primary-color);
}
/* Auth Login and Register End */

.hero-banner-section {
    /* background: url('../src/assets/images/herobanner/bannerimage.png'); */
    padding: 150px 20px 150px;
    background-size: cover;
    background-position: center;
}
.hero-banner-section .banner-title {
    font-size: clamp(1.875rem, 1.5426rem + 1.0638vw, 2.5rem);
    color: var(--light-color);
    margin-bottom: 20px;
    font-weight: bold;
}
.banner-desc{
    font-size: clamp(0.875rem, 0.6755rem + 0.6383vw, 1.25rem);
    color: var(--light-color);
}
.hero-banner-section .banner-fill-btn{
    margin-right: 12px;
}
.hero-banner-section .banner-outline-btn{
    margin-left: 12px;
    background-color: transparent;
    border: 1px solid var(--light-color);
}
.hero-banner-section .banner-outline-btn:hover{
    background-color: var(--light-color);
    color: var(--heading-color);
}
.hero-banner-section .banner-action-btn{
    margin-top: 60px;
}
.about-section{
    background-color: var(--bg-light);
}
.why-image-content{
    padding: 80px 50px;
}
.section-title{
    font-size: clamp(1.5625rem, 0.7314rem + 2.6596vw, 3.125rem);
    color: var(--heading-color);
    font-weight: bold;
    text-transform: capitalize;
}
.section-para{
    font-size: clamp(0.875rem, 0.6786rem + 0.3571vw, 1rem);
    color: var(--text-color);
    line-height: 29px;
    margin: 30px 0px;
}
.services-slider-section {
    position: relative;
    background: linear-gradient(90deg, #714630 30%, var(--bg-light) 10%);
    padding: 40px 40px;
}
.services-slider-section:before{
    /* content: url('../src/assets/images/down-arrow.png'); */
    position: absolute;
    top: -40px;
    right: 180px;
    width: 130px;
}
.services-slider-section .swiper {
    width: 100%;
    height: 100%;
    overflow-y: visible !important;
}
.services-slider-section .swiper-slide {
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.services-slider-section .service-slide-box {
    background: var(--light-color);
    box-shadow: 0px 4px 10px 1px rgb(0 0 0 / 5%);
    padding: 20px;
    text-align: center;
    margin: 0;
    width: 100%;
    min-height: 204px;
}
.services-slider-section .service-slide-box .service-name a {
    font-size: clamp(0.875rem, 0.6786rem + 0.3571vw, 1rem);
    text-decoration: none;
    color: var(--heading-color);
    font-weight: bold;
}
.services-slider-section .service-slide-box .service-name a:hover{
    color: var(--voilet-color);
}
.services-slider-section .service-slide-box .service-icon-img {
    max-width: 110px;
    min-width: 110px;
    max-height: 60px;
    object-fit: contain;
    min-height: 100px;
    margin-bottom: 10px;;
}
.arrow-move-slider {
    position: absolute;
    right: 110px;
    top: -10px;
    z-index: 1;
}
.arrow-move-slider .swiper-btn-motion:after {
    font-size: 25px;
    background: #C70039;
    padding: 12px 15px;
    color: white;
}
.arrow-move-slider .swiper-btn-motion.swiper-button-disabled:after {
    background: rgba(7, 11, 28, 22%);
}
.arrow-move-slider .swiper-button-prev{
    left: unset !important;
    right: 20px;
}
.arrow-move-slider .swiper-button-next{
    left: unset !important;
    right: unset !important;
}
.clients-sections {
    padding: 50px 20px;
    position: relative;
}
.client-logo-box {
    text-align: center;
}
.client-logo-box .client-logo-img {
    min-height: 130px;
    object-fit: contain;
    background: white;
    max-height: 130px;
    padding: 20px 15px;
}
.steps-sections {
    padding: 80px 50px 0px;
    background-color: var(--bg-light);
}

.steps-sections .section-top {
    max-width: 870px;
    margin: auto;
}
.steps-sections .all-steps-follow {
    margin: 100px 0px 0px;
}
.steps-sections .stepside-Content {
    padding: 70px 50px;
}
.steps-sections .linesDropDown1 {
    text-align: center;
    margin-top: -90px;
    margin-right: 270px;
}
.steps-sections .linesDropDown2 {
    text-align: center;
    margin-top: -40px;
    margin-right: 290px;
}

.steps-sections .linesDropDown3 {
    text-align: center;
    margin-top: -90px;
}
.information-sections .information-content{
    /* background: url('../src/assets/images/solution-page.png'); */
    background-size: cover;
    background-position: center;
    padding: 108px 100px;
}
.information-sections .information-content-box {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    align-content: center;
    position: relative;
}

.information-content-box:before {
    content: '';
    /* background: url('../src/assets/images/down-opp-blue.png'); */
    position: absolute;
    left: 10px;
    bottom: -100px;
    width: 110px;
    height: 105px;
    background-size: contain;
    background-repeat: no-repeat;
}
.information-sections .image-solution{
    display: flex;
    height: 100%;
}
.information-sections .image-solution img{
    object-fit: cover;
}
.information-sections .purple-stamp-sloution {
    position: absolute;
    top: 70px;
    left: -100px;
    max-width: 260px;
}
.information-sections .green-stamp-sloution {
    position: absolute;
    bottom: 20px;
    right: -100px;
    max-width: 290px;
    z-index: 1;
}

.nonprofit-sections .nonprofit-content{
    background-color:var(--bg-light);
    padding: 108px 100px;
}
.nonprofit-sections .nonprofit-image-solution {
    text-align: center;
    background: white;
    height: 100%;
    align-content: space-evenly;
}
.nonprofit-sections .nonprofit-solution-images {
    max-height: 550px;
    margin: auto;
}
.nonprofit-sections .multicurly-nonprofit.img-fluid {
    position: absolute;
    bottom: -60px;
    max-width: 120px;
    right: 60px;
}
.testimonial-section {
    padding: 80px 20px;
}
#feedbackSlider {
    margin: 50px 0px 0px;
}
#feedbackSlider .client-image {
    text-align: center;
}
#feedbackSlider .clientImages {
    max-width: 200px;
    margin: auto;
}
#feedbackSlider .carousel-control-prev {
    position: absolute;
    background: var(--heading-color);
    max-height: 70px !important;
    max-width: 45px;
    opacity: 1;
    bottom: 0 !important;
    top: unset;
    right: 52px;
    border-radius: 100px;
    left: unset;
    height: 46px;
}
#feedbackSlider .carousel-control-next {
    position: absolute;
    background: var(--heading-color);
    max-height: 70px !important;
    max-width: 45px;
    opacity: 1;
    bottom: 0 !important;
    top: unset;
    right: 5px;
    border-radius: 100px;
    left: unset;
    height: 46px;
}
#feedbackSlider .carousel-control-prev-icon, #feedbackSlider .carousel-control-next-icon {
    width: 20px;
}
.main-footer{
    padding: 80px 20px 50px;
     background: url('../src/assets/images/footerBg.png');
    background-size: cover;
}
.main-footer .footerline{
    font-size: 14px;
    margin: 20px 0px;
    color: var(--text-color);
}
.main-footer .footerLogo {
    width: 70%;
    margin-bottom: 20px;
}
.main-footer .social-footer-menu {
    justify-content: start;
    margin: 30px 0px 30px;
}
.main-footer .social-footer-menu a {
    font-size: 20px;
    color: rgba(95, 106, 112, 70%);
    margin-right: 20px;
}
.main-footer .social-footer-menu a:hover{
    color: var(--primary-color);
}
.main-footer .footer-title{
    font-size: clamp(1rem, 0.734rem + 0.8511vw, 1.5rem);
    font-weight: bold;
    margin-bottom: 30px;
}
.main-footer .footer-company-details {
    max-width: 90%;
}
.main-footer .footer-list a {
    font-size: clamp(0.8125rem, 0.7793rem + 0.1064vw, 0.875rem);
    display: block;
    text-decoration: none;
    color: var(--text-color);
    text-decoration: none;
    margin-bottom: 15px;
}
.main-footer .footer-list a:hover{
    color: var(--primary-color);
}
.main-footer .contact-list-ftr a{
    text-decoration: underline;
    color: var(--heading-color);
    font-style: italic;
    font-weight: 500;
}
.main-footer .contact-list-ftr a.none-underline-link {
    text-decoration: none !important;
}
.main-footer .otherCol {
    padding-left: 60px;
}
.main-footer .divider-ftr {
    border-color: #b5b5b5;
    border-width: 2px;
    margin: 30px 0px;
}
.register-process .image-boxes-register {
    /* background: url('../src/assets/images/slideRegister.png'); */
    min-height: 100vh;
    height: 100%;
    background-size: cover;
    background-position: center;
    padding: 20px;
}
.register-process .text-images {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    width: 80%;
}
.customTopLink{
    padding: 20px;
}
.optionForm{
    padding: 20px;
}
.notes-information{
    font-size: 10px;
    line-height: 18px;
    padding: 0px 20px;
}
.selection-form-register-choice .card-body-content {
    display: flex;
    justify-content: space-between;
}
.selection-form-register-choice .card-titles {
    font-size: clamp(1.125rem, 0.8923rem + 0.7447vw, 1.5625rem);
    color: var(--heading-color);
    font-weight: 500;
}
.selection-form-register-choice .card-tagline {
    font-size: 14px;
    margin-right: auto;
    color: var(--voilet-color);
    margin-bottom: 0px;
}
.sidecard-content {
    background: var(--bg-light);
}
.card-services-list {
    font-size: 14px;
    margin-top: 20px;
    color: var(--text-color);
}
.card-services-list ul li{
    margin-bottom: 5px;
}
.selection-form-register-choice .card-info {
    flex: auto;
}
.selection-form-register-choice .card-select-btn {
    margin-left: auto;
}
.selection-form-register-choice .card-images {
    margin-right: 20px;
    max-width: 178px;
}
.selection-form-register-choice .card-form {
    margin: 20px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #F6F6F6;
    background: white;
}
.pricing-hero-banner-section{
    padding: 80px 50px 330px;
    /* background: url('../src/assets/images/pricing/top-hero-banner.png'); */
    background-size: cover;
    background-position: center;
}
.pricing-hero-banner-section .banner-switch-btn{
    background: var(--light-color);
    width: auto;
    max-width: fit-content;
    margin: auto;
    padding: 10px;
    border-radius: 150px;
}
.pricing-hero-banner-section .banner-switch-btn .btn.custom-btn{
    border-radius: 100px;
    background-color: var(--bg-light);
    color: var(--heading-color);
    font-weight: 600;
    border: none;
}
.pricing-hero-banner-section .banner-switch-btn .btn.custom-btn.show{
    background: var(--orange-color);
    color: var(--light-color);
}
#month-offers, #annual-offers {
    margin-top: -280px;
    padding: 20px;
}
.price-list-card{
    position: relative;
    height: 100%;
    padding: 0px 0px 120px;
    background: var(--light-color);
    border-radius: 5px;
    box-shadow: -1px 15px 20px -3px #7d7d7d2b;
}
.price-table-footer{
    position: absolute;
    bottom: 20px;
    width: 100%;
    left: 0;
    padding: 0px 11px;
}
.price-table-footer .btn{
    width: 100%;
}
.price-tabsbuttons {
    background: linear-gradient(180deg, var(--bg-light) 95%, var(--light-color) 8%);
}
.price-tabsbuttons .price-list-card .cardType {
    font-size: clamp(1.125rem, 0.9255rem + 0.6383vw, 1.5rem);
    text-align: center;
    color: var(--heading-color);
}
.price-tabsbuttons .price-list-card .cardOffer-price {
    text-align: center;
    font-size: clamp(1.125rem, 0.5931rem + 1.7021vw, 2.125rem);
    font-weight: 600;
    color: var(--heading-color);
    margin: 20px 0px;
}
.price-tabsbuttons .price-list-card .offerPeriod {
    font-size: 11px;
    color: rgba(95, 106, 112, 70%);
    font-weight: 500;
}
.price-tabsbuttons .price-list-card .cardOffer-price.green-txt {
    color: var(--green-color);
}
.price-tabsbuttons .price-list-card .cardOffer-para {
    text-align: center;
    font-size: clamp(0.8125rem, 0.7793rem + 0.1064vw, 0.875rem);
    color: var(--text-color);
}
.price-tabsbuttons .price-listcard-header {
    padding: 20px 15px 1px;
    background: #f8f8f8;
    border-radius: 5px 5px 0px 0px;
}
.price-tabsbuttons .price-list-card-body {
    padding: 20px 15px 0px;
}
.price-tabsbuttons .price-list-card-body ul{
    padding-left: 15px;
}
.price-tabsbuttons .price-list-card-body ul li{
    margin-bottom: 15px;
    color: #363232;
    font-size: 14px;
}
.price-tabsbuttons .price-list-card-body .extra-services-offer {
    border-bottom: 1px solid #EBE7EF;
}
.price-tabsbuttons .blue-txt{
    color: var(--blue-color) !important;
}
.price-tabsbuttons .green-txt{
    color: var(--green-color) !important;
}
.price-tabsbuttons .yellow-txt{
    color: var(--yellow-color) !important;
}
.price-tabsbuttons .pink-txt{
    color: var(--pink-color) !important;
}
/* .price-tabsbuttons .price-list-card-body .main-offers-table-list li { */
    /* list-style-image: url('../src/assets/images/pricing/check.png'); */
/* } */
.price-tabsbuttons .noted-offer-line {
    text-align: center;
    font-weight: 500;
}
.light-green-btn {
    background-color: #ECFFEF;
    color: var(--green-color);
    font-weight: 500;
}
.light-blue-btn {
    background-color: #F1FDFE;
    color: var(--blue-color);
    font-weight: 500;
}
.light-yellow-btn {
    background-color: #FEF4E9;
    color: var(--yellow-color);
    font-weight: 500;
}
.light-pink-btn {
    background-color: #FFF1F2;
    color: var(--pink-color);
    font-weight: 500;
}
.price-list-card.mostPopular:before {
    content: 'Most Popular';
    width: auto;
    position: absolute;
    display: inline-block;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    background: var(--delete-color);
    border-radius: 0 0 5px 5px;
    color: white;
    padding: 1px 10px;
    font-size: 16px;
    font-weight: 500;
}
.price-list-card.mostPopular .price-listcard-header {
    padding: 30px 15px 1px;
}
.price-list-card.bestsale:before {
    content: 'Best Sale';
    width: auto;
    position: absolute;
    display: inline-block;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    background: #FF4848;
    border-radius: 0 0 5px 5px;
    color: white;
    padding: 1px 10px;
    font-size: 16px;
    font-weight: 500;
}
.price-list-card.bestsale .price-listcard-header {
    padding: 30px 15px 1px;
}
.star-fetaure {
    background: #F9F9F9;
    text-align: center;
    display: flex;
    justify-content: center;
    position: relative;
    flex-direction: column;
    padding: 20px;
    max-height: 100px;
    margin: 15px 0px;
}
.star-fetaure .star-image{
    position: absolute;
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 22px;
}
.star-fetaur .section-para{
    font-weight: 500;
    text-align: center;
    font-size: 14px;
}
.extra-curl-img {
    text-align: right;
}
.allanother-gold-features {
    padding: 80px 20px;
}
.vr-top-banner{
    padding: 130px 20px;
    text-align: center;
    background-color: var(--heading-color);
}
/* .vr-top-banner.contact-us-vr-banner{ */
    /* background: url('../src/assets/images/contact/top-bg.png'); */
/* } */
.icon-box-information-custom .icon-box-imgs {
    padding: 20px 23px;
    background: #F8FAFC;
    border-radius: 90px;
    margin-right: 20px;
    font-size: 26px;
    color: var(--orange-color);
}
.contact-infoText a{
    color: var(--heading-color);
    font-weight: 500;
    text-decoration: none;
}
.icon-box-information-custom .icon-box-imgs.greenColorIcon{
    color: var(--green-color);
}
.contact-form-section {
    padding: 80px 20px;
}
.msg {
    position: fixed;
    top: -100%;
    right: 10px;
    background: #ebfaf3;
    padding: 10px 30px;
    border-radius: 5px;
    color: #0db95d;
    box-shadow: 0px 9px 19px -12px;
    visibility: hidden;
    opacity: 0;
    transition: all 600ms linear;
}
.msg.showMsg{
    top: 10px;
    visibility: visible;
    opacity: 1;
    transition: all 600ms linear;
}
.map-section-form-box {
    max-height: 491px;
    background: #b0b0b0;
    position: relative;
    overflow: hidden;
}
.map-section-form-box:before{
    position: absolute;
    content: 'MAP VIEW';
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 50px;
    font-weight: 500;
    color: #dfdfdf;
}
.map-view-iframe {
    min-height: 490px;
    padding: 0;
    position: relative;
    width: 100%;
}
.sideContactBannerTitle {
    position: relative;
}
.sideContactBannerTitle:before{
    content: '';
    position: absolute;
    left: 70%;
    /* background: url('../src/assets/images/contact/spring-arrow.png'); */
    width: 90px;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
}
.about-hero-section {
    background: linear-gradient(90deg, #502488 80%, #F7F4FB 10%);
    padding: 80px 20px;
}
.left-about-content .blue-down-arrow-about {
    position: absolute;
    right: 60px;
    max-width: 100px;
}
.our-team-members-section{
    background: var(--bg-light);
    padding: 80px 20px;
}
.team-member-card {
    background: var(--light-color);
    padding: 20px;
    border: 1px solid #e4e4e4;
    border-radius: 5px;
    text-align: center;
    margin-bottom: 30px;
}
.sub-title {
    font-weight: 600;
    font-family: inherit;
}
.team-member-card .team-img{
    max-height: 300px;
    min-height: 300px;
    width: 100%;
    object-fit: cover;
    object-position: center;
    margin-bottom: 20px;
    border-radius: 10px;
}
.team-member-card .team-member-position {
    margin: 5px 0px 10px;
    color: var(--blue-color);
}
.social-team-contact h5{
    font-size: 14px;
}
.team-social-LI-link{
    font-size: 16px;
    color: var(--blue-color);
    text-decoration: none;
}
.steps-boxs {
    display: flex;
    align-items: center;
}
.about-steps {
    max-width: 150px;
}
.about-steps-para {
    font-size: 14px;
    line-height: 22px;
    padding: 40px 200px 40px 60px;
    position: relative;
}
.about-steps-para:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: url('../src/assets/images/aboutus/steps/section-steps-pink.png'); */
    background-size: 100% 100%;
    background-repeat: no-repeat;
    z-index: -1;
}
.count-about-number {
    font-size: clamp(1.875rem, 0.3125rem + 5vw, 3.125rem);
    padding: 20px;
    font-weight: 600;
    text-align: center;
}
.abt-stp-2, .abt-stp-4{
    padding: 40px 60px 40px 200px;
}
.abt-stp-2:before{
    /* background: url('../src/assets/images/aboutus/steps/section-steps-blue.png'); */
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.abt-stp-3:before{
    /* background: url('../src/assets/images/aboutus/steps/section-steps-green.png'); */
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.abt-stp-4:before{
    /* background: url('../src/assets/images/aboutus/steps/section-steps-voilet.png'); */
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.about-steps-section{
    padding: 80px 20px;
}
.allStepsAbout {
    margin: 50px 0px 0px;
}
.team-hero-section {
    background: linear-gradient(180deg, var(--bg-light) 90%, transparent 20%);
    padding: 80px 20px;
}
.memberBioDetailsImg {
    max-width: 220px;
    min-width: 220px;
    max-height: 260px;
    min-height: 260px;
    object-fit: cover;
    object-position: center;
}
.Team-member-img-linked {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0px 30px 20px 0px;
    float: left;
}
.Team.MemberBio {
    padding: 50px 20px;
}
.Team.MemberBio .member-info-name {
    display: flex;
}
.terms-hero-section{
    padding: 80px 20px;
    background: var(--voilet-color);
}
.terms-sections-list {
    padding: 80px 20px;
    background: var(--bg-light);
}
#terms-list-context a.list-group-item {
    border: none;
    background: transparent;
    font-size: 14px;
    color: var(--text-color);
    border-radius: 5px;
}
#terms-list-context a.list-group-item.active{
    background: var(--orange-color);
    color: var(--light-color);
}
.terms-scrollExample {
    padding: 30px;
    background: var(--light-color);
}
.terms-scrollExample .termsBox-txt {
    margin-bottom: 30px;
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 20px;
}
.terms-scrollExample  .terms-condition-list li {
    list-style: circle;
    margin: 20px 0px;
    font-size: clamp(0.875rem, 0.6786rem + 0.3571vw, 1rem);
    color: var(--text-color);
}
.addressContact {
    list-style: none !important;
    padding: 0;
    font-weight: 500;
    font-style: italic;
    color: var(--orange-color) !important;
}
.custom-menu-heading{
    font-size: 17px;
    margin-top: 20px;
    opacity: .8;
    margin-left: 5px;
}
#terms-list-context {
    z-index: 0 !important;
    top: 30px;
}
.features-hero-section{
    position: relative;
    padding: 80px 20px;
    background: var(--input-bg);
}
.features-hero-section:before{
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: url('../src/assets/images/login-bg.png'); */
    background-size: cover;
    background-position: center;
    opacity: .6;
    background-attachment: fixed;
}
.features-sections-list {
    padding: 80px 20px;
}
.features-card-box {
    padding: 20px;
    background: var(--bg-light);
    height: 100%;
    top: 0;
    position: relative;
    box-shadow: 0px 11px 30px 0px rgb(212 212 212 / 0%);
    transition: all 300ms ease-in-out !important;
}
.features-card-box:hover{
    background: var(--light-color);
    top: -20px;
    box-shadow: 0px 11px 30px 0px rgb(212 212 212 / 30%);
    transition: all 300ms linear !important;
}
.dark-banner-desc {
    font-size: clamp(0.875rem, 0.6755rem + 0.6383vw, 1.25rem);
    color: var(--dark-color);
}
.feater-body-modal {
    text-align: left;
}
.features-card-icon {
    max-width: 70px;
    min-width: 70px;
    min-height: 70px;
    max-height: 70px;
    object-fit: contain;
    object-position: center;
}
.features-box-column{
    margin-bottom: 30px;
}
/* .sidebarTopImage.w-100 {
    max-height: 400px;
    min-height: 400px;
    object-fit: contain;
    width: 100%;
} */
.our-team-members-section .team-member-card {
    position: relative;
    overflow: hidden;
    top: 0;
    box-shadow: unset;
    transition: all 400ms linear;
}
.our-team-members-section .team-member-card:hover{
    box-shadow: 0px 14px 20px -10px #e8e8e8;
    border: 1px solid #d0d0d0;
    top: -20px;
    transition: all 400ms linear;
}
.our-team-members-section .team-member-card:after {
    content: '';
    background: #f3f5f5;
    position: absolute;
    top: 120px;
    left: 0;
    width: 0;
    height: 100%;
    z-index: -1;
    transition: all 400ms linear;
}

.our-team-members-section .team-member-card:hover:after {

    width: 100%;
    transition: all 400ms linear;
}
/* Team Modal CSS */
.team-modal-preview {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 75%);
    top: 0;
    left: 0;
    display: flex;
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    transition: all 400ms linear;
}
.team-modal-preview.showingModal{
    visibility: visible;
    opacity: 1;
    z-index: 2;
    transition: all 400ms linear;
}
.team-modal-inside-content {
    max-width: 1080px;
    padding: 50px;
    background: var(--light-color);
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow-y: auto;

}
.modal-team-images {
    max-width: 200px;
    min-width: 200px;
    max-height: 250px;
    min-height: 250px;
    object-fit: cover;
    object-position: center;
    margin-bottom: 20px;
}
.close-team-modal {
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 30px;
    cursor: pointer;
    background: var(--dark-color);
    padding: 0px 8px;
    color: var(--input-bg);
}
.close-team-modal:hover{
    background: var(--primary-color);
}


.modal-paragraph p{
    font-size: 14px !important;
    font-size: 14px !important;
    line-height: 18px;
    margin: 15px 0px !important;
    color: var(--text-color);
    font-style: italic;
}
.modal-paragraph {
    max-height: 400px;
    overflow-y: auto;
}
.team-modal-name {
    font-size: 22px;
}
.team-memeber-info-box {
    display: flex;
}
.modal-paragraph p:first-child {
    margin-top: 0px !important;
}
.modal-division {
    margin: 0px 20px;
    border: 1px dashed var(--placeholder-input);
}
.terms-sections-list .lastline{
    list-style: none !important;
    font-weight: 600;
}
.topFeatures-icon-images{
    max-width: 150px;
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 15px 30px -10px rgba(0, 0, 0, .2);
}
.blur-drop-style-blue,.blur-drop-style-pink,.blur-drop-style-orange{
    position: relative;
    overflow: hidden;
}

.blur-drop-style-blue::before {
    content: '';
    background: var(--voilet-color) !important;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
}
.blur-drop-style-pink::before {
    content: '';
    background: #E91E63 !important;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
}
.blur-drop-style-purple::before {
    content: '';
    background: #804063 !important;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
}
.topheader-feature-relative{
    position: relative;
}
.single-card-imges {
    max-width: 1024px;
    margin: 30px auto;
    width: 100%;
    display: flex;
    /* box-shadow: 0px 11px 30px 0px rgb(255 255 255 / 23%); */
    border-radius: 10px;
    padding: 25px 20px;
    background: #f4f6f7;
}
.count-numbers {
    font-size: 18px;
    text-align: center;
}
.count-numbers span {
    background: lightblue;
    color: var(--light-color);
    display: block;
    box-sizing: content-box;
    height: 59px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    max-width: 60px;
    width: 100%;
    border-radius: 10px 30px 10px 30px;
}
.count-numbers span.voilet-bg {
    background: var(--voilet-color);
}
.count-numbers span.pink-bg {
    background: var(--primary-color);
}
.count-numbers span.green-bg {
    background: var(--green-color);
}
.cout-box-list {
    display: flex;
    justify-content: space-between;
    background: var(--bg-light);
    padding: 60px 50px 50px;
}
.cout-box-list .count-box{
    max-width: 30%;
    width: 100%;
    margin: 0px auto;
    position: relative;
}
.count-box:after{
    content: '';
    border: 1px dashed #c3c3c3;
    position: absolute;
    left: 50%;
    width: 100%;
    height: 1px;
    top: 16%;
    z-index: -1;
}
.cout-box-list .count-box:last-child:after{
    display: none;
}

.trialListPrice{
    list-style-type: none;
    list-style-image: none !important;
}
.processing-fees-sections {
    background: linear-gradient(180deg, var(--bg-light), transparent);
    padding: 80px 20px;
}
.calculation-box {
    background: white;
    padding: 20px;
    border: 1px solid #dfdfdf;
}
.amount-fees-field{
    position: relative;
    padding-left: 30px;
}
.amount-currency-symbol{
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}
.form-check-input{

    border-color: var(--blue-color);
}
.form-check-input:focus{
    box-shadow: none;
}
.form-check-input:checked{
    box-shadow: none;
    background-color: var(--green-color);
    border-color: var(--green-color);
}
.green-divider-line{
    border: 2px solid var(--blue-color);
    margin-top: 30px;
    margin-bottom: 20px;
}
.dtls-fees-row{
    max-width: 300px;
}
.dtls-fees-row {
    max-width: 320px;
    margin: 0px auto !important;
}
.img-fluid.userprofileImg {
    max-width: 40px;
    min-width: 40px;
    min-height: 40px;
    max-height: 40px;
    object-fit: cover;
    border-radius: 100px;
    object-position: center;
    margin-left: 15px;
}
.btn-icon-option{
    font-size: 10px;
    margin-left:5px;
    color: var(--heading-color);
}
.sm-sub-menu-list{
    max-width: 250px !important;
    left: unset !important;
}
.profile-submenu .sub-menu-link{
    color: white !important;
    font-size: 14px;
    padding: 15px 0px 0px;
    display: block;
    text-decoration: none !important;
}
.profile-submenu .sub-menu-link:last-child{
    padding-bottom: 20px;
}
.profile-submenu .sub-menu-link:hover{
    text-decoration: underline !important;
}
.dashboard-us-vr-banner{
    background: var(--bg-light);
    background-size:cover ;
    padding: 50px 20px;
}
.purple-block-background{
    padding: 50px 20px;
}
.purple-block-background:before{
    /* background: url('../src/assets/images/dasboard/management-consol-bg.png'); */
    opacity: 1;
}
.option-block .Option-small-title{
    font-size: clamp(0.8125rem, 0.5781rem + 0.75vw, 1rem);
    font-weight: 500;
    color: var(--heading-color);
    text-decoration: none;
    display: block;
}
.option-block {
    text-align: center;
    padding: 20px;
    background: var(--bg-light);
    min-height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}
.option-blck-imges {
    max-width: 100px;
    margin-bottom: 10px;
    min-height: 50px;
    max-height: 60px;
    object-fit: contain;
    object-position: center;
    width: 100%;
}
.section-category-blocks {
    padding: 00px 0px 50px;
    background: var(--bg-light);
}
.offer-cards {
    background: white;
    padding: 30px 20px 10px;
    margin-bottom: 30px;
    box-shadow: -1px 14px 20px -11px rgb(169 169 169 / 20%);
}
.option-block .Option-small-title:hover{
    text-decoration: underline;
}
.option-block.special-offers {
    position: relative;
}
.option-block.special-offers .special-offer-text {
    position: absolute;
    font-weight: 500;
    font-size: clamp(0.6875rem, 0.6094rem + 0.25vw, 0.75rem);
    top: 0;
    left: 10px;
    background: var(--pink-color);
    padding: 5px 12px;
    border-radius: 0px 0px 5px 5px;
    color: white;
}
.offers-card-options .sub-title {
    font-size: clamp(0.875rem, 0.5625rem + 1vw, 1.125rem);
}
.breadcrumb-header a{
    font-size: clamp(0.8125rem, 0.6563rem + 0.5vw, 0.9375rem);
    text-decoration: none;
    color: rgba(255, 255, 255, .70);
}
.breadcrumb-header li:before{
    color: var(--light-color) !important;
}
.breadcrumb-header .active{
    font-size: clamp(0.8125rem, 0.6563rem + 0.5vw, 0.9375rem);
    color: var(--light-color);
}

.loggedIn-active .top-navbar .primary-menu .primary-menu-list .list-group-item a {
    font-size: clamp(0.8125rem, 0.7128rem + 0.3191vw, .9rem);
    padding: 40px 11px;
}
.data-section {
    padding: 50px 20px;
}
.btn-apply {
    font-size: 17px;
    text-decoration: none;
    margin: 3px;
}
.table-preview-box {
    padding: 20px;
    background: var(--table-bg-box);
    border-radius: 5px;
    min-height: max-content;
    position: relative;
    padding-bottom: 120px;
}
.table-form-datalist{
    border-collapse: separate !important;
    border-spacing: 0 10px !important;
}

.table-form-datalist th{
    background: transparent;
    color: var(--light-color);
    font-size: 13px;
    font-weight: 500;
    padding: 12px 17px;
    position: relative;
}
.table-form-datalist th:after{
    content: '';
    background: var(--bg-light);
    opacity: .6;
    position: absolute;
    height: 70%;
    width: 1px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}
.table-form-datalist .main-table-header{
    background: var(--heading-color);
    border-radius: 10px;
    position: sticky;
    top: 0;
}
.table-form-datalist th ,.table-form-datalist td{
    vertical-align: middle;
    white-space: nowrap;
}
.table-form-datalist td{
    font-size: 14px;
    font-weight: 500;
    padding: 10px 17px;
}
.table-form-datalist tbody td{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
}
.table-form-datalist th:first-child ,.table-form-datalist td:first-child{
    border-radius:10px 0 0 10px;
    vertical-align: middle;
}
.table-form-datalist th:last-child , .table-form-datalist td:last-child{
    border-radius:0 10px 10px 0;
    border-right: none;
}
.table-form-datalist th:last-child:after{
    display: none;
}
.table-form-datalist .cost-value{
    white-space: nowrap;
}

.table-form-datalist .editbtn i{
    color: var(--green-color);
}

.table-form-datalist .winnersBtn {
    color: var(--winners);
    padding: 4px 10px;
    background: #5024880d;
    border-radius: 4px;
    font-size: 16px;
}
.table-form-datalist .drawBtn {
    color: #502488;
    padding: 4px 10px;
    background: #5024880d;
    border-radius: 4px;
    font-size: 16px;
}
.btn-apply.winnersBtn {
    color: var(--winners);
    padding: 4px 10px;
    background: #5024880d;
    border-radius: 4px;
    font-size: 16px;
}
.btn-apply.drawBtn {
    color: #502488;
    padding: 4px 10px;
    background: #5024880d;
    border-radius: 4px;
    font-size: 16px;
}
.table-form-datalist .deleteBtnList{
    margin-top: -2px;
}
.table-form-datalist .deleteBtnList i{
    color: var(--delete-color);
}
.addstoreBtn{
    margin-bottom: 1rem !important;
}
.tbl-ftr-pagination .pagination{
    margin-bottom: 0px;
}
.tbl-ftr-pagination .pagination .page-link {
    font-size: 15px;
    border: none;
    padding: 0px 5px;
    color: var(--heading-color);
}
.tbl-ftr-pagination .pagination .page-link:focus, .tbl-ftr-pagination .pagination .page-link:hover{
    box-shadow: none;
    background: transparent;
    color: var(--blue-color);
}
.table-form-datalist .tablefooter{
    position: absolute;
    left: 50%;
    bottom: 10px;
    transform: translateX(-50%);
    width: 96.5%;
    display: inline-table;
}
.table-shield-box{
    max-height: 770px;
}
.table-form-datalist .rowTableSelect {
    min-width: 90px;
}
.lightBtnText, .lightBtnText:hover {
    background: transparent;
    color: var(--heading-color);
    text-decoration: none;
}

.boldtext{
    font-weight: 500;
    font-size: 20px;
}
.lightBtnText i {
    color: var(--heading-color);
}
.lightBtnText:hover i {
    color: var(--pink-color);
}
.form-lable-update{
    font-size: 13px;
    margin-bottom: 5px;
    font-style: italic;
}
.store-logo-box {
    font-size: clamp(0.8125rem, 0.7793rem + 0.1064vw, 0.875rem);
    padding: 0px 20px;
    background-color: var(--input-bg);
    border: var(--input-border);
    border-radius: 5px;
}
#StorelogoImage {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    opacity: 0;
}
.uploadFileImgStoreLogo {
    max-width: 230px;
    width: 100%;
    min-height: 200px;
    max-height: 200px;
    object-fit: contain;
    margin: auto;
}
.deleteBtnList i {
    color: var(--delete-color);
}
.store-logo-box .deleteBtnList{
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1;
}
.main-table-body td.descrition-text {
    max-width: 300px;
}
#accordionFlushVideo .video-box {

    display: none;
}
#accordionFlushVideo .video-box .videoPrint{
    height: 100%;
    width: 100%;
    max-height: 400px;
}
#accordionFlushVideo .video-box.showVideo{
    display: block;
}
#accordionFlushVideo .steps-order {
    margin-bottom: 10px;
    padding: 5px;
    font-size: 13px;
}
#accordionFlushVideo .steps-order li{
    margin-bottom: 15px;
}

#accordionFlushVideo .custom-accordion.collapsed{
    background: var(--bg-light);
    color: var(--heading-color);
    text-transform: capitalize;
}

#accordionFlushVideo .custom-accordion{
    background: var(--blue-color);
    color: var(--light-color);
}
#accordionFlushVideo .custom-accordion.collapsed:after{
    filter: brightness(0);
}
#accordionFlushVideo .custom-accordion:after{
    filter: brightness(110.5);
}
#accordionFlushVideo .custom-accordion:focus{
    box-shadow: none;
}
#accordionFlushVideo .vdo-link-txt .videoplyLink{
    font-size: 14px;
    color: var(--pink-color);
    text-decoration: none;
    background: transparent;
    padding: 0;
    border: none;
}
#accordionFlushVideo  .vdo-link-txt .videoplyLink:hover{
    color: var(--blue-color);
}
#accordionFlushVideo  .accordion-button.collapsed:last-child {
    margin-bottom: 0px;
}
#accordionFlushVideo .accordion-item{
    margin-bottom: 15px;
    border: none;
}
#accordionFlushVideo .accordion-collapse {
    border: none;
}
#accordionFlushVideo .accordion-body {
    background: #f8fcff;
}
#accordionFlushVideo .accordion-body .row.videoRow div:first-child{
    max-width: 100% !important;
    width: 100%;
}
.dollaricon{
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    color: var(--blue-color);
}
.accordion-button.custom-accordion {
    text-transform: capitalize;
}

/* Table Form */
.e-grid.table {
    border-color: #eaeaea;
    padding: 15px;
    background: #f6f6f6;
    border-radius: 10px;
}
.e-grid.table .e-content , .e-grid.table .e-gridcontent {
    background-color: transparent !important;
    background: transparent !important;
}
.e-grid.table .e-table.e-sortfilter {
    background: #070b1c;
    border-radius: 10px;
    border: none !important;
    box-shadow: none !important;
}
.e-grid.table .e-table .e-gridheader.e-lib.e-droppable {
    border: none !important;
}
.e-grid.table .e-table .e-headercell , .e-headercell .e-headertext{
    background: transparent !important;
    color: white !important;
}
.e-grid.table .e-table th.e-headercell {
    border-right: 1px solid #727272 !important;
}
.e-grid.table .e-table th.e-headercell:last-child {
    border: none !important;
}
.e-grid.table .e-table .e-headercell .e-sortfilterdiv.e-icons{
    color: white !important;
}
.e-grid.table .e-table {
    border-collapse: separate !important;
    border-spacing: 0 5px !important;
    background: transparent;
    background-color: transparent;
    padding: 0px;
    border-color: transparent !important;
}
.e-grid.table .e-table .e-row td{
    background: white;
    font-size: 14px;
    vertical-align: middle;
    font-weight: 400;
    border: none !important;
}

.e-grid.table .e-table .e-row td:first-child{
    border-radius: 10px 0px 0px 10px !important;
}
.e-grid.table .e-table .e-row td:last-child{
    border-radius: 0px 10px 10px 0px !important;
}
.e-grid.table .e-gridpager.e-control.e-pager.e-lib {
    padding: 5px;
}
.e-grid.table .e-link.e-numericitem.e-spacing.e-currentitem.e-active {
    background: white;
}
/* Table Form End */


/* Form Sync Style */
form ejs-textbox.e-textbox span.e-input-group.e-control-wrapper , form ejs-datepicker.ejs-datepicker span.e-input-group.e-control-wrapper, form ejs-daterangepicker.ejs-daterangepicker span.e-input-group.e-control-wrapper, form ejs-datetimepicker.e-datetimepicker span.e-input-group.e-control-wrapper, form ejs-dropdownlist.e-dropdownlist span.e-input-group.e-control-wrapper , form ejs-numerictextbox.ng-pristine span.e-input-group.e-control-wrapper , form ejs-numerictextbox.ng-untouched span.e-input-group.e-control-wrapper , form ejs-maskedtextbox span.e-input-group.e-control-wrapper, form ejs-multiselect .e-input-group.e-control-wrapper{
    font-size: clamp(0.8125rem, 0.7793rem + 0.1064vw, 0.875rem);
    padding: 5px 15px;
    background-color: #fdfdfd;
    border: 1px solid #EBEBEB;
    border-radius: 5px;
}

form small.e-error.ng-star-inserted {
    font-size: 13px;
    color: #ff4848;
}
form label.form-label , form label.form-check-label{
    font-size: 13px;
    margin-bottom: 5px;
    font-style: italic;
    color: #212529;
}
form .e-btn{
    font-size: clamp(0.8125rem, 0.7128rem + 0.3191vw, 1rem) !important;
    color: #fff !important;
    min-width: 192px;
    background: #502488 !important;
    padding-top: 9px;
    padding-bottom: 9px;
    text-align: center !important;
}
form .e-btn:hover, form .e-btn:focus, form .e-btn.e-info:hover , form .e-btn.e-info:focus{
    background-color: #C70039 !important;
}
form.ng-invalid .e-btn:hover, form.ng-invalid .e-btn:focus, form.ng-invalid .e-btn.e-info:hover, form.ng-invalid .e-btn.e-info:focus{
    background-color: #C70039 !important;
    border-color: #C70039 !important;
}
form .file-upload.ng-star-inserted .cus_drop-area.ng-star-inserted {
    border: none !important;
    margin: auto;
    border-radius: 10px;
    padding: 10px 20px 19px;
    font-size: 10px;
    width: 100%;
    line-height: 11px;
}
form .file-upload.ng-star-inserted .cus_drop-area.ng-star-inserted button.btn-danger.e-btn {
    position: absolute;
    top: 21px;
    right: 20px;
    max-width: 32px !important;
    width: 100%;
    text-align: center !important;
    padding: 1px 0px;
    border-radius: 38px !important;
    min-width: unset !important;
}
form .innter-tbl-box {
    border: 1px dashed #b7b7b7;
}



/* Draw Count Down */

.countdown ul {
    padding: 40px 20px;
    background: #f8f8f8;
    text-align: center;
    display: flex !important;
    justify-content: center;
    flex-wrap: wrap;
}
.countdown ul li {
    font-size: clamp(1rem, 0.6875rem + 1vw, 1.25rem) !important;
    display: block !important;
    max-width: 200px;
    min-width: 160px;
    width: 100%;
}
.countdown .e-info {
    background: #4d1e80 !important;
    color: #fff;
    border-radius: 10px !important;
    box-shadow: inset 0px -10px 13px 0px rgb(32 11 86) !important;
}
.countdown ul li span {
    display: block !important;
    max-width: 200px;
    width: 100%;
    margin: auto;
    font-size: clamp(1.875rem, -0.4688rem + 7.5vw, 3.75rem);
    font-weight: 600;
    font-family: monospace;
}
.countdown b.h1 {
    margin-bottom: 20px !important;
    display: block;
    font-weight: 700;
    color: #070b1c;
    text-align: left;
}


/* Draw Spinner Page */
.spinner {
  background: var(--bg-light);
  padding: 30px 20px;
}
.spinner-image {
    max-width: 290px;
    width: 100%;
    margin: 0px auto 15px;
}
table .e-rowcell.e-templatecell img{
    max-width: 80px;
    max-height: 60px;
    min-height: 60px;
    object-fit: cover;
    object-position: center;
}
table .e-rowcell input.btn-check {
    position: relative;
    clip: unset;
    pointer-events: unset;
    border-radius: 50px;
    width: 30px;
    height: 30px;
    background-color: #f8f8f8;
    border: 2px dashed #e3e3e3;
    appearance: unset !important;
    cursor: pointer !important;
}
table .e-rowcell input.btn-check:checked {
    border-style: solid;
    border-color: #1eaf61;
    background: #0DB95D;
}
table .e-rowcell input.btn-check:checked:after {
    content: '\2713';
    font-size: 20px;
    font-weight: 800;
    color: white;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}


/* Search Form & Text */
#basic-addon1 {
    color: white !important;
    padding: 2px;
    background: #5d3cd9 !important;
    border: none;
    font-size: 13px;
    border-radius: 0px;
    display: flex;
}
#basic-addon1:hover{
    background: #0DB95D;
}
.searchtext.form-control {
    height: 100%;
    border: none !important;
    background: transparent;
    padding: 2px;
}
.searchtext.form-control span {
    border: none;
}
.editor-tag span.tag-item.ng-star-inserted {
    background: #3aadc0;
    border-radius: 0px;
    line-height: auto;
    font-size: 13px;
    font-weight: 600;
}
.editor-tag span.tag-item.ng-star-inserted .remove-tag {
    background: #219aae;
    text-align: center;
    padding: 0px 6px 1px;
    display: inline-flex;
}
.editor-tag span.tag-item.ng-star-inserted .remove-tag:hover{
    background: #144c56;
}

/* Report Screen */

#report-tabContent .tab-pane{
    padding: 30px 20px;
    background: var(--bg-light);
}
#report-tabContent .section-tab-block{
    background: var(--light-color);
    padding: 20px;
    border: 1px solid var(--menu-light);
    border-radius: 10px;
    margin-bottom: 20px;
}
#nav-report-tab{
    border: none;
}
#nav-report-tab .nav-link {
    padding: 20px;
    background: #f2f2f2;
    margin-right: 10px;
    color: var(--heading-color);
    border: none !important;
}
#nav-report-tab .nav-link:last-child{
    margin-right: 0px;
}
#nav-report-tab .nav-link.active {
    border: none;
    background: #fafafa;
    color: var(--heading-color);
    font-weight: 700;
    border: none !important;
}
#nav-report-tab .nav-link:hover , #nav-report-tab .nav-link:focus{
    border: none !important;
}
.card-donation-box {
    text-align: center;
    background: #dfdfdf;
    padding: 20px;
    border-radius: 10px;
}
#collection-donation{
    background: #0db95d1c;
}
#recurring-donation{
    background: #4E7D981c;
}
#collection-donation i.bi {
    font-size: 49px;
    color: var(--green-color);
}
#recurring-donation i.bi {
    font-size: 49px;
    color: var(--blue-color);
}
#collection-donation .money-tlt-rc {
    color: var(--green-color);
}
#recurring-donation .money-tlt-rc {
    color: var(--blue-color);
}
.money-tlt-rc {
    font-size: 25px;
}
.titlecard-donation {
    text-transform: capitalize;
    font-weight: 600;
    color: var(--dark-color);
}
.money-text-para {
    font-size: 14px;
}
.video-hintsicons , .video-hintsicons i, .video-hintsicons a{
    font-size: 15px;
    color: var(--bg-light);
}
.video-hintsicons , .video-hintsicons:hover i, .video-hintsicons:hover a{
    font-size: 15px;
    color: var(--bg-light);
}
.posterImgeView{
    max-height: 400px;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
    width: 100%;
}
.carddonationImage{
    max-height: 120px;
    min-height: 120px;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
    width: 100%;
}

/* CRM ADMIN CSS */
.crm-text-icons {
    font-size: 18px;
}
form ejs-richtexteditor .e-btn {
    background: transparent !important;
    color: #000 !important;
    font-size: 13px !important;
    border-radius: 0px !important;
}
form ejs-richtexteditor .e-toolbar-pop {
    border: 1px solid #dadada !important;
    left: 0px !important;
}
form ejs-richtexteditor .e-expended-nav {
    background: black !important;
    color: white !important;
}
form ejs-richtexteditor .e-expended-nav .e-icons{
    color: white !important;
}

form ejs-richtexteditor .e-toolbar-item .e-btn:focus span, form ejs-richtexteditor .e-toolbar-item .e-btn:hover span{
    color: white !important;
}

#TrackableContent .tab-pane{
    background: #fbfbfb;
    padding: 15px;
}
#Trackable {
    border: none;
}
#Trackable .nav-link {
   color: var(--voilet-color);
   background: transparent;
    border-radius: 0px;
    border: none !important;
}
#Trackable .nav-link.active {
    background-color:  var(--voilet-color);
    color: var(--light-color);
    border-radius: 0px;
    border: none !important;

}
.small-para-info{
    font-size: 13px;
    margin: 5px 0px;
}
#email-marketing-tabContent .tab-pane{
    padding: 30px 20px;
    background: white;
}
#email-marketing-tabContent .section-tab-block{
    background: var(--light-color);
    padding: 20px;
    border: 1px solid var(--menu-light);
    border-radius: 10px;
    margin-bottom: 20px;
}
#nav-email-marketing-tab{
    border: none;
}
#nav-email-marketing-tab .nav-link {
    padding: 20px;
    background: #f2f2f2;
    margin-right: 10px;
    color: var(--heading-color);
    border: none !important;
}
#nav-email-marketing-tab .nav-link:last-child{
    margin-right: 0px;
}
#nav-email-marketing-tab .nav-link.active {
    border: none;
    background: #fff;
    color: var(--heading-color);
    font-weight: 700;
    border: none !important;
}
#nav-email-marketing-tab .nav-link:hover , #nav-email-marketing-tab .nav-link:focus{
    border: none !important;
}
#StorelogoImage2 {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    opacity: 0;
}
.uploadFileImgStoreLogo2 {
    max-width: 230px;
    width: 100%;
    min-height: 200px;
    max-height: 200px;
    object-fit: contain;
    margin: auto;
}
.learnmoreBtn {
    max-width: 120px;
    width: 100%;
    cursor: pointer;
    margin-top: 5px;
    margin-bottom: 5px;
}
button.btn.btn-search-icon {
    position: absolute;
    right: 10px;
    padding: 0px;
    top: 50%;
    transform: translateY(-50%) !important;
    z-index: 1;
    border: none !important;
    margin: 0 !important;
}
button.btn.btn-search-icon:focus, button.btn.btn-search-icon:hover{
    background: transparent !important;
    color: var(--blue-color);
    border-radius: 0 !important;
    box-shadow: none !important;
}
.input-search-box{
    max-width: 300px;
    width: 100%;
}
.search-input-field-grid{
    padding-right: 30px !important;
    background: var(--input-bg);
}
.search-input-field-grid:hover, .search-input-field-grid:focus{
    border: 1px solid var(--blue-color);
    background: white;
}
#nav-report-tab-inside .nav-link {
    padding: 20px;
    background: #f2f2f2;
    margin-right: 10px;
    color: var(--heading-color);
    border: none !important;
}
#nav-report-tab-inside .nav-link.active {
    border: none;
    background: #fff;
    color: var(--heading-color);
    font-weight: 700;
    border: none !important;
}
#nav-report-tab-inside{
    border: none !important;
}

/*---------------CRM DATA STYLE---------------*/
.dataViewCard .card-donation-box{
    background: var(--bg-light);
}

.dataViewCard i {
    font-size: clamp(1rem, 0.375rem + 2vw, 1.5rem);
}
.dataViewCard .money-tlt-rc{
    font-size: clamp(1.25rem, 0.4688rem + 2.5vw, 1.875rem);
    font-weight: 600;
}
.dataViewCard  .titlecard-donation {
    text-transform: capitalize;
    font-weight: 600;
    color: var(--dark-color);
}

.dataViewCard.greenOne .card-donation-box{
    background: #75d7a329 !important;
}
.dataViewCard.greenOne i , .dataViewCard.greenOne .money-tlt-rc , .dataViewCard.greenOne .titlecard-donation{
    color: var(--green-color) !important;
}
.dataViewCard.blueOne .card-donation-box{
    background: #75aad729 !important;
}
.dataViewCard.blueOne i , .dataViewCard.blueOne .money-tlt-rc , .dataViewCard.blueOne .titlecard-donation{
    color: var(--blue-color) !important;
}
.dataViewCard.voiletOne .card-donation-box{
    background: #50248814 !important;
}
.dataViewCard.voiletOne i , .dataViewCard.voiletOne .money-tlt-rc , .dataViewCard.voiletOne .titlecard-donation{
    color: var(--voilet-color) !important;
}
.dataViewCard.pinkOne .card-donation-box{
    background: #ffbfc436 !important;
}
.dataViewCard.pinkOne i , .dataViewCard.pinkOne .money-tlt-rc , .dataViewCard.pinkOne .titlecard-donation{
    color: var(--pink-color) !important;
}


.fetaures-side-hero-image {
    text-align: right;
}
.fetaures-side-hero-image img.sidebarTopImage {
    max-width: 190px;
    margin-left: auto !important;
    filter: drop-shadow(0px 10px 20px rgba(165, 165, 165, 0.2));
    background: #ffffff;
    padding: 10px;
    border-radius: 10px;
}

/* Chart Inner report */
.report-inner-chart-text {
    padding: 10px;
    background: #f8fbfc;
    margin-bottom: 15px;
}
.report-inner-chart-text .list-type-chart{
    padding: 0px;
}
.report-inner-chart-text .list-type-chart li {
    list-style: none;
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px dashed var(--menu-light);
    font-size: 11px;
    flex-wrap: wrap;
}
.report-inner-chart-text .list-type-chart li:last-child{
    border-bottom: none;
    padding-bottom: 0px;
    margin-bottom: 0px;
}
.report-inner-chart-text .chart-innerTitle {
    font-size: 13px;
    font-weight: 700;
    color: var(--blue-color);
}
.report-inner-chart-text .list-type-chart-inline{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0;
}
.report-inner-chart-text .list-type-chart-inline li {
    list-style: none;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    flex-wrap: wrap;
}

.topFeatures-icon-images.extra-lg-image{
    max-width: 180px;
}

.icon-table-action {
    max-width: 20px;
    padding: 1px;
}

.card-white-board{
    background: var(--light-color);
    padding: 20px;
    border: 1px solid var(--menu-light);
    border-radius: 10px;
    margin-bottom: 20px;
}
.playyoutubebtn{
  position: relative;
  display: flex;
  align-items: center;
  font-size: 30px;
  text-decoration: none;
}
.playyoutubebtn i{
  color: #FF0000;
}

.side-social-action-wl .socialBtnsLinks{
  font-size: 19px;
}
.side-social-action-wl .likeBtnsCount:hover{
  background-color: var(--orange-color) !important;
}
.side-social-action-wl .socialBtnsLinks path{
  fill: #502488 !important;
}
.side-social-action-wl .socialBtnsLinks:hover{
  color: var(--orange-color) !important;
}
.side-social-action-wl .socialBtnsLinks:hover path{
  fill: var(--orange-color) !important;
}
.mvTabsList{
  border: none !important;
}
.mvTabsList li .nav-link{
  color: var(--voilet-color);
  background-color: white;
  border: 1px solid var(--voilet-color);
  border-radius: 10px;
}
.mvTabsList li .nav-link.active , .mvTabsList li .nav-link:hover{
  background-color: var(--voilet-color);
  color: white;
  border-radius: 10px;

}

.mvTabsContent-view {
  min-height: 400px;
  padding: 0px;
  margin-bottom: 10px;
}
.lefthandBoardContent .mvTabsContent-view {
  min-height: unset;
}
.banner-title{
  word-wrap: break-word;
}
.campaignDetailsView{
  min-height: 410px;
  max-height: 410px;
  overflow-y: auto;
  background: white;
}
.wlImg{
  width: 100%;
  max-height:150px;
  min-height:150px;
  object-fit: cover;
  object-position: center;
  margin-bottom: .7rem;
}
.light-subpara {
  color: #070B1C;
  font-size: .8rem;
  margin-bottom: 5px;
}
.wl-status-active{
  color: #fff;
  background: #25c06d;
  padding: 3px 10px;
  border-radius: 4px 0px 4px 0px;
  font-weight: 400;
  position: absolute;
  bottom: 0;
  right: 0;
}
.wl-status-deactive{
  color: var(--primary-color);
  background: #c7003942;
  padding: 3px 10px;
  border-radius: 3px;
  font-weight: 400;
}
.card-name {
  font-size: .9rem;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 300px;
  overflow: hidden;
  margin-bottom: .7rem;
}


/* New Style */
.MenuiconImage {
  max-width: 25px;
  margin-right: 5px;
  max-height: 30px;
  min-height: 30px;
  object-fit: contain;
  opacity: .8;
}
.primary-menu-list a:hover .MenuiconImage {
  opacity: 1;
}

.purple-bg-color{
  background: #663399;
  opacity: 1;
}
.purple-bg-color::before{
  display: none;
}

.scan-QR-code-section {
  background: #0DB95D;
  padding: 10px;
}
.QRBox-content{
  justify-content: end;
}


.card-slider-boxes {
  position: relative;
  width: 100%;
  height: 670px;
  overflow: hidden;
  margin: 0 auto;

}
.wl-card-content{
  flex-wrap: wrap;
}
.cardBlogsTile {
  width: 100%;
  height: calc(100%/2);
  position: absolute;
  top: 100%;
  transition: top 0.5s;
  background: lightblue;
  border: 1px solid #f4f4f4;
  cursor: pointer;
  border-radius: 5px;
}


.cardBlogsTile.selected-card , .cardBlogsTile:hover{
  border: 1px solid var(--orange-color);
}

.startValue {
  position: absolute;
  top: 100%;
  font-size: 12px;
  color: #B5CADB;
  font-weight: 500;
}
.finalValue {
  position: absolute;
  top: 100%;
  right: 0;
  font-size: 12px;
  color: #B5CADB;
  font-weight: 500;
}

.ar-wl-card {
  width: 100%;
  height: auto;
  /*height: calc(100% / 2);  Height in percentage to show 3 cards */
  margin-bottom: 20px;
  position: absolute;
  transition: top 0.5s ease-in-out;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 14px 20px -11px rgb(203 203 203 / 20%);
  border: 1px solid #f4f4f4;
}
.data-section.lightWihteLabel {
  background: #FAFAFA;
  padding: 50px 0px;
}
.wl-blog-details-tabs {
  padding: 20px;
  background: white;
  border: 1px solid #f4f4f4;
  border-radius: 5px;
}

.side-action-wl .btn.custom-btn.lightBtn:hover, .side-action-wl .btn.custom-btn.lightBtn:focus{
  color: white !important;
}
.btn.TopTablightBtn{
  background: #f2f2f2;
  color: var(--voilet-color);
  border: 1px solid;
}
.btn.TopTablightBtn:hover, .btn.TopTablightBtn:focus{
color: white !important;
}
/* Video Image Slider CSS */
.slider-container {
position: relative;
width: 90%;
margin: 20px auto;
overflow: hidden;
}

.video-slider {
display: flex;
transition: transform 0.5s ease;
}

.video-card {
flex: 0 0 25%; /* Default to show 4 items at a time */
box-sizing: border-box;
padding: 10px;
text-align: center;
}

.video-card img {
width: 100%;
height: auto;
border-radius: 8px;
transition: transform 0.2s;
padding: 15px;
border: 1px solid #ffffff38;
background: #ffffff1c;
}

.video-card:hover img {
transform: scale(1.05);
border-color: #fff;
}

.arrow {
position: absolute;
top: 50%;
width: 50px;
height: 50px;
background-color: rgba(0, 0, 0, 0.5);
color: white;
border: none;
cursor: pointer;
font-size: 20px;
display: flex;
align-items: center;
justify-content: center;
border-radius: 50%;
z-index: 10;
transform: translateY(-50%);
}

.prev, .images-prev {
left: 10px;
}

.next , .images-nxt{
right: 10px;
}

.video-popup {
display: none;
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.8);
justify-content: center;
align-items: center;
z-index: 1000;
}

.video-content {
position: relative;
width: 90%;
max-width: 1100px;
display: flex;
flex-direction: column;
align-items: center;
left: 50%;
top: 50%;
transform: translate(-50%,-50%);
}

.video-content iframe {
width: 100%;
height: 450px; /* Adjust height as needed */
border-radius: 8px;
}

.close-video-popup, .close-video-popupImage {
position: absolute;
top: 0;
right: 0;
box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.8);
color: var(--voilet-color);
font-size: 24px;
cursor: pointer;
background: var(--bg-light);
padding: 2px 10px;
}

.next-videos {
margin-top: 10px;
display: flex;
gap: 10px;
}

.next-videos img {
width: 100px;
height: auto;
border-radius: 8px;
cursor: pointer;
}
.videoImage-sliderImage{
background-color: #663399;
}
.video-card p {
color: white;
margin-bottom: 8px;
}
.sub-title.text-white.lineText {
position: relative;
}
.lineText span{
background: #663399;
position: relative;
z-index: 1;
padding-right: 10px;
}
.sub-title.text-white.lineText:after{
content: '';
width: 100%;
right: 0;
height: 1%;
top: 50%;
transform: translateY(-50%);
background-color: rgba(255, 255, 255, 0.5);
position: absolute;
}
.image-slider {
display: flex;
transition: transform 0.5s ease;
}

.image-card {
flex: 0 0 25%;
box-sizing: border-box;
padding: 10px;
text-align: center;
}

.image-card img {
width: 100%;
height: 200px;
border-radius: 8px;
transition: transform 0.2s;
object-fit: cover;
}

.image-card:hover img {
transform: scale(1.05);
}
.image-popup {
display: none;
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.8);
justify-content: center;
align-items: center;
z-index: 1000;
}

.image-content {
position: relative;
width: 80%;
max-width: 800px;
display: flex;
flex-direction: column;
align-items: center;
top: 50%;
left: 50%;
transform: translate(-50%,-50%);
}

.image-content img {
width: 100%;
border-radius: 8px;
max-width: 500px;
max-height: 500px;
object-fit: contain;
margin: auto;
}
.next-images {
margin-top: 10px;
display: flex;
gap: 10px;
}

.next-images img {
width: 100px;
height: auto;
border-radius: 8px;
cursor: pointer;
}
.side-action-wl {
background: #faf4ff;
padding-top: 17px;
}
.side-action-wl .btn.custom-btn.lightBtn{
border: 1px solid;
}
.side-social-action-wl{
background: #faf4ff;
padding-top: 10px;
margin-top: -10px;
}
.side-action-wl .btn.custom-btn:focus{
color: white !important;
}
.topslideractionBtn .custom-btn{
  padding: 10px 20px;
  font-size: 14px;
  margin: 2px 2px;
  border-radius: 5px;
  border-bottom: 2px solid #909090;
  background: white;
  color: black;
}
.mvTabsContent-view .blogTags.mb-1.blue-txt {
  font-size: 16px;
  color: #000 !important;
}
.topslideractionBtn .custom-btn:hover, .topslideractionBtn .custom-btn:focus{
  box-shadow: none;
  background-color: var(--orange-color);
  border-bottom-color: white;
}
.topslideractionBtn{
  position: absolute;
  top: 10px;
  right: 10px;
  margin: 0;
  padding: 0;
  background: transparent;
}
.imge-blog-container{
overflow: hidden;
}
.defaultViewBigTileImg{
max-height: 350px;
height: 100%;
object-fit: contain;
position: relative;
border: 2px solid #faf4ff;
background: rgb(0 0 0 / 12%);
}
.defaultViewBigTileImg--box{
position: relative;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: var(--dark-color);
object-fit: cover;
object-position: center;
max-height: 500px;
}
.AllTbasContentRow{
  padding: 20px;
  background: white;
  border: 1px solid #F1F1F1;
  border-radius: 10px;
  border-bottom: 5px solid #F1F1F1;
  margin-bottom: 50px !important;
}

.imagebtn{
  position: relative;
  display: flex;
  align-items: center;
  font-size: 30px;
  text-decoration: none;
}

.imagebtn{
  color: #502488;
}




/* New Style */


.card-slider-boxes::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}


.slick-dots li.slick-active button:before {
  color: #0DB95D !important;
  opacity: 1 !important;
}

.slick-dots li button:before {
  position: inline-block;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-size: 10px;
  line-height: 25px;
  text-align: center;
  color: #B5CADB;
  opacity: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots {
  position: absolute;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.WlDetailsTabsButns button {
  font-size: .9rem;
  color: #212529 !important;
}
.WlDetailsTabsButns button.active {
  color: #502488 !important;
  background: #f6f6f6;
  border-bottom: transparent;
}
.WlDetailsTabsContnt {
  padding: 2px 0px;
  background: #f6f6f6;
}
.WlDetailsTabsContnt .table-preview-box{
  margin: 0 !important;
}


.scanQrcodeImage{
  max-width: 130px !important;
  width: 100%;
  padding: 5px !important;
  top: -10% !important;
}

.for-lg , .for-lg, button.for-lg, div.for-lg{
  display: flex !important;
}
.for-sm, .for-sm ,button.for-sm, div.for-sm{
  display: none !important;
}

@media (max-width: 1200px) {
  .video-card {
      flex: 0 0 33.33%; /* Show 3 items at a time */
  }
  .image-card {
    flex: 0 0 33.3%;
  }
}

.primary-menu.headerWhitemenu a {
  padding: 20px 11px !important;
}
.moreiconsParentmenu{
  position: relative;
  cursor: pointer;
}
.moreiconsParentmenu .moreiconsSubMenu{
  position: absolute;
  background: var(--bg-light);
  top: 150%;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  border-radius: 0px;
  flex-direction: column !important;
  display: block !important;
  transition: all 400ms linear;

}
.moreiconsParentmenu:hover .moreiconsSubMenu {
  top: 100%;
  opacity: 1;
  visibility: visible;
  z-index: 1;
  transition: all 400ms linear;
}

 .main-btn-acc-whtlabel:hover .sub-child-menu{
    visibility: visible;
    opacity: 1;
    z-index: 1;
    top: 100%;
    transition: all 400ms linear;
  }


@media (max-width: 768px){

  .not-sm-device{
    display: none !important;
  }
  .moreiconsParentmenu .moreiconsSubMenu{
    position: relative;
    background: var(--bg-light);
    top: 0%;
    opacity: 1;
    visibility: visible;
    z-index: 1;
    border-radius: 0px;

  }
  .QRBox-content p {
      margin-right: 140px;
  }
  .card-slider-boxes{
      width: 90%;
  }
  .mvTabsList  .firtNameList{
      width: 100%;
  }
  .wl-blog-details-tabs{
      margin-top: 20px;
  }
  .video-card {
      flex: 0 0 50%;
  }
  .image-card {
      flex: 0 0 50%;
  }
  .side-social-action-wl .socialBtnsLinks {
      font-size: 19px;
      max-width: 100%;
  }
  .top-navbar .toggle-menu {
    display: block !important;
  }
  .top-navbar .primary-menu{
    display: none;
  }
  .img-fluid.userprofileImg{
    margin-left: 0px;
  }
  .top-navbar .primary-menu.showing ul.primary-menu-list {
    display: block;
    margin-left: 0;
  }
  .primary-menu.headerWhitemenu.ng-star-inserted.showing {
    display: block;
  }
  .for-lg, .for-lg{
    display: none !important;
  }
  .for-sm, .for-sm{
    display: flex !important;
  }
}

@media (max-width: 590px) {
  .side-action-wl{
      padding: 10px 20px 20px;
  }
  .scanQrcodeImage {
    max-width: 110px !important;
    width: 40%;
    padding: 5px !important;
    top: 40% !important;
  }
  .mvTabsContent-view{
    min-height: unset;
  }
  header.top-header .row .col-5:first-child {
    padding: 0 !important;
  }
  .topslideractionBtn .custom-btn {
    background: #ff6d00;
    color: #fff;
    border: 1px solid #ff6d00;
    width: 100%;
    font-size: 17px;
  }
}

@media (max-width: 480px) {
  .video-card {
      flex: 0 0 100%; /* Show 1 item at a time */
  }
  .image-card {
      flex: 0 0 100%;
  }
  .side-social-action-wl .socialBtnsLinks {
      font-size: 24px;
      max-width: 100%;
  }
  .card-slider-boxes{
      background-color: #fff;
  }
  .arrow {
      position: absolute;
      top: 50%;
      width: 30px;
      height: 30px;
      font-size: 15px;
  }



}


/* 2 Time */


.custom-btn {
  padding: 15px 40px;
  font-size: clamp(0.8125rem, 0.7128rem + 0.3191vw, 1rem);
  color: #ffffff;
  background-color: #502488;
}

.custom-btn.greenBtn {
  background-color: #0DB95D !important;
}

.custom-btn.orangeBtn {
  background-color: #F9521D !important;
}

.custom-btn.lightBtn {
  background-color: #f2f2f2 !important;
  color: #070B1C;
}

.custom-btn.pinkBtn {
  background-color: #F7545F;
}

.custom-btn.blueBtn {
  background-color: #4E7D98 !important;
  color: #ffffff !important;
}

.custom-btn.blueBtn:hover,.custom-btn.blueBtn:focus {
  background-color: #C70039 !important;
  color: #ffffff !important;
  box-shadow: none;
}

.custom-btn:hover,
.custom-btn:focus {
  color: #ffffff !important;
  background-color: #C70039;
  box-shadow: none;
}

.light-text {
  font-weight: 300;
}

.sm-voilet-btn {
  font-size: clamp(0.8125rem, 0.7793rem + 0.1064vw, 0.875rem);
  font-weight: 500;
  color: #ffffff;
  background-color: #502488;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  text-decoration: none;
}

.blue-txt {
  color: #4E7D98 !important;
}

.green-txt {
  color: #0DB95D !important;
}

.yellow-txt {
  color: #FF8A00 !important;
}

.pink-txt {
  color: #F7545F !important;
}

.voilet-txt {
  color: #502488 !important;
}

.orange-txt {
  color: #F9521D !important;
}

.blue-bg{
  color: white !important;
  background-color: #4E7D98 !important;
}

.green-bg{
  color: white !important;
  background-color: #0DB95D !important;
}

.yellow-bg{
  color: #070B1C !important;
  background-color: #FF8A00 !important;
}

.pink-bg{
  color: white !important;
  background-color: #F7545F !important;
}

.voilet-bg{
  color: white !important;
  background-color: #502488 !important;
}
.login-register-action .btn-text {
  display: block;
}

.login-register-action .btn-icon {
  display: none;
}

.sm-voilet-btn:hover,
.sm-voilet-btn:focus {
  box-shadow: none;
  border: none;
  background-color: #C70039;
  color: #ffffff;
}




/* Header & Nav Style Start */
.top-navbar {
  background: #ffffff;
  position: relative;
}



.top-navbar .primary-menu .primary-menu-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 15px;
}

.top-navbar .primary-menu .primary-menu-list .list-group-item {
  padding: 0px;
  border: none;
  position: unset;
}

.top-navbar .primary-menu .primary-menu-list .list-group-item a {
  font-size: clamp(0.8125rem, 0.5793rem + 0.7463vw, 0.9375rem);
  color: #aeaeae;
  text-decoration: none;
  padding: 40px 20px;
  font-family: inherit;
  font-weight: 500;
  display: flex;
  position: relative;
  align-items: center;
  text-transform: capitalize;
}

.top-navbar .primary-menu .primary-menu-list .list-group-item a.active,
.top-navbar .primary-menu .primary-menu-list .list-group-item a:hover {
  color: #502488;
}

.top-navbar .primary-menu .primary-menu-list .list-group-item a.parent-link i.bi {
  font-size: 10px;
}

.top-navbar .all-top-action-menu {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-end;
}

.top-navbar .search-box {
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0px 8px 27px -9px rgb(179 179 179 / 20%);
  border: 1px solid #FAFAFA;
  min-width: 260px;
  right: 0;
  top: 150%;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  transition: all 400ms ease-in-out;
}

.top-navbar .search-box.view-popup {
  top: 100%;
  opacity: 1;
  visibility: visible;
  z-index: 1;
  transition: all 400ms ease-in-out;
}

.top-navbar .search-btn .search-action-btn {
  border: none;
  padding: 0;
}

.top-navbar .search-box .search-form {
  padding: 15px;
  position: relative;
}

.top-navbar .toggle-menu {
  display: none;
}

.top-navbar .toggle-menu .toggle-action-btn {
  border: none;
}

.top-navbar .sub-child-menu .sub-menu-image {
  max-width: 37px;
  min-width: 37px;
  max-height: 37px;
  min-height: 37px;
  object-fit: contain;
  object-position: center;
  margin-right: 10px;
}

.top-navbar .sub-child-menu {
  background: rgba(46, 13, 88, .8);
  position: absolute;
  width: 100%;
  left: 50%;
  top: 100%;
  z-index: 1;
  backdrop-filter: blur(10px);
  max-width: 880px;
  margin: auto !important;
  transform: translateX(-50%);
}

.top-navbar .primary-menu .primary-menu-list .list-group-item .sub-child-menu .sub-menu-link {
  color: white;
  font-size: 14px;
  padding: 15px 0px 0px;
}

.top-navbar .primary-menu .primary-menu-list .list-group-item .sub-child-menu .sub-menu-link:hover {
  text-decoration: underline;
}

.top-navbar .primary-menu .primary-menu-list .list-group-item .sub-child-menu .sub-menu-link:last-child {
  padding-bottom: 30px;
}

.top-navbar .primary-menu .primary-menu-list .list-group-item .sub-child-menu .sub-menu-link:first-child {
  padding-top: 30px;
}

.have-child .sub-child-menu {
  visibility: hidden;
  opacity: 0;
  z-index: -1;
  top: 150%;
  transition: all 400ms linear;
}

.content-extra-menu .extra-menu-box-images {
  width: 100%;
  max-height: 180px;
  min-height: 180px;
  object-fit: cover;
  object-position: center;
  margin-bottom: 20px;
}

.extra-menu-box-addon {
  padding: 20px 0px;
}

/* Header & Nav Style End */
/* Auth Login and Register */
.auth-form-secrtion {
  padding: 80px 20px;
  /* background: url('../../assets/images/new-ui-image/login-bg.png'); */
  background-size: cover;
  min-height: 85vh;
  height: 100%;
  align-items: center;
}

.page-main-title {
  font-size: clamp(1.875rem, 1.3431rem + 1.7021vw, 2.875rem);
  color: #070B1C;
  font-weight: bold;
  text-transform: capitalize;
}

.page-main-title .helloEmoji {
  max-width: 42px;
}

.sub-main-line {
  font-size: clamp(1.125rem, 0.859rem + 0.8511vw, 1.625rem);
  color: #070B1C;
}

.side-auth-form .form-link {
  font-size: 14px;
  display: block;
  margin: 10px 0px 20px;
  text-align: right;
  text-decoration: none;
  color: #070B1C;
}

.side-auth-form .form-link:hover {
  color: #C70039;
}

/* Auth Login and Register End */
.hero-banner-section {
  /* background: url('../../assets/images/new-ui-image/herobanner/bannerimage.png'); */
  padding: 150px 20px 150px;
  background-size: cover;
  background-position: center;
}

.hero-banner-section .banner-title {
  font-size: clamp(1.875rem, 1.5426rem + 1.0638vw, 2.5rem);
  color: #ffffff;
  margin-bottom: 20px;
  font-weight: bold;
}

.banner-desc {
  font-size: clamp(0.875rem, 0.6755rem + 0.6383vw, 1.25rem);
  color: #ffffff;
  margin-bottom: 3rem;
}

.hero-banner-section .banner-fill-btn {
  margin-right: 12px;
}

.hero-banner-section .banner-outline-btn {
  margin-left: 12px;
  background-color: transparent;
  border: 1px solid #ffffff;
}

.hero-banner-section .banner-outline-btn:hover {
  background-color: #ffffff;
  color: #070B1C;
}

.hero-banner-section .banner-action-btn {
  margin-top: 60px;
}

.about-section {
  background-color: #FAFAFA;
}

.why-image-content {
  padding: 80px 50px;
}

.section-title {
  font-size: clamp(1.5625rem, 0.7314rem + 2.6596vw, 3.125rem);
  color: #070B1C;
  font-weight: bold;
  text-transform: capitalize;
}

.section-para {
  font-size: clamp(0.875rem, 0.6786rem + 0.3571vw, 1rem);
  color: #242424;
  line-height: 29px;
  margin: 30px 0px;
}

.services-slider-section {
  position: relative;
  background: linear-gradient(90deg, #714630 100%, #FAFAFA 10%);
  padding: 40px 40px;
}

.services-slider-section:before {
  /* content: url('../../assets/images/new-ui-image/down-arrow.png'); */
  position: absolute;
  top: -40px;
  right: 180px;
  width: 130px;
}

.services-slider-section .swiper {
  width: 100%;
  height: 100%;
  overflow-y: visible !important;
}

.services-slider-section .swiper-slide {
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.services-slider-section .service-slide-box {
  background: #ffffff;
  box-shadow: 0px 4px 10px 1px rgb(0 0 0 / 5%);
  padding: 20px;
  text-align: center;
  margin: 0;
  width: 100%;
  min-height: 204px;
}

.services-slider-section .service-slide-box .service-name a {
  font-size: clamp(0.875rem, 0.6786rem + 0.3571vw, 1rem);
  text-decoration: none;
  color: #070B1C;
  font-weight: bold;
}

.services-slider-section .service-slide-box .service-name a:hover {
  color: #502488;
}

.services-slider-section .service-slide-box .service-icon-img {
  max-width: 110px;
  min-width: 110px;
  max-height: 60px;
  object-fit: contain;
  min-height: 100px;
  margin-bottom: 10px;
  ;
}

.arrow-move-slider {
  position: absolute;
  right: 110px;
  top: -10px;
  z-index: 1;
}

.arrow-move-slider .swiper-btn-motion:after {
  font-size: 25px;
  background: #C70039;
  padding: 12px 15px;
  color: white;
}

.arrow-move-slider .swiper-btn-motion.swiper-button-disabled:after {
  background: rgba(7, 11, 28, 22%);
}

.arrow-move-slider .swiper-button-prev {
  left: unset !important;
  right: 20px;
}

.arrow-move-slider .swiper-button-next {
  left: unset !important;
  right: unset !important;
}

.clients-sections {
  padding: 50px 20px;
  position: relative;
}

.client-logo-box {
  text-align: center;
}

.client-logo-box .client-logo-img {
  min-height: 130px;
  object-fit: contain;
  background: white;
  max-height: 130px;
  padding: 20px 15px;
}

.steps-sections {
  padding: 80px 50px 0px;
  background-color: #FAFAFA;
}

.steps-sections .section-top {
  max-width: 870px;
  margin: auto;
}

.steps-sections .all-steps-follow {
  margin: 100px 0px 0px;
}

.steps-sections .stepside-Content {
  padding: 70px 50px;
}

.steps-sections .linesDropDown1 {
  text-align: center;
  margin-top: -90px;
  margin-right: 270px;
}

.steps-sections .linesDropDown2 {
  text-align: center;
  margin-top: -40px;
  margin-right: 290px;
}

.steps-sections .linesDropDown3 {
  text-align: center;
  margin-top: -90px;
}

.information-sections .information-content {
  /* background: url('../../assets/images/new-ui-image/solution-page.png'); */
  background-size: cover;
  background-position: center;
  padding: 108px 100px;
}

.information-sections .information-content-box {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  align-content: center;
  position: relative;
}

.information-content-box:before {
  content: '';
  /* background: url('../../assets/images/new-ui-image/down-opp-blue.png'); */
  position: absolute;
  left: 10px;
  bottom: -100px;
  width: 110px;
  height: 105px;
  background-size: contain;
  background-repeat: no-repeat;
}

.information-sections .image-solution {
  display: flex;
  height: 100%;
}

.information-sections .image-solution img {
  object-fit: cover;
}

.information-sections .purple-stamp-sloution {
  position: absolute;
  top: 70px;
  left: -100px;
  max-width: 260px;
}

.information-sections .green-stamp-sloution {
  position: absolute;
  bottom: 20px;
  right: -100px;
  max-width: 290px;
  z-index: 1;
}

.nonprofit-sections .nonprofit-content {
  background-color: #FAFAFA;
  padding: 108px 100px;
}

.nonprofit-sections .nonprofit-image-solution {
  text-align: center;
  background: white;
  height: 100%;
  align-content: space-evenly;
}

.nonprofit-sections .nonprofit-solution-images {
  max-height: 550px;
  margin: auto;
}

.nonprofit-sections .multicurly-nonprofit.img-fluid {
  position: absolute;
  bottom: -60px;
  max-width: 120px;
  right: 60px;
}

.testimonial-section {
  padding: 80px 20px;
}

#feedbackSlider {
  margin: 50px 0px 0px;
}

#feedbackSlider .client-image {
  text-align: center;
}

#feedbackSlider .clientImages {
  max-width: 200px;
  margin: auto;
}

#feedbackSlider .carousel-control-prev {
  position: absolute;
  background: #070B1C;
  max-height: 70px !important;
  max-width: 45px;
  opacity: 1;
  bottom: 0 !important;
  top: unset;
  right: 52px;
  border-radius: 100px;
  left: unset;
  height: 46px;
}

#feedbackSlider .carousel-control-next {
  position: absolute;
  background: #070B1C;
  max-height: 70px !important;
  max-width: 45px;
  opacity: 1;
  bottom: 0 !important;
  top: unset;
  right: 5px;
  border-radius: 100px;
  left: unset;
  height: 46px;
}

#feedbackSlider .carousel-control-prev-icon,
#feedbackSlider .carousel-control-next-icon {
  width: 20px;
}

.main-footer {
  padding: 80px 20px 50px;
  /* background: url('../../assets/images/new-ui-image/footerBg.png'); */
  background-size: cover;
}

.main-footer .footerline {
  font-size: 14px;
  margin: 20px 0px;
  color: #242424;
}

.main-footer .footerLogo {
  width: 70%;
  margin-bottom: 20px;
}

.main-footer .social-footer-menu {
  justify-content: start;
  margin: 30px 0px 30px;
}

.main-footer .social-footer-menu a {
  font-size: 20px;
  color: rgba(95, 106, 112, 70%);
  margin-right: 20px;
}

.main-footer .social-footer-menu a:hover {
  color: #C70039;
}

.main-footer .footer-title {
  font-size: clamp(1rem, 0.734rem + 0.8511vw, 1.5rem);
  font-weight: bold;
  margin-bottom: 30px;
}

.main-footer .footer-company-details {
  max-width: 90%;
}

.main-footer .footer-list a {
  font-size: clamp(0.8125rem, 0.7793rem + 0.1064vw, 0.875rem);
  display: block;
  text-decoration: none;
  color: #242424;
  text-decoration: none;
  margin-bottom: 15px;
}

.main-footer .footer-list a:hover {
  color: #C70039;
}

.main-footer .contact-list-ftr a {
  text-decoration: underline;
  color: #070B1C;
  font-style: italic;
  font-weight: 500;
}

.main-footer .contact-list-ftr a.none-underline-link {
  text-decoration: none !important;
}

.main-footer .otherCol {
  padding-left: 60px;
}

.main-footer .divider-ftr {
  border-color: #b5b5b5;
  border-width: 2px;
  margin: 30px 0px;
}

.register-process .image-boxes-register {
  /* background: url('../../assets/images/new-ui-image/slideRegister.png'); */
  min-height: 100vh;
  height: 100%;
  background-size: cover;
  background-position: center;
  padding: 20px;
}

.register-process .text-images {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  width: 80%;
}

.customTopLink {
  padding: 20px;
}

.optionForm {
  padding: 20px;
}

.notes-information {
  font-size: 10px;
  line-height: 18px;
  padding: 0px 20px;
}

.selection-form-register-choice .card-body-content {
  display: flex;
  justify-content: space-between;
}

.selection-form-register-choice .card-titles {
  font-size: clamp(1.125rem, 0.8923rem + 0.7447vw, 1.5625rem);
  color: #070B1C;
  font-weight: 500;
}

.selection-form-register-choice .card-tagline {
  font-size: 14px;
  margin-right: auto;
  color: #502488;
  margin-bottom: 0px;
}

.sidecard-content {
  background: #FAFAFA;
}

.card-services-list {
  font-size: 14px;
  margin-top: 20px;
  color: #242424;
}

.card-services-list ul li {
  margin-bottom: 5px;
}

.selection-form-register-choice .card-info {
  flex: auto;
}

.selection-form-register-choice .card-select-btn {
  margin-left: auto;
}

.selection-form-register-choice .card-images {
  margin-right: 20px;
  max-width: 178px;
}

.selection-form-register-choice .card-form {
  margin: 20px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #F6F6F6;
  background: white;
}

.pricing-hero-banner-section {
  padding: 80px 50px 330px;
  /* background: url('../../assets/images/new-ui-image/pricing/top-hero-banner.png'); */
  background-size: cover;
  background-position: center;
}

.pricing-hero-banner-section .banner-switch-btn {
  background: #ffffff;
  width: auto;
  max-width: fit-content;
  margin: auto;
  padding: 10px;
  border-radius: 150px;
}

.pricing-hero-banner-section .banner-switch-btn .custom-btn {
  border-radius: 100px;
  background-color: #FAFAFA;
  color: #070B1C;
  font-weight: 600;
  border: none;
}

.pricing-hero-banner-section .banner-switch-btn .custom-btn.show {
  background: #F9521D;
  color: #ffffff;
}

#month-offers,
#annual-offers {
  margin-top: -280px;
  padding: 20px;
}

.price-list-card {
  position: relative;
  height: 100%;
  padding: 0px 0px 120px;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: -1px 15px 20px -3px #7d7d7d2b;
}

.price-table-footer {
  position: absolute;
  bottom: 20px;
  width: 100%;
  left: 0;
  padding: 0px 11px;
}

.price-table-footer .btn {
  width: 100%;
}

.price-tabsbuttons {
  background: linear-gradient(180deg, #FAFAFA 95%, #ffffff 8%);
}

.price-tabsbuttons .price-list-card .cardType {
  font-size: clamp(1.125rem, 0.9255rem + 0.6383vw, 1.5rem);
  text-align: center;
  color: #070B1C;
}

.price-tabsbuttons .price-list-card .cardOffer-price {
  text-align: center;
  font-size: clamp(1.125rem, 0.5931rem + 1.7021vw, 2.125rem);
  font-weight: 600;
  color: #070B1C;
  margin: 20px 0px;
}

.price-tabsbuttons .price-list-card .offerPeriod {
  font-size: 11px;
  color: rgba(95, 106, 112, 70%);
  font-weight: 500;
}

.price-tabsbuttons .price-list-card .cardOffer-price.green-txt {
  color: #0DB95D;
}

.price-tabsbuttons .price-list-card .cardOffer-para {
  text-align: center;
  font-size: clamp(0.8125rem, 0.7793rem + 0.1064vw, 0.875rem);
  color: #242424;
}

.price-tabsbuttons .price-listcard-header {
  padding: 20px 15px 1px;
  background: #f8f8f8;
  border-radius: 5px 5px 0px 0px;
}

.price-tabsbuttons .price-list-card-body {
  padding: 20px 15px 0px;
}

.price-tabsbuttons .price-list-card-body ul {
  padding-left: 15px;
  margin-bottom: 1rem;
}

.price-tabsbuttons .price-list-card-body ul li {
  margin-bottom: 15px;
  color: #363232;
  font-size: 14px;
}

.price-tabsbuttons .price-list-card-body .extra-services-offer {
  border-bottom: 1px solid #EBE7EF;
}

.price-tabsbuttons .blue-txt {
  color: #4E7D98 !important;
}

.price-tabsbuttons .green-txt {
  color: #0DB95D !important;
}

.price-tabsbuttons .yellow-txt {
  color: #FF8A00 !important;
}

.price-tabsbuttons .pink-txt {
  color: #F7545F !important;
}

/* .price-tabsbuttons .price-list-card-body .main-offers-table-list li { */
  /* list-style-image: url('../../assets/images/new-ui-image/pricing/check.png'); */
/* } */

.price-tabsbuttons .noted-offer-line {
  text-align: center;
  font-weight: 500;
  margin-bottom: 1rem;
}

.light-green-btn {
  background-color: #ECFFEF;
  color: #0DB95D;
  font-weight: 500;
}

.light-blue-btn {
  background-color: #F1FDFE;
  color: #4E7D98;
  font-weight: 500;
}

.light-yellow-btn {
  background-color: #FEF4E9;
  color: #FF8A00;
  font-weight: 500;
}

.light-pink-btn {
  background-color: #FFF1F2;
  color: #F7545F;
  font-weight: 500;
}

.price-list-card.most-popular:before {
  content: 'Most Popular';
  width: auto;
  position: absolute;
  display: inline-block;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  background: var(--delete-color);
  border-radius: 0 0 5px 5px;
  color: white;
  padding: 1px 10px;
  font-size: 12px;
  /* font-weight: 500; */
}

.price-list-card.most-popular .price-listcard-header {
  padding: 30px 15px 10px;
}

.price-list-card.bestsale:before {
  content: 'Best Sale';
  width: auto;
  position: absolute;
  display: inline-block;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  background: #FF4848;
  border-radius: 0 0 5px 5px;
  color: white;
  padding: 1px 10px;
  font-size: 12px;
  /* font-weight: 500; */
}

.price-list-card.bestsale .price-listcard-header {
  padding: 30px 15px 10px;
}

.star-fetaure {
  background: #F9F9F9;
  text-align: center;
  display: flex;
  justify-content: center;
  position: relative;
  flex-direction: column;
  padding: 20px;
  max-height: 100px;
  margin: 15px 0px;
}

.star-fetaure .star-image {
  position: absolute;
  top: -12px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 22px;
}

.star-fetaur .section-para {
  font-weight: 500;
  text-align: center;
  font-size: 14px;
}

.extra-curl-img {
  text-align: right;
}

.allanother-gold-features {
  padding: 80px 20px;
}

.vr-top-banner {
  padding: 130px 20px;
  text-align: center;
  background-color: #070B1C;
}

/* .vr-top-banner.contact-us-vr-banner { */
  /* background: url('../../assets/images/new-ui-image/contact/top-bg.png'); */
/* } */

.icon-box-information-custom .icon-box-imgs {
  padding: 20px 23px;
  background: #F8FAFC;
  border-radius: 90px;
  margin-right: 20px;
  font-size: 26px;
  color: #F9521D;
}

.contact-infoText a {
  color: #070B1C;
  font-weight: 500;
  text-decoration: none;
}

.icon-box-information-custom .icon-box-imgs.greenColorIcon {
  color: #0DB95D;
}

.contact-form-section {
  padding: 80px 20px;
}

.msg {
  position: fixed;
  top: -100%;
  right: 10px;
  background: #ebfaf3;
  padding: 10px 30px;
  border-radius: 5px;
  color: #0db95d;
  box-shadow: 0px 9px 19px -12px;
  visibility: hidden;
  opacity: 0;
  transition: all 600ms linear;
}

.msg.showMsg {
  top: 10px;
  visibility: visible;
  opacity: 1;
  transition: all 600ms linear;
}

.map-section-form-box {
  max-height: 491px;
  background: #b0b0b0;
  position: relative;
  overflow: hidden;
}

.map-section-form-box:before {
  position: absolute;
  content: 'MAP VIEW';
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 50px;
  font-weight: 500;
  color: #dfdfdf;
}

.map-view-iframe {
  min-height: 490px;
  padding: 0;
  position: relative;
  width: 100%;
}

.sideContactBannerTitle {
  position: relative;
}

.sideContactBannerTitle:before {
  content: '';
  position: absolute;
  left: 70%;
  /* background: url('../../assets/images/new-ui-image/contact/spring-arrow.png'); */
  width: 90px;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
}

.about-hero-section {
  background: linear-gradient(90deg, #502488 80%, #F7F4FB 10%);
  padding: 80px 20px;
}

.left-about-content .blue-down-arrow-about {
  position: absolute;
  right: 60px;
  max-width: 100px;
}

.our-team-members-section {
  background: #FAFAFA;
  padding: 80px 20px;
}

.team-member-card {
  background: #ffffff;
  padding: 20px;
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 30px;
}

.sub-title {
  font-weight: 600;
  font-family: inherit;
}

.team-member-card .team-img {
  max-height: 300px;
  min-height: 300px;
  width: 100%;
  object-fit: cover;
  object-position: center;
  margin-bottom: 20px;
  border-radius: 10px;
}

.team-member-card .team-member-position {
  margin: 5px 0px 10px;
  color: #4E7D98;
}

.social-team-contact h5 {
  font-size: 14px;
}

.team-social-LI-link {
  font-size: 16px;
  color: #4E7D98;
  text-decoration: none;
}

.steps-boxs {
  display: flex;
  align-items: center;
}

.about-steps {
  max-width: 150px;
}

.about-steps-para {
  font-size: 14px;
  line-height: 22px;
  padding: 40px 200px 40px 60px;
  position: relative;
}

.about-steps-para:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: url('../../assets/images/new-ui-image/aboutus/steps/section-steps-pink.png'); */
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: -1;
}

.count-about-number {
  font-size: clamp(1.875rem, 0.3125rem + 5vw, 3.125rem);
  padding: 20px;
  font-weight: 600;
  text-align: center;
}

.abt-stp-2,
.abt-stp-4 {
  padding: 40px 60px 40px 200px;
}

.abt-stp-2:before {
  /* background: url('../../assets/images/new-ui-image/aboutus/steps/section-steps-blue.png'); */
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.abt-stp-3:before {
  /* background: url('../../assets/images/new-ui-image/aboutus/steps/section-steps-green.png'); */
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.abt-stp-4:before {
  /* background: url('../../assets/images/new-ui-image/aboutus/steps/section-steps-voilet.png'); */
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.about-steps-section {
  padding: 80px 20px;
}

.allStepsAbout {
  margin: 50px 0px 0px;
}

.team-hero-section {
  background: linear-gradient(180deg, #FAFAFA 90%, transparent 20%);
  padding: 80px 20px;
}

.memberBioDetailsImg {
  max-width: 220px;
  min-width: 220px;
  max-height: 260px;
  min-height: 260px;
  object-fit: cover;
  object-position: center;
}

.Team-member-img-linked {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0px 30px 20px 0px;
  float: left;
}

.Team.MemberBio {
  padding: 50px 20px;
}

.Team.MemberBio .member-info-name {
  display: flex;
}

.terms-hero-section {
  padding: 80px 20px;
  background: #502488;
}

.terms-sections-list {
  padding: 80px 20px;
  background: #FAFAFA;
}

#terms-list-context a.list-group-item {
  border: none;
  background: transparent;
  font-size: 14px;
  color: #242424;
  border-radius: 5px;
}

#terms-list-context a.list-group-item.active {
  background: #F9521D;
  color: #ffffff;
}

.terms-scrollExample {
  padding: 30px;
  background: #ffffff;
}

.terms-scrollExample .termsBox-txt {
  margin-bottom: 30px;
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 20px;
}

.terms-scrollExample .terms-condition-list li {
  list-style: circle;
  margin: 20px 0px;
  font-size: clamp(0.875rem, 0.6786rem + 0.3571vw, 1rem);
  color: #242424;
}

.addressContact {
  list-style: none !important;
  padding: 0;
  font-weight: 500;
  font-style: italic;
  color: #F9521D !important;
}

.custom-menu-heading {
  font-size: 17px;
  margin-top: 20px;
  opacity: .8;
  margin-left: 5px;
}

#terms-list-context {
  z-index: 0 !important;
  top: 30px;
}

.features-hero-section {
  position: relative;
  padding: 80px 20px;
  background: #FDFDFD;
}

.features-hero-section:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: url('../../assets/images/new-ui-image/login-bg.png'); */
  background-size: cover;
  background-position: center;
  opacity: .6;
  background-attachment: fixed;
}

.features-sections-list {
  padding: 80px 20px;
}

.features-card-box {
  padding: 20px;
  background: #FAFAFA;
  height: 100%;
  top: 0;
  position: relative;
  box-shadow: 0px 11px 30px 0px rgb(212 212 212 / 0%);
  transition: all 300ms ease-in-out !important;
}

.features-card-box:hover {
  background: #ffffff;
  top: -20px;
  box-shadow: 0px 11px 30px 0px rgb(212 212 212 / 30%);
  transition: all 300ms linear !important;
}

.dark-banner-desc {
  font-size: clamp(0.875rem, 0.6755rem + 0.6383vw, 1.25rem);
  color: var(--dark-color);
}

.feater-body-modal {
  text-align: left;
}

.features-card-icon {
  max-width: 70px;
  min-width: 70px;
  min-height: 70px;
  max-height: 70px;
  object-fit: contain;
  object-position: center;
}

.features-box-column {
  margin-bottom: 30px;
}

.sidebarTopImage.w-100 {
  max-height: 400px;
  min-height: 400px;
  object-fit: contain;
  width: 100%;
}

.our-team-members-section .team-member-card {
  position: relative;
  overflow: hidden;
  top: 0;
  box-shadow: unset;
  transition: all 400ms linear;
}

.our-team-members-section .team-member-card:hover {
  box-shadow: 0px 14px 20px -10px #e8e8e8;
  border: 1px solid #d0d0d0;
  top: -20px;
  transition: all 400ms linear;
}

.our-team-members-section .team-member-card:after {
  content: '';
  background: #f3f5f5;
  position: absolute;
  top: 120px;
  left: 0;
  width: 0;
  height: 100%;
  z-index: -1;
  transition: all 400ms linear;
}

.our-team-members-section .team-member-card:hover:after {
  width: 100%;
  transition: all 400ms linear;
}

/* Team Modal CSS */
.team-modal-preview {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 75%);
  top: 0;
  left: 0;
  display: flex;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
  transition: all 400ms linear;
}

.team-modal-preview.showingModal {
  visibility: visible;
  opacity: 1;
  z-index: 2;
  transition: all 400ms linear;
}

.team-modal-inside-content {
  max-width: 1080px;
  padding: 50px;
  background: #ffffff;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow-y: auto;
}

.modal-team-images {
  max-width: 200px;
  min-width: 200px;
  max-height: 250px;
  min-height: 250px;
  object-fit: cover;
  object-position: center;
  margin-bottom: 20px;
}

.close-team-modal {
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 30px;
  cursor: pointer;
  background: var(--dark-color);
  padding: 0px 8px;
  color: #FDFDFD;
}

.close-team-modal:hover {
  background: #C70039;
}

.modal-paragraph p {
  font-size: 14px !important;
  font-size: 14px !important;
  line-height: 18px;
  margin: 15px 0px !important;
  color: #242424;
  font-style: italic;
}

.modal-paragraph {
  max-height: 400px;
  overflow-y: auto;
}

.team-modal-name {
  font-size: 22px;
}

.team-memeber-info-box {
  display: flex;
}

.modal-paragraph p:first-child {
  margin-top: 0px !important;
}

.modal-division {
  margin: 0px 20px;
  border: 1px dashed #A9A9A9;
}

.terms-sections-list .lastline {
  list-style: none !important;
  font-weight: 600;
}


.blur-drop-style-blue,
.blur-drop-style-pink,
.blur-drop-style-orange {
  position: relative;
  overflow: hidden;
}

.blur-drop-style-blue::before {
  content: '';
  background: #502488 !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
}

.blur-drop-style-pink::before {
  content: '';
  background: #502488 !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
}

.blur-drop-style-purple::before {
  content: '';
  background: #502488 !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
}

.topheader-feature-relative {
  position: relative;
}

.single-card-imges {
  max-width: 1024px;
  margin: 30px auto;
  width: 100%;
  display: flex;
  /* box-shadow: 0px 11px 30px 0px rgb(255 255 255 / 23%); */
  border-radius: 10px;
  padding: 25px 20px;
  background: #f4f6f7;
}

.count-numbers {
  font-size: 18px;
  text-align: center;
}

.count-numbers span {
  background: lightblue;
  color: #ffffff;
  display: block;
  box-sizing: content-box;
  height: 59px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  max-width: 60px;
  width: 100%;
  border-radius: 10px 30px 10px 30px;
}

.count-numbers span.voilet-bg {
  background: #502488;
}

.count-numbers span.pink-bg {
  background: #C70039;
}

.count-numbers span.green-bg {
  background: #0DB95D;
}

.cout-box-list {
  display: flex;
  justify-content: space-between;
  background: #FAFAFA;
  padding: 60px 50px 50px;
}

.cout-box-list .count-box {
  max-width: 30%;
  width: 100%;
  margin: 0px auto;
  position: relative;
}

.cout-box-list .count-box:last-child:after {
  display: none;
}

.count-box:after {
  content: '';
  border: 1px dashed #c3c3c3;
  position: absolute;
  left: 60%;
  width: 90%;
  height: 1px;
  top: 16%;
  z-index: 1;
}

.trialListPrice {
  list-style-type: none;
  list-style-image: none !important;
}

.processing-fees-sections {
  background: linear-gradient(180deg, #FAFAFA, transparent);
  padding: 80px 20px;
}

.calculation-box {
  background: white;
  padding: 20px;
  border: 1px solid #dfdfdf;
}

.amount-fees-field {
  position: relative;
  padding-left: 30px;
}

.amount-currency-symbol {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.form-check-input {
  border-color: #4E7D98;
}

.form-check-input:focus {
  box-shadow: none;
}

.form-check-input:checked {
  box-shadow: none;
  background-color: #0DB95D;
  border-color: #0DB95D;
}

.green-divider-line {
  border: 2px solid #4E7D98;
  margin-top: 30px;
  margin-bottom: 20px;
}

.dtls-fees-row {
  max-width: 300px;
}

.dtls-fees-row {
  max-width: 320px;
  margin: 0px auto !important;
}

.img-fluid.userprofileImg {
  max-width: 40px;
  min-width: 40px;
  min-height: 40px;
  max-height: 40px;
  object-fit: cover;
  border-radius: 100px;
  object-position: center;
  margin-left: 15px;
}

.btn-icon-option {
  font-size: 10px;
  margin-left: 5px;
  color: #070B1C;
}

.sm-sub-menu-list {
  max-width: 250px !important;
  left: unset !important;
}

.profile-submenu .sub-menu-link {
  color: white !important;
  font-size: 14px;
  padding: 15px 0px 0px;
  display: block;
  text-decoration: none !important;
  color: rgba(255, 255, 255, 0.747) !important;
}

.profile-submenu .sub-menu-link:last-child {
  padding-bottom: 20px;
}

.profile-submenu .sub-menu-link:hover {
  text-decoration: none !important;
  color: white !important;
}

.profile-submenu .notlinkAnchor, .profile-submenu .notlinkAnchor:hover {
  color: #083b34 !important;
  font-size: 11px;
  font-style: italic;
  padding: 10px 10px !important;
  background: #0ae8cd;
  cursor: none;
}

.dashboard-us-vr-banner {
  background: #FAFAFA;
  background-size: cover;
  padding: 50px 20px;
}

.purple-block-background {
  padding: 50px 20px;
}

.purple-block-background:before {
  background: url('../src/assets/images/Newui-image/management-consol-bg.png');
  opacity: 1;
}

.option-block .Option-small-title {
  font-size: clamp(0.8125rem, 0.5781rem + 0.75vw, 1rem);
  font-weight: 500;
  color: #070B1C;
  text-decoration: none;
  display: block;
}

.option-block {
  text-align: center;
  padding: 20px;
  background: #FAFAFA;
  min-height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.option-blck-imges {
  max-width: 100px;
  margin-bottom: 10px;
  min-height: 50px;
  max-height: 60px;
  object-fit: contain;
  object-position: center;
  width: 100%;
}

.section-category-blocks {
  padding: 00px 0px 50px;
  background: #FAFAFA;
}

.offer-cards {
  background: white;
  padding: 30px 20px 10px;
  margin-bottom: 30px;
  box-shadow: -1px 14px 20px -11px rgb(169 169 169 / 20%);
}

.option-block .Option-small-title:hover {
  text-decoration: underline;
}

.option-block.special-offers {
  position: relative;
}

.option-block.special-offers .special-offer-text {
  position: absolute;
  font-weight: 500;
  font-size: clamp(0.6875rem, 0.6094rem + 0.25vw, 0.75rem);
  top: 0;
  left: 10px;
  background: #F7545F;
  padding: 5px 12px;
  border-radius: 0px 0px 5px 5px;
  color: white;
}

.offers-card-options .sub-title {
  font-size: clamp(0.875rem, 0.5625rem + 1vw, 1.125rem);
}

.breadcrumb-header a {
  font-size: clamp(0.8125rem, 0.6563rem + 0.5vw, 0.9375rem);
  text-decoration: none;
  color: rgba(255, 255, 255, .70);
}

.breadcrumb-header li:before {
  color: #ffffff !important;
}

.breadcrumb-header .active {
  font-size: clamp(0.8125rem, 0.6563rem + 0.5vw, 0.9375rem);
  color: #ffffff !important;
}

.loggedIn-active .top-navbar .primary-menu .primary-menu-list .list-group-item a {
  font-size: clamp(0.8125rem, 0.7128rem + 0.3191vw, .9rem);
  padding: 40px 11px;
}

.data-section {
  padding: 50px 20px;
}

.btn-apply {
  font-size: 17px;
  text-decoration: none;
  margin: 3px;
}

.table-preview-box {
  padding: 20px;
  background: #F6F6F6;
  border-radius: 5px;
  min-height: max-content;
  position: relative;
  /* padding-bottom: 120px; */
  overflow-y: auto !important;
}

.table-form-datalist {
  border-collapse: separate !important;
  border-spacing: 0 10px !important;
}

.table-form-datalist th {
  background: transparent;
  color: #FAFAFA;
  font-size: 13px;
  font-weight: 500;
  padding: 12px 17px;
  position: relative;
}

.table-form-datalist th:after {
  content: '';
  background: #FAFAFA;
  opacity: .6;
  position: absolute;
  height: 70%;
  width: 1px;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.table-form-datalist .main-table-header {
  background: #070B1C;
  border-radius: 10px;
  position: sticky;
  top: 0;
}

.table-form-datalist th,
.table-form-datalist td {
  vertical-align: middle;
  white-space: nowrap;
}

.table-form-datalist td {
  font-size: 14px;
  font-weight: 500;
  padding: 10px 17px;
}

.table-form-datalist tbody td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
}

.table-form-datalist th:first-child,
.table-form-datalist td:first-child {
  border-radius: 10px 0 0 10px;
  vertical-align: middle;
}

.table-form-datalist th:last-child,
.table-form-datalist td:last-child {
  border-radius: 0 10px 10px 0;
  border-right: none;
}

.table-form-datalist th:last-child:after {
  display: none;
}

.table-form-datalist .cost-value {
  white-space: nowrap;
}

.table-form-datalist .editbtn i {
  color: #0DB95D;
}

.table-form-datalist .winnersBtn {
  color: var(--winners);
  padding: 4px 10px;
  background: #5024880d;
  border-radius: 4px;
  font-size: 16px;
}

.editbtn i {
  color: #0DB95D;
}

.table-form-datalist .deleteBtnList {
  margin-top: -2px;
}

.deleteBtnList {
  margin-top: -2px;
  background: #FAFAFA;
}

.table-form-datalist .deleteBtnList i {
  color: var(--delete-color);
}

.addstoreBtn {
  margin-bottom: 1rem !important;
}

.tbl-ftr-pagination .pagination {
  margin-bottom: 0px;
}

.tbl-ftr-pagination .pagination .page-link {
  font-size: 15px;
  border: none;
  padding: 0px 5px;
  color: #070B1C;
}

.tbl-ftr-pagination .pagination .page-link:focus,
.tbl-ftr-pagination .pagination .page-link:hover {
  box-shadow: none;
  background: transparent;
  color: #4E7D98;
}

.table-form-datalist .tablefooter {
  position: absolute;
  left: 50%;
  bottom: 10px;
  transform: translateX(-50%);
  width: 96.5%;
  display: inline-table;
}

.table-shield-box {
  max-height: 770px;
}

.table-form-datalist .rowTableSelect {
  min-width: 90px;
}

.lightBtnText,
.lightBtnText:hover {
  background: transparent;
  color: #070B1C;
  text-decoration: none;
}

.boldtext {
  font-weight: 500;
  font-size: 20px;
}

.lightBtnText i {
  color: #070B1C;
}

.lightBtnText:hover i {
  color: #F7545F;
}

.form-lable-update {
  font-size: 13px;
  margin-bottom: 5px;
  font-style: italic;
}

.store-logo-box {
  font-size: clamp(0.8125rem, 0.7793rem + 0.1064vw, 0.875rem);
  padding: 0px 20px;
  background-color: #FDFDFD;
  border: 1px solid #EBEBEB;
  border-radius: 5px;
}

#StorelogoImage {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  opacity: 0;
}

.uploadFileImgStoreLogo {
  max-width: 230px;
  width: 100%;
  min-height: 200px;
  max-height: 200px;
  object-fit: contain;
  margin: auto;
}

.deleteBtnList i {
  color: var(--delete-color);
}

.store-logo-box .deleteBtnList {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
}

.main-table-body td.descrition-text {
  max-width: 300px;
}

#accordionFlushVideo .video-box {
  display: none;
}

#accordionFlushVideo .video-box .videoPrint {
  height: 100%;
  width: 100%;
  max-height: 400px;
}

#accordionFlushVideo .video-box.showVideo {
  display: block;
}

#accordionFlushVideo .steps-order {
  margin-bottom: 10px;
  padding: 5px;
  font-size: 13px;
}

#accordionFlushVideo .steps-order li {
  margin-bottom: 15px;
}

#accordionFlushVideo .custom-accordion.collapsed {
  background: #FAFAFA;
  color: #070B1C;
  text-transform: capitalize;
}

#accordionFlushVideo .custom-accordion {
  background: #4E7D98;
  color: #ffffff;
}

#accordionFlushVideo .custom-accordion.collapsed:after {
  filter: brightness(0);
}

#accordionFlushVideo .custom-accordion:after {
  filter: brightness(110.5);
}

#accordionFlushVideo .custom-accordion:focus {
  box-shadow: none;
}

#accordionFlushVideo .vdo-link-txt .videoplyLink {
  font-size: 14px;
  color: #F7545F;
  text-decoration: none;
  background: transparent;
  padding: 0;
  border: none;
}

#accordionFlushVideo .vdo-link-txt .videoplyLink:hover {
  color: #4E7D98;
}

#accordionFlushVideo .accordion-button.collapsed:last-child {
  margin-bottom: 0px;
}

#accordionFlushVideo .accordion-item {
  margin-bottom: 15px;
  border: none;
}

#accordionFlushVideo .accordion-collapse {
  border: none;
}

#accordionFlushVideo .accordion-body {
  background: #f8fcff;
}

.dollaricon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: #4E7D98;
}

.accordion-button.custom-accordion {
  text-transform: capitalize;
}

/* Table Form */
.e-grid.table {
  border-color: #eaeaea;
  padding: 15px;
  background: #f6f6f6;
  border-radius: 10px;
}

.e-grid.table .e-content,
.e-grid.table .e-gridcontent {
  background-color: transparent !important;
  background: transparent !important;
}

.e-grid.table .e-table.e-sortfilter {
  background: #070b1c;
  border-radius: 10px;
  border: none !important;
  box-shadow: none !important;
}

.e-grid.table .e-table .e-gridheader.e-lib.e-droppable {
  border: none !important;
}

.e-grid.table .e-table .e-headercell,
.e-headercell .e-headertext {
  background: transparent !important;
  color: white !important;
}

.e-grid.table .e-table th.e-headercell {
  border-right: 1px solid #727272 !important;
}

.e-grid.table .e-table th.e-headercell:last-child {
  border: none !important;
}

.e-grid.table .e-table .e-headercell .e-sortfilterdiv.e-icons {
  color: white !important;
}

.e-grid.table .e-table {
  border-collapse: separate !important;
  border-spacing: 0 5px !important;
  background: transparent;
  background-color: transparent;
  padding: 0px;
  border-color: transparent !important;
}

.e-grid.table .e-table .e-row td {
  background: white;
  font-size: 14px;
  vertical-align: middle;
  font-weight: 400;
  border: none !important;
}

.e-grid.table .e-table .e-row td:first-child {
  border-radius: 10px 0px 0px 10px !important;
}

.e-grid.table .e-table .e-row td:last-child {
  border-radius: 0px 10px 10px 0px !important;
}

.e-grid.table .e-gridpager.e-control.e-pager.e-lib {
  padding: 5px;
}

.e-grid.table .e-link.e-numericitem.e-spacing.e-currentitem.e-active {
  background: white;
}

/* Table Form End */


/* Form Sync Style */
ejs-textbox.e-textbox span.e-input-group.e-control-wrapper,
ejs-datetimepicker.e-datetimepicker span.e-input-group.e-control-wrapper,
ejs-datepicker.e-datepicker span.e-input-group.e-control-wrapper,
ejs-dropdownlist.e-dropdownlist span.e-input-group.e-control-wrapper,
ejs-numerictextbox span.e-input-group.e-control-wrapper,
/*ejs-numerictextbox span.e-input-group.e-control-wrapper,*/
ejs-maskedtextbox span.e-input-group.e-control-wrapper,
ejs-multiselect .e-input-group.e-control-wrapper,
ejs-daterangepicker .e-input-group.e-control-wrapper   {
  font-size: clamp(0.8125rem, 0.7793rem + 0.1064vw, 0.875rem);
  padding: 5px 15px;
  background-color: #fdfdfd;
  border: 1px solid #EBEBEB;
  border-radius: 5px;
}

form small.e-error.ng-star-inserted {
  font-size: 13px;
  color: #ff4848;
}

form label.form-label,
form label.form-check-label {
  font-size: 13px;
  margin-bottom: 5px;
  font-style: italic;
  color: #212529;
}

form .e-btn {
  font-size: clamp(0.8125rem, 0.7128rem + 0.3191vw, 1rem) !important;
  color: #fff !important;
  min-width: 192px;
  background: #502488 !important;
  padding-top: 9px;
  padding-bottom: 9px;
  text-align: center !important;
}

form .e-btn:hover,
form .e-btn:focus,
form .e-btn.e-info:hover,
form .e-btn.e-info:focus {
  background-color: #C70039 !important;
}

form.ng-invalid .e-btn:hover,
form.ng-invalid .e-btn:focus,
form.ng-invalid .e-btn.e-info:hover,
form.ng-invalid .e-btn.e-info:focus {
  background-color: #C70039 !important;
  border-color: #C70039 !important;
}

form .file-upload.ng-star-inserted .cus_drop-area.ng-star-inserted {
  border: none !important;
  margin: auto;
  border-radius: 10px;
  padding: 10px 20px 19px;
  font-size: 10px;
  width: 100%;
  line-height: 11px;
}

form .file-upload.ng-star-inserted .cus_drop-area.ng-star-inserted button.btn-danger.e-btn {
  position: absolute;
  top: 21px;
  right: 20px;
  max-width: 32px !important;
  width: 100%;
  text-align: center !important;
  padding: 1px 0px;
  border-radius: 38px !important;
  min-width: unset !important;
}

form .innter-tbl-box {
  border: 1px dashed #b7b7b7;
}

/* Draw Count Down */
.countdown ul {
  padding: 40px 20px;
  background: #f8f8f8;
  text-align: center;
  display: flex !important;
  justify-content: center;
  flex-wrap: wrap;
}

.countdown ul li {
  font-size: clamp(1rem, 0.6875rem + 1vw, 1.25rem) !important;
  display: block !important;
  max-width: 200px;
  min-width: 160px;
  width: 100%;
}

.countdown .e-info {
  background: #4d1e80 !important;
  color: #fff;
  border-radius: 10px !important;
  box-shadow: inset 0px -10px 13px 0px rgb(32 11 86) !important;
}

.countdown ul li span {
  display: block !important;
  max-width: 200px;
  width: 100%;
  margin: auto;
  font-size: clamp(1.875rem, -0.4688rem + 7.5vw, 3.75rem);
  font-weight: 600;
  font-family: monospace;
}

.countdown b.h1 {
  margin-bottom: 20px !important;
  display: block;
  font-weight: 700;
  color: #070b1c;
  text-align: left;
}

/* Draw Spinner Page */
.spinner {
  background: #FAFAFA;
  padding: 30px 20px;
}

.spinner-image {
  max-width: 290px;
  width: 100%;
  margin: 0px auto 15px;
}

table .e-rowcell.e-templatecell img {
  max-width: 80px;
  max-height: 60px;
  min-height: 60px;
  object-fit: cover;
  object-position: center;
}

table .e-rowcell input.btn-check {
  position: relative;
  clip: unset;
  pointer-events: unset;
  border-radius: 50px;
  width: 30px;
  height: 30px;
  background-color: #f8f8f8;
  border: 2px dashed #e3e3e3;
  appearance: unset !important;
  cursor: pointer !important;
}

table .e-rowcell input.btn-check:checked {
  border-style: solid;
  border-color: #1eaf61;
  background: #0DB95D;
}

table .e-rowcell input.btn-check:checked:after {
  content: '\2713';
  font-size: 20px;
  font-weight: 800;
  color: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* Search Form & Text */
#basic-addon1 {
  color: white !important;
  padding: 2px;
  background: #5d3cd9 !important;
  border: none;
  font-size: 13px;
  border-radius: 0px;
  display: flex;
}

#basic-addon1:hover {
  background: #0DB95D;
}

.searchtext.form-control {
  height: 100%;
  border: none !important;
  background: transparent;
  padding: 2px;
}

.searchtext.form-control span {
  border: none;
}

.editor-tag span.tag-item.ng-star-inserted {
  background: #3aadc0;
  border-radius: 0px;
  line-height: auto;
  font-size: 13px;
  font-weight: 600;
}

.editor-tag span.tag-item.ng-star-inserted .remove-tag {
  background: #219aae;
  text-align: center;
  padding: 0px 6px 1px;
  display: inline-flex;
}

.editor-tag span.tag-item.ng-star-inserted .remove-tag:hover {
  background: #144c56;
}


/* Report Screen */

#report-tabContent .tab-pane {
  padding: 30px 20px;
  background: #FAFAFA;
}

#report-tabContent .section-tab-block {
  background: #ffffff;
  padding: 20px;
  border: 1px solid var(--menu-light);
  border-radius: 10px;
  margin-bottom: 20px;
}

#nav-report-tab {
  border: none;
}

#nav-report-tab .nav-link {
  padding: 20px;
  background: #f2f2f2;
  margin-right: 10px;
  color: #070B1C;
  border: none !important;
}

#nav-report-tab .nav-link:last-child {
  margin-right: 0px;
}

#nav-report-tab .nav-link.active {
  border: none;
  background: #fafafa;
  color: #070B1C;
  font-weight: 700;
  border: none !important;
}

#nav-report-tab .nav-link:hover,
#nav-report-tab .nav-link:focus {
  border: none !important;
}

.card-donation-box {
  text-align: center;
  background: #dfdfdf;
  padding: 20px;
  border-radius: 10px;
}

#collection-donation {
  background: #0db95d1c;
}

#recurring-donation {
  background: #4E7D981c;
}

#collection-donation i.bi {
  font-size: 49px;
  color: #0DB95D;
}

#recurring-donation i.bi {
  font-size: 49px;
  color: #4E7D98;
}

#collection-donation .money-tlt-rc {
  color: #0DB95D;
}

#recurring-donation .money-tlt-rc {
  color: #4E7D98;
}

.money-tlt-rc {
  font-size: 25px;
}

.titlecard-donation {
  text-transform: capitalize;
  font-weight: 600;
  color: var(--dark-color);
}

.money-text-para {
  font-size: 14px;
}

#accordionFlushVideo .accordion-body .row.videoRow div:first-child {
  max-width: 100% !important;
  width: 100%;
}

/* New Video Hint icon and downloads section */
.video-hintsicons,
.video-hintsicons i,
.video-hintsicons a {
  font-size: 15px;
  color: #FAFAFA;
}

.video-hintsicons,
.video-hintsicons:hover i,
.video-hintsicons:hover a {
  font-size: 15px;
  color: #FAFAFA;
}

.posterImgeView {
  max-height: 400px;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
  width: 100%;
}

.carddonationImage {
  max-height: 120px;
  min-height: 120px;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
  width: 100%;
}

.qrCodeText {
  padding-bottom: 84px;
}

/* CRM ADMIN CSS */
.crm-text-icons {
  font-size: 18px;
}

form ejs-richtexteditor .e-btn {
  background: transparent !important;
  color: #000 !important;
  font-size: 13px !important;
  border-radius: 0px !important;
}

form ejs-richtexteditor .e-toolbar-pop {
  border: 1px solid #dadada !important;
  left: 0px !important;
}

form ejs-richtexteditor .e-expended-nav {
  background: black !important;
  color: white !important;
}

form ejs-richtexteditor .e-expended-nav .e-icons {
  color: white !important;
}

form ejs-richtexteditor .e-toolbar-item .e-btn:focus span,
form ejs-richtexteditor .e-toolbar-item .e-btn:hover span {
  color: white !important;
}

#TrackableContent .tab-pane {
  background: #fbfbfb;
  padding: 15px;
}

#Trackable {
  border: none;
}

#Trackable .nav-link {
  color: #502488;
  background: transparent;
  border-radius: 0px;
  border: none !important;
}

#Trackable .nav-link.active {
  background-color: #502488;
  color: #ffffff;
  border-radius: 0px;
  border: none !important;

}

.small-para-info {
  font-size: 13px;
  margin: 5px 0px;
}

#email-marketing-tabContent .tab-pane {
  padding: 30px 20px;
  background: white;
}

#email-marketing-tabContent .section-tab-block {
  background: #ffffff;
  padding: 20px;
  border: 1px solid var(--menu-light);
  border-radius: 10px;
  margin-bottom: 20px;
}

#nav-email-marketing-tab {
  border: none;
}

#nav-email-marketing-tab .nav-link {
  padding: 20px;
  background: #f2f2f2;
  margin-right: 10px;
  color: #070B1C;
  border: none !important;
}

#nav-email-marketing-tab .nav-link:last-child {
  margin-right: 0px;
}

#nav-email-marketing-tab .nav-link.active {
  border: none;
  background: #fff;
  color: #070B1C;
  font-weight: 700;
  border: none !important;
}

#nav-email-marketing-tab .nav-link:hover,
#nav-email-marketing-tab .nav-link:focus {
  border: none !important;
}

#StorelogoImage2 {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  opacity: 0;
}

.uploadFileImgStoreLogo2 {
  max-width: 230px;
  width: 100%;
  min-height: 200px;
  max-height: 200px;
  object-fit: contain;
  margin: auto;
}

.learnmoreBtn {
  max-width: 100px;
  width: 100%;
  cursor: pointer;
  margin-bottom: 5px;
}

.btn.btn-search-icon {
  position: absolute;
  right: 10px;
  padding: 0px;
  top: 50%;
  transform: translateY(-50%) !important;
  z-index: 1;
  border: none !important;
  margin: 0 !important;
}

button.btn.btn-search-icon:focus,
button.btn.btn-search-icon:hover {
  background: transparent !important;
  color: #4E7D98;
  border-radius: 0 !important;
  box-shadow: none !important;
}

.input-search-box {
  max-width: 300px;
  width: 100%;
}

.search-input-field-grid {
  padding-right: 30px !important;
  background: #FDFDFD;
}

.search-input-field-grid:hover,
.search-input-field-grid:focus {
  border: 1px solid #4E7D98;
  background: white;
}


.nav-tab-list-box .nav-link {
  padding: 20px;
  background: #f2f2f2;
  margin-right: 10px;
  color: #070B1C;
  border: none !important;
}

.nav-tab-list-box .nav-link.active {
  border: none;
  background: #fff;
  color: #070B1C;
  font-weight: 700;
  border: none !important;
}

.nav-tab-list-box {
  border: none !important;
}


/*CRM DATA STYLE*/
.dataViewCard .card-donation-box {
  background: #FAFAFA;
}

.dataViewCard i {
  font-size: clamp(1rem, 0.375rem + 2vw, 1.5rem);
}

.dataViewCard .money-tlt-rc {
  font-size: clamp(1.25rem, 0.4688rem + 2.5vw, 1.875rem);
  font-weight: 600;
}

.dataViewCard .titlecard-donation {
  text-transform: capitalize;
  font-weight: 600;
  color: var(--dark-color);
}

.dataViewCard.greenOne .card-donation-box {
  background: #75d7a329 !important;
}

.dataViewCard.greenOne i,
.dataViewCard.greenOne .money-tlt-rc,
.dataViewCard.greenOne .titlecard-donation {
  color: #0DB95D !important;
}

.dataViewCard.blueOne .card-donation-box {
  background: #75aad729 !important;
}

.dataViewCard.blueOne i,
.dataViewCard.blueOne .money-tlt-rc,
.dataViewCard.blueOne .titlecard-donation {
  color: #4E7D98 !important;
}

.dataViewCard.voiletOne .card-donation-box {
  background: #50248814 !important;
}

.dataViewCard.voiletOne i,
.dataViewCard.voiletOne .money-tlt-rc,
.dataViewCard.voiletOne .titlecard-donation {
  color: #502488 !important;
}

.dataViewCard.pinkOne .card-donation-box {
  background: #ffbfc436 !important;
}

.dataViewCard.pinkOne i,
.dataViewCard.pinkOne .money-tlt-rc,
.dataViewCard.pinkOne .titlecard-donation {
  color: #F7545F !important;
}

.fetaures-side-hero-image {
  text-align: right;
}

.fetaures-side-hero-image img.sidebarTopImage {
  max-width: 190px;
  margin-left: auto !important;
  filter: drop-shadow(0px 10px 20px rgba(165, 165, 165, 0.2));
  background: #ffffff;
  padding: 10px;
  border-radius: 10px;
}

/* Chart Inner report */
.report-inner-chart-text {
  padding: 10px;
  background: #f8fbfc;
  margin-bottom: 15px;
}

.report-inner-chart-text .list-type-chart {
  padding: 0px;
}

.report-inner-chart-text .list-type-chart li {
  list-style: none;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px dashed var(--menu-light);
  font-size: 11px;
  flex-wrap: wrap;
}

.report-inner-chart-text .list-type-chart li:last-child {
  border-bottom: none;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.report-inner-chart-text .chart-innerTitle {
  font-size: 13px;
  font-weight: 700;
  color: #4E7D98;
}

.report-inner-chart-text .list-type-chart-inline {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0;
}

.report-inner-chart-text .list-type-chart-inline li {
  list-style: none;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  flex-wrap: wrap;
}


.icon-table-action {
  max-width: 20px !important;
  padding: 1px;
}

.mc-cards .option-block {
  height: 90%;
  position: relative;
  overflow: hidden;
}

.mc-cards .backside-card-view {
  transition: all 300ms linear;
  position: absolute;
  top: 150%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  visibility: hidden;
  opacity: 0;
}

.mc-cards .front-view-card {
  transition: all 300ms linear;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  visibility: visible;
  opacity: 1;
}

.mc-cards .option-block:hover .backside-card-view {
  transition: all 300ms linear;
  top: 50%;
  visibility: visible;
  opacity: 1;
}

.mc-cards .option-block:hover .front-view-card {
  transition: all 300ms linear;
  top: -50%;
  visibility: hidden;
  opacity: 0;
}

.img-fluid.rewardimage {
  max-width: 660px;
  margin: auto;
}

.reward-container {
  max-width: 1000px;
  margin: auto;
  background: white;
  box-shadow: 0px 15px 48px -12px rgb(122 122 122 / 18%);
}

.tag-reward {
  font-size: clamp(1.125rem, 0.1875rem + 3vw, 1.875rem);
  color: blueviolet;
}

.reward-section-data {
  background: #FAFAFA;
}

.account-status {
  padding: 5px 10px;
  background: #FF8A00;
  color: #ffffff;
  text-decoration: none;
  font-size: 13px;
  border-radius: 5px;
}

  .account-status.active {
    background: #0DB95D;
  }

  .account-status.closed {
    background: var(--delete-color);
  }



.accounts-tabs .profileTabsImg {
  max-width: 40px;
  max-height: 40px;
  min-width: 40px;
  min-height: 40px;
  object-fit: contain;
  object-position: center;
}

.ps-maintitle {
  border-bottom: 1px solid #dfdfdf;
  padding-bottom: 14px;
  margin-bottom: 20px;
}

.profileSetting-list-Content {
  flex: auto;
}

.UserImageBox {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.profileImagebox {
  font-size: clamp(0.8125rem, 0.7793rem + 0.1064vw, 0.875rem);
  padding: 0px 20px;
  background-color: #FDFDFD;
  border: 1px solid #EBEBEB;
  border-radius: 5px;
}

.uploadFileImguserProfile {
  min-width: 150px;
  width: 100%;
  min-height: 150px;
  max-height: 150px;
  object-fit: cover;
  margin: auto;
  max-width: 150px;
  border-radius: 80px;
  padding: 20px;
}

.profileImagebox .deleteBtnList {
  position: absolute;
  top: 10px;
  right: 10px;
}

.sub-menu-list {
  flex: none;
}

.profileSetting-list-Content .tab-pane {
  background: white;
  padding: 20px;
}

.text-form-anchor {
  color: blueviolet;
  text-decoration: none;
  word-break: break-all;
}

.sub-menu-list .nav-link {
  color: var(--dark-color) !important;
  margin-bottom: 10px;
  background-color: transparent;
  border-radius: 5px 0 0 5px !important;
  padding: 15px 20px;
}

.sub-menu-list .nav-link.active {
  color: var(--dark-color) !important;
  background-color: white !important;
  box-shadow: 1px 4px 6px rgb(233 233 233 / 50%) !important;
}



@media screen and (max-width:786px) {

  .sub-menu-list {
    flex: auto;
  }

    .sub-menu-list .nav-link.active {
      margin-right: 10px;
    }
}

@media screen and (max-width:786px) {

  .sub-menu-list {
    flex-wrap: nowrap;
    overflow-x: auto;
  }

    .sub-menu-list .nav-link {
      margin-right: 10px;
    }

      .sub-menu-list .nav-link span {
        white-space: nowrap;
      }
}


.profileSetting-list-Content .e-grid .e-table, .profileSetting-list-Content .e-grid .e-table td, .profileSetting-list-Content .e-grid .e-table th {
  table-layout: auto;
  white-space: normal;
}

@media screen and (max-width:786px) {
  .profileSetting-list-Content .e-grid .e-table, .profileSetting-list-Content .e-grid .e-table td, .profileSetting-list-Content .e-grid .e-table th {
    table-layout: fixed;
    white-space: nowrap;
  }
}

.MenuiconImage {
  max-width: 25px;
  margin-right: 5px;
  max-height: 30px;
  min-height: 30px;
  object-fit: contain;
  opacity: .8;
}

.primary-menu-list a:hover .MenuiconImage {
  opacity: 1;
}



.purple-bg-color {
  background: #663399;
  opacity: 1;
}

.purple-bg-color::before {
  display: none;
}

.scanQRImageShow {
  position: absolute;
  right: 15px;
  top: 50%;
  max-width: 109px;
  transform: translateY(-50%);
}


.wl-card-content {
  flex-wrap: wrap;
}



.startValue {
  position: absolute;
  top: 100%;
  font-size: 12px;
  color: #B5CADB;
  font-weight: 500;
}

.finalValue {
  position: absolute;
  top: 100%;
  right: 0;
  font-size: 12px;
  color: #B5CADB;
  font-weight: 500;
}

.dn-progressTab {
  margin-bottom: 30px;
}



.data-section.lightWihteLabel {
  background: #FAFAFA;
  padding: 50px 0px;
}

.wl-blog-details-tabs {
  padding: 20px;
  background: white;
  border: 1px solid #f4f4f4;
  border-radius: 5px;
}

/* .custom-slider-vertical {
  position: relative;
  display: flex;
  overflow: hidden;
} */

/* .custom-slider-vertical:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100px;
  background: linear-gradient(0deg, #fafafa, transparent);
  width: 100%;
} */

/* .custom-slider-vertical .slick-track {
  display: flex;
  transition: transform 0.5s ease;
}
.custom-slider-vertical .slick-slide {
  height: auto;
  margin: 0 10px;
  transition: transform 0.3s ease;
}


.custom-slider-vertical .slick-current {
  transform: scale(1.05);
} */

.custom-slider-horizontal {
  overflow: hidden !important; /* Hide overflow */
}

.custom-slider-horizontal .slick-track {
  display: flex !important; /* Ensure the track is displayed in a row */
  margin-left: unset ;
  margin-right: unset;
}

.custom-slider-horizontal .slick-slide {

  margin: 0 10px; /* Space between slides */
}
.custom-slider-horizontal .slick-dots li{
  width: 10px !important;
  height: 10px !important;
}
.custom-slider-horizontal .slick-dots li button {
  width: 10px !important;
  height: 10px !important;
  padding: 0px !important;
  position: relative;
}
.custom-slider-horizontal .slick-dots li.slick-active button:before {
  background-color: var(--green-color) !important;
  color: transparent !important;
}
.custom-slider-horizontal .wl-card-content p{
  line-height: 20px;
}
.custom-slider-horizontal .progressorBox{
  position: absolute;
  width: 99%;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, 0%);
}
.custom-slider-horizontal .progressorBox .startValue {
  position: absolute;
  top: 0;
  left: 5px;
  font-size: 12px;
  color: #B5CADB;
  font-weight: 500;
}
.custom-slider-horizontal .progressorBox .finalValue {
  position: absolute;
  top: 0;
  right: 5px;
  font-size: 12px;
  color: #B5CADB;
  font-weight: 500;
}
.custom-slider-horizontal .slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: " " !important;
  width: 100%;
  height: 100%;
  font-family: "slick";
  font-size: 13px;
  line-height: unset;
  text-align: center;
  background-color: white;
  border-radius: 100px !important;
  color: unset;
}
.iframeVideoMW.position-relative.ng-star-inserted iframe {
  min-height: 500px;
}
.progress-bar {
  height: 100%;
  width: 100%;
  background: #dfdfdf;
}
progress::-webkit-progress-bar {
  background: #fff !important;
}
.side-action-wl .custom-btn.lightBtn:hover {
  color: white !important;
}
pre {
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
  white-space: pre-wrap;
  word-break: break-word;
}

.header_mobile_menu {
  display: none;
}

.header_main {
  padding      : 17px 0;
  background   : #FFF;
  box-shadow   : 0px 4px 36px 0px rgba(0, 0, 0, 0.06);
  margin-bottom: 10px;
}

.header_row {
  display        : flex;
  flex-wrap      : wrap;
  justify-content: space-between;
  align-items    : center;
  margin         : 0 -10px;
}

/* .header_left_logo_block {
  display: block;
  height : 28px;
} */

.header_right_logo,
.header_left_logo {
  width  : 20%;
  padding: 0 10px;
}


.header_left_logo_block {
  height : 100%;
  width  : auto;
  display: block;
}

.header_left_logo_block img{
height : 100px;
}

.header_nav_block {
  width  : 60%;
  padding: 0 10px;
}

.header_nav_block ul {
  display        : flex;
  align-items    : center;
  justify-content: center;
  list-style     : none;
  gap            : 26px;
  padding-top: 12px;
}

.header_nav_block ul a {
  color         : #0F2480;
  font-family   : 'Poppins', sans-serif;
  font-size     : 16px;
  font-weight   : 400;
  line-height   : normal;
  text-transform: capitalize;
  margin        : 0;
  position      : relative;
  display       : block;
  transition    : all .4s ease;
  text-decoration: none;
}

.header_nav_block ul a::after {
  content      : '';
  position     : absolute;
  width        : 0;
  height       : 2px;
  border-radius: 14px;
  background   : #54FBDD;
  bottom       : -6px;
  left         : 0;
  right        : 0;
  margin       : auto;
  transition   : all .4s ease;
}

.header_nav_block ul a.active::after,
.header_nav_block ul a:hover::after {
  width: 30px;
}

.header_nav_block ul a.active {
  font-weight: 600;
}

.header_right_logo_block {
  width      : 160px;
  height     : auto;
  margin-left: auto;
}

.header_right_logo_block img {
  width  : 100%;
  height : auto;
  display: block;
}


.newsong_title {
  color         : #000;
  font-family   : 'Poppins', sans-serif;
  font-size     : 40px;
  font-weight   : 700;
  line-height   : 1.3;
  text-transform: capitalize;
  margin-bottom : 5px;
  margin-top: 5px;
}

.newsong_info {
  position     : relative;
  text-align   : right;
  padding-right: 255px;
  margin-bottom: 110px;
}

.newsong_info p {
  color      : #0F0E5B;
  font-family: 'Poppins', sans-serif;
  font-size  : 40px;
  font-style : normal;
  font-weight: 300;
  line-height: normal;
  padding    : 7px 0;
  margin-right: 246px;
  text-wrap: nowrap;
}

strong {
  font-weight: 700;
  color: var(--dark-blue);
}

.newsong_barcode {
  position : absolute;
  right    : 102px;
  top      : 50%;
  transform: translateY(-50%);
  width    : 150px;
  cursor: pointer;
}

.newsong_info::after {
  content   : '';
  position  : absolute;
  width     : 100vw;
  height    : 100%;
  background: #54FBDD;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  top       : 0;
  left      : 50%;
  transform : translateX(-50%);
  z-index   : -1;
}

.newsong_barcode_img {
  position     : relative;
  padding-top  : 100%;
  border-radius: 10px;
  border       : 0.5px solid rgba(0, 0, 0, 0.20);
  box-shadow   : 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  overflow     : hidden;
}

.newsong_barcode_img img {
  position  : absolute;
  width     : 100%;
  height    : 100%;
  top       : 0;
  left      : 0;
  object-fit: cover;
}

.general_fund_row {
  display    : flex;
  align-items: center;
  margin     : 0 -10px;
  flex-wrap  : wrap;
}

.general_fund_left_slider {
  width  : 31%;
  padding: 0 10px;
  margin-top : -5%
}

.general_fund_right_slider_block {
  width  : 63%;
  padding: 0 10px;
}

.general_fund_nav_item {
  padding: 8px;
}

.general_fund_nav_item_block {
  border-radius: 20px;
  border       : 0.4px solid rgba(0, 0, 0, 0.20);
  background   : #FFF;
  padding      : 12px;
  display      : flex;
  flex-wrap    : wrap;
  transition   : all .4s ease;
  cursor       : pointer;
  align-items  : center;
}

.general_fund_nav_img {
  width: 46.5%;
}

.general_fund_nav_img_block {
  position     : relative;
  padding-top  : 100%;
  border-radius: 20px;
  overflow     : hidden;
}

.general_fund_nav_img_block img {
  position  : absolute;
  width     : 100%;
  height    : 100%;
  top       : 0;
  left      : 0;
  object-fit: cover;
}

.general_fund_nav_info {
  width       : 53.5%;
  padding-left: 16px;
}

.general_fund_nav_info_block p {
  color      : #000;
  font-family: 'Poppins', sans-serif;
  font-size  : 16px;
  font-weight: 400;
  line-height: 1.8;
  margin: 0;
}

.general_fund_nav_info_block p strong {
  color: #000;
}

.general_fund_for_slide_item_block {
  border-radius: 20px;
  background   : #FFF;
  box-shadow   : 0px 4px 11px 0px rgba(15, 14, 91, 0.20);
  padding      : 20px 20px 25px;
}

.general_fund_for_slide_top_row {
  display        : flex;
  align-items    : center;
  flex-wrap      : wrap;
  justify-content: space-between;
  margin-bottom  : 4px;
}

.general_fund_for_slide_title {
  color      : #0F0E5B;
  font-family: 'Poppins', sans-serif;
  font-size  : 40px;
  font-weight: 300;
  line-height: 1;
}

.general_fund_for_btn_block {
  display  : flex;
  gap      : 20px;
  flex-wrap: wrap;
  list-style: none;
}

.general_fund_for_btn {
  color         : #5D3CD9;
  font-family   : 'Poppins', sans-serif;
  font-size     : 15px;
  font-weight   : 500;
  line-height   : 1;
  margin        : 0;
  border-radius : 50px;
  border        : 1px solid #5D3CD9;
  padding       : 15px 16px;
  text-transform: capitalize;
  transition    : all .4s ease;
  background    : #fff;
  cursor: pointer;
}

.general_fund_for_btn.active:hover{
  background: transparent;
  color     : #5D3CD9;
}

.general_fund_for_btn.active,
.general_fund_for_btn:hover {
  background: #5D3CD9;
  color     : #fff;
}
.general_fund_for_item {
  display: none;
}
.general_fund_for_item.active {
  display: block;
}
.general_fund_for_img {
  position     : relative;
  padding-top  : 46.2%;
  border-radius: 20px;
  overflow     : hidden;
  margin-bottom: 22px;
}

.general_fund_for_img img {
  position  : absolute;
  width     : 100%;
  height    : 100%;
  top       : 0;
  left      : 0;
  right     : 0;
  object-fit: cover;
}

.general_fund_for_item > p {
  color        : #000;
  font-family  : 'Poppins', sans-serif;
  font-size    : 16px;
  font-weight  : 400;
  line-height  : 1.75;
  margin-bottom: 17px;
}

.general_fund_for_info_btn_block {
  display        : flex;
  align-items    : center;
  justify-content: center;
  flex-wrap      : wrap;
  gap            : 30px;
  margin  : 15px 0;
  cursor: pointer;
}

.general_fund_for_info_btn {
  border-radius : 10px;
  border        : 1px solid #5D3CD9;
  color         : #5D3CD9;
  font-family   : 'Poppins', sans-serif;
  font-size     : 18px;
  font-weight   : 500;
  line-height   : normal;
  text-transform: capitalize;
  padding       : 13px 15px;
  margin        : 0;
  transition    : all .4s ease;
  cursor: pointer;
}

.general_fund_for_info_btn:hover {
  color           : #fff;
  background-color: #5D3CD9;
}

.general_fund_for_info_btn.active {
  color           : #fff;
  background-color: #5D3CD9;
}

.general_fund_for_social_btn {
  display        : flex;
  align-items    : center;
  justify-content: center;
  list-style     : none;
  gap            : 14px;
  cursor: pointer;
}

.general_fund {
margin-top: -50px;
  margin-bottom: 80px;
}

.general_fund_for_social_icon {
  display        : flex;
  margin         : 0;
  align-items    : center;
  justify-content: center;
  width          : 48px;
  height         : 48px;
  border-radius  : 50px;
  border         : 1px solid #FFF;
  background     : #0F0E5B;
  box-shadow     : 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color          : #fff;
  transition     : all .4s ease;
}

.general_fund_for_social_icon svg {
  width     : 24px;
  height    : 32px;
  object-fit: contain;
  display   : block;
}

.general_fund_for_social_icon:hover {
  background: #FFF;
  color     : #0F0E5B;
}

.general_fund_for_slide_item {
  padding: 10px;
}

.general_fund_nav_item_block:hover,
.general_fund_nav_item.slick-current .general_fund_nav_item_block {
  box-shadow: 0px 4px 11px 0px rgba(15, 14, 91, 0.20);
}

.general_fund_nav_slider_arrow {
  display        : flex;
  align-items    : center;
  gap            : 17px;
  justify-content: center;
}

.general_fund_nav_slider_arrow .slick-arrow {
  position  : unset;
  width     : 31px;
  height    : 31px;
  transform : unset;
  transition: all .4s ease;
}

.general_fund_nav_slider_arrow .slick-arrow svg {
  width  : 100%;
  height : 100%;
  display: block;
}

.general_fund_nav_slider_arrow .slick-arrow::before {
  display: none;
}

.general_fund_nav_dots_list {
  display    : flex;
  align-items: center;
  list-style : none;
  gap        : 12px;
}

.general_fund_nav_dots_list li {
  color      : #0F2480;
  text-align : center;
  font-family: 'Poppins', sans-serif;
  font-size  : 16px;
  font-weight: 400;
  line-height: 1.2;
  display    : block;
  transition : all .4s ease;
  cursor     : pointer;
}

.general_fund_nav_dots_list li:hover {
  text-decoration          : underline;
  text-underline-offset    : 4px;
  text-decoration-thickness: 2px;
}

.general_fund_nav_dots_list li.slick-active {
  text-decoration          : underline;
  text-underline-offset    : 4px;
  text-decoration-thickness: 2px;
  font-weight              : 700;
}

.video_slider_for_row {
  display  : flex;
  flex-wrap: wrap;
  margin   : 0 -10px;
}

.video_slider_for_block {
  width: 70.5%;
}

.video_slider_nav_block {
  width: 29.5%;
}

.video_slide_for_item {
  padding: 9px;
}

.video_slide_for_item_block {
  background   : #FFF;
  box-shadow   : 0px 5px 5px rgba(15, 36, 128, 0.20);
  padding      : 25px;
  border-radius: 20px;
}

.video_slide_for_button {
  position       : absolute;
  width          : 100px;
  height         : 100px;
  top            : 50%;
  left           : 50%;
  transform      : translate(-50%, -50%);
  display        : flex;
  align-items    : center;
  justify-content: center;
  border-radius  : 120px;
  border         : 1px solid #FFF;
  background     : rgba(255, 255, 255, 0.30);
  backdrop-filter: blur(5.5px);
  margin         : 0;
  transition     : all .4s ease;
  color          : #0F0E5B;
}

.video_slide_for_item_wrapper {
  position     : relative;
  padding-top  : 54.3%;
  border-radius: 20px;
  overflow     : hidden;
}

.video_slide_for_main {
  position  : absolute;
  width     : 100%;
  height    : 100%;
  top       : 0;
  left      : 0;
  right     : 0;
  object-fit: cover;
}

.video_slide_for_button svg {
  width  : 40px;
  height : auto;
  display: block;
}

.video_slide_for_button:hover {
  border-color: rgb(15 14 91);
  background  : rgba(15, 14, 91, 0.30);
  color       : #fff;
}

.video_slider_nav_item {
  padding: 9px;
}

.video_slider_nav_item {
  padding: 9px;
}

.video_slider_nav_item_block {
  position     : relative;
  padding-top  : 56.3%;
  box-shadow   : 0px 5px 15px rgba(15, 36, 128, 0.20);
  border-radius: 20px;
  overflow     : hidden;
  cursor       : pointer;
}

.video_slider_nav_item_block img {
  position  : absolute;
  width     : 100%;
  height    : 100%;
  top       : 0;
  left      : 0;
  object-fit: cover;
}

.video_slider_nav .slick-list {
  padding-top: 0 !important;
}

.video_slider_nav::after {
  content   : '';
  position  : absolute;
  width     : 100%;
  height    : 100px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  bottom    : 0;
}

.video_slider_for .slick-dots {
  bottom         : -30px;
  display        : flex;
  justify-content: center;
  align-items    : center;
  gap            : 5px;
}

.video_slider_for .slick-dots li {
  margin : 0;
  width  : auto;
  height : auto;
  display: block;
}

.video_slider_for .slick-dots li button {
  width        : 10px;
  height       : 10px;
  padding      : 0;
  background   : #DADAFF;
  transition   : all .4s ease;
  border-radius: 50px;
}

.video_slider_nav .slick-arrow::before,
.video_slider_for .slick-dots li button::before {
  display: none;
}

.video_slider_for .slick-dots li.slick-active button {
  width     : 55px;
  background: #0F0E5B;
}

.video_slider_for .slick-dots li button:hover {
  background: #0F0E5B;
}

.video_slider_nav .slick-arrow {
  margin   : 0;
  transform: unset;
  width    : 40px;
  height   : 40px;
}

.video_slider_nav .slick-arrow svg {
  width : 100%;
  height: 100%;
}

.video_slider_nav .slick-arrow {
  margin   : 0;
  transform: unset;
  width    : 40px;
  height   : 40px;
  right    : -50px;
  left     : auto;
  top      : auto;
}

.video_slider_nav .slick-arrow.slick-prev {
  top      : calc(50% - 35px);
  transform: translateY(-50%);
}

.video_slider_nav .slick-arrow.slick-next {
  top      : calc(50% + 35px);
  transform: translateY(-50%);
}

.video_slider_section {
  margin-bottom: 145px;
}
.general_fund_for_table {
  padding: 0 35px;
}
.general_fund_for_table_heading h2 {
  color: #FFF;
  font-family: 'Poppins',sans-serif;
  font-size: 28px;
  font-weight: 300;
  line-height: normal;
}
.general_fund_for_table_heading {
  text-align: center;
  border-radius: 10px 10px 0px 0px;
  background: #0F0E5B;
  padding: 12px 0 10px;
}
.general_fund_for_table_heading h2 strong {
  color: #fff;
}
.general_fund_for_table_label {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  background: #E9E9E9;
  margin-bottom: 0px;
}
.general_fund_for_table_label li {
  padding: 16px 10px;
  color: #000;
  font-family: 'Poppins',sans-serif;
  font-size: 22px;
  font-weight: 700;
  line-height: normal;
  text-align: center;
}
.general_fund_for_table_gifts li,
.general_fund_for_table_information li {
  width: 25%;
}
.general_fund_for_table_value {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  background: #fff;
  border: 1px solid #0F0E5B;
  border-radius: 0 0 10px 10px;
}
.general_fund_for_table_value li {
  padding: 17px 10px;
  color: #000;
  font-family: 'Poppins',sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}
.general_fund_for_table_value li:not(:last-child) {
  border-right: 1px solid #0F0E5B;
}
.general_fund_for_table_update ul li:last-child {
  width: 50%;
}
.general_fund_for_table_update ul li:not(:last-child) {
  width: 25%;
}
.general_fund_for_item > div:not(:last-child) {
  margin-bottom: 35px;
}
/* My same code add */

.features-hero-section {
  position: relative;
  padding: 80px 20px;
  background: #FDFDFD;
}
.purple-bg-color {
  background: #663399;
  opacity: 1;
}
.banner-title{
  font-size: clamp(1.875rem, 1.5426rem + 1.0638vw, 2.5rem);
  font-weight: bold;
  color: #070B1C;
}



.QRBox-content p {
  color: white;
  margin-bottom: 0px !important;
  text-align: right !important;
  margin-right: 150px;
  font-weight: 500;
  font-size: 17px;
  line-height: normal;
  width: 100%;
}
.data-section.lightWihteLabel {
  background: #FAFAFA;
  padding: 50px 0px;
}

.dropdown_shorts span.e-input-group.e-control-wrapper {
  box-shadow: none;
  font-size: 19px;
  padding: 10px 15px;
  background-color: #fdfdfd;
  border: 1px solid #EBEBEB !important;
  color: #212529 !important;
  max-width: 100% !important;
  border-radius: 10px;
}
.dropdown_shorts span.e-input-group.e-control-wrapper:focus{
  background-color: white;
  border: 1px solid #ebebeb !important;
}
.dropdown_shorts span.e-input-group.e-control-wrapper:focus:before , .dropdown_shorts span.e-input-group.e-control-wrapper:focus:after{
  display: none;
}
.dropdown_shorts .e-ddl.e-input-group.e-control-wrapper .e-ddl-icon{
  color: #212529 !important;
}
.top-action-shopbtns .search-button , .top-action-shopbtns .cart-button{
  padding: 0;
  background: transparent !important;
  height: auto !important;
  color: #5D3CD9;
  border : none !important;
  position: relative;
}
.top-action-shopbtns .search-button button{
  padding: 15px 40px;
  background: #663399 !important;
  height: auto !important;
  border: 1px solid #663399 !important;
  display: flex;
  color: white;
  justify-content: center;
  margin: 0;
  align-items: center;
}
.top-action-shopbtns .cart-button button{
  padding: 14px 40px;
  background: rgba(255, 255, 255, .2) !important;
  height: auto !important;
  border: 1px solid  #663399!important;
  display: flex;
  color: #663399;
  margin: 0;
  align-items: center;
}
.top-action-shopbtns button i, .top-action-shopbtns button span{
  font-size: clamp(0.875rem, 0.7188rem + 0.5vw, 1rem) !important;
}
.top-action-shopbtns .search-button button:hover{
  background: white !important;
  color: #663399;
}

.top-action-shopbtns .cart-button button:hover{
  background-color: #fff !important;
  color: #663399 !important;
}
.countaddtocart {
  position: absolute;
  min-width: 29px;
  min-height: 29px;
  background: #ff6d00;
  line-height: 20px;
  max-width: 29px;
  max-height: 29px;
  padding: 5px 0px;
  right: -12px;
  top: 0px;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.allProductItemsCards .card{
  border: 1px solid #f0f0f0;
}
.allProductItemsCards .card img{
  width: 100%;
  max-height: 200px;
  min-height: 200px;
  object-fit: cover;
  object-position: center;
}
.allProductItemsCards .detailsBtnsAction{
  background: #ff6d00;
  color: white;
  border: 1px solid #ff6d00;
}
.allProductItemsCards .detailsBtnsAction:hover, .allProductItemsCards .detailsBtnsAction:focus{
  box-shadow: none;
  background: white;
  border-color: #ff6d00;
  color: #ff6d00;
}
.allProductItemsCards .card-title-name {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
}
.note_help_main{
  font-size: 16px;
  color: #663399;
  text-align: center;
  margin-bottom: 0px;
  cursor: pointer;
  line-height: 26px;
}
#auction_description {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 99%;
  display: inline-block;
  vertical-align: middle;
  pointer-events: none;
}

.note_help_main:hover{
  text-decoration: underline;
}
.allProductItemsCards .currencyCost {
  font-size: 20px;
  font-weight: 600;
  color: #0db95d;
}
.completeDoneItem{
font-size: 20px;
font-weight: 600;
background-color: rgba(13, 185, 93, .1);
color: #0db95d;
padding: 10px;
border: 1px solid #0db95d;
}
.formqrtCardboard{
  background: white;
  padding: 20px;
  margin-top: 15px !important;
  border-radius: 10px;
}
.formqrtCardboard a{
  text-decoration: none;
}
.storeNameofDetails{
  border-bottom: 1px dashed #efefef;
  margin-bottom: 10px;
  padding-bottom: 5px;
  font-size: 16px;
}
.storeIconinsideDetails{
  color: #212529;
  font-size: 20px;
}
.itemNameofDetails {
  font-size: clamp(0.875rem, 0.75rem + 1vw, 1.5rem);
}
.itemPriceOfDetails {
  font-size: clamp(0.875rem, 0.675rem + 1.6vw, 1.875rem);
  font-weight: 700;
  color: #0db95d;
}
.itemDescriptionOfDetails {
  font-size: 14px;
  color: #1c1919;
  margin-bottom: 10px;
  line-height: 18px;
}
.storeSideImage{
  max-height: 500px;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
  border: 1px solid #dfdfdf;
}
.quantityboxCounting{
  display: flex;
}
.quantityboxCounting .general_fund_for_info_btn{
  font-size: 14px;
  background: #FDFDFD !important;
  color: #212529;
  border-radius: 0px;
  padding: 10px;
  border: 1px solid #dfdfdf !important;
  align-items: center;
}
.attribute-box-production-option {
  margin-bottom: 10px;
}
.attribute-box-production-option label {
  margin: 0;
  background: #fdfdfd;
  border: 1px solid #dfdfdf !important;
}
.attribute-box-production-option .product-name-extra-variation {
  font-size: 14px;
  color: #868686;
}
.attribute-box-production-option .btn-check:checked + label {
  color: #fff !important;
  background: #1c1919 !important;
}
.shoping-button button {
  background: #fdfdfd !important;
  color: #000000 !important;
}
.shoping-button button:hover {
  background: transparent !important;
  color: #5D3CD9 !important;
}

.total-n-code {

display: flex;

align-items: center;

width: 100%;

flex-wrap: wrap;

margin-bottom: 24px;
border-bottom: 1px dashed #b7b7b7;
padding: 10px 0px 24px !important;

}

.totalPayment.ng-star-inserted {

flex-grow: 1;

}

.total-n-code .shopping_expreience_cart_price.ng-star-inserted {

font-size: 17px;

margin-right: 20px;

}

.loadPromoValid {

background: #f6fffe;

padding: 10px;

border: 1px solid #0db95d;

border-radius: 5px;

display: flex;

flex-wrap: wrap;
flex-grow:1;

}
.all_total.ng-star-inserted.d-flex.flex-wrap.pt-2 {
  font-size: 17px;
  font-weight: 300;
}
.priceshowingTotal {
  font-size: 20px;
  font-weight: 400;
}
.promocodeLinkbtn a{
  font-size: 14px;
  text-decoration: none !important;
}
.promocodeLinkbtn a:hover{
  color: var(--dark-color);
}
.promomApplied {

font-size: 16px;

font-weight: 600;

color: #000;

margin-bottom: 5px;

}
.min-cut-off-text {
  color: #ff0065;
  font-weight: unset;
  text-decoration: line-through;
  margin-left: 10px;
  opacity: .7;
}
.promocodevisibleIcon {

font-size: 23px;

margin-right: 10px;

color: #1eaf61;
    transform: rotate(90deg);
}

.promocontent {

  display: flex;
  text-align: left;
  flex-direction: column;
  justify-content: center;

}


.promtexted {

margin-bottom: 5px;

color: #605f5f;

margin-left: 9px;
font-size: 12px;
line-height: unset;
}

.percent-and-remove {

display: flex;

align-items: center;

justify-content: flex-end;

margin-left: auto;

}

.total_promo_percent {

font-size: 20px;

margin: 5px;

font-weight: 600;

color: #0db95d;

}

.removePromoCode {
  font-size: 14px;
  text-decoration: none;
  font-weight: 500;
  color: #ff0065;
  margin-left: 10px;
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}

.QRBox-content .scanQRImageShow.topFeatures-icon-images.scanQrcodeImage {
  max-width: 120px !important;
  max-height: 120px;
  height: 100%;
  min-width: 120px;
  min-height: 120px;
  object-fit: contain;
  object-position: center;
  margin: 10px 0px;
}

@media(max-width:1600px) {
  .header_left_logo_block {
      height: 24px;
  }

  .header_left_logo_block img{
    height: 50px;
    margin-top: -13px;
  }

  .header_right_logo_block {
      width: 135px;
  }

  .newsong_title {
      font-size    : 44px;
      margin-bottom: 20px;
  }

  .newsong_barcode {
      width: 200px;
      right: 10px;
  }

  .newsong_info {
      padding-right: 210px;
      margin-bottom: 90px;
  }

  .newsong_info p {
      font-size: 52px;
      padding  : 5px 0;
      text-wrap: nowrap;
  }

  .general_fund_nav_info {
      padding-left: 10px;
  }

  .general_fund_nav_info_block p {
      font-size: 14px;
  }

  .general_fund_for_slide_title {
      font-size: 35px;
  }

  .general_fund_for_btn_block {
      gap: 8px;
  }

  .general_fund_for_btn {
      font-size: 16px;
      padding  : 13px 15px;
  }

  .general_fund_for_img {
      margin-bottom: 15px;
  }

  .general_fund_for_item>p {
      font-size    : 14px;
      margin-bottom: 10px;
  }

  .general_fund_for_info_btn {
      font-size: 16px;
      padding  : 8px 10px;
  }

  .general_fund_for_social_icon {
      width : 42px;
      height: 42px;
  }

  .general_fund_for_social_icon svg {
      width : 20px;
      height: 28px;
  }

  .general_fund_for_info_btn_block {
      gap          : 20px;
      margin: 20px 0;
  }

  .general_fund_nav_item {
      padding: 5px;
  }

  .video_slider_section {
      margin-bottom: 120px;
  }

  .video_slide_for_item_block {
      padding: 15px;
  }

  .video_slider_for_block {
      width: 72%;
  }

  .video_slider_nav_block {
      width: 28%;
  }

  .video_slide_for_button {
      width : 85px;
      height: 85px;
  }

  .video_slide_for_button svg {
      width: 35px;
  }
  .general_fund_for_table {
      padding: 0 20px;
  }
  .general_fund_for_table_heading h2 {
      font-size: 24px;
  }
  .general_fund_for_table_label li {
      padding: 15px 10px;
      font-size: 20px;
  }
  .general_fund_for_table_value li {
      padding: 15px 10px;
      font-size: 16px;
  }
  .general_fund_for_item > div:not(:last-child) {
      margin-bottom: 25px;
  }
}

@media(max-width:1400px) {
  .general_fund_for_table {
      padding: 0;
  }
  .general_fund_for_table_heading h2 {
      font-size: 20px;
  }
  .general_fund_for_table_label li {
      padding: 12px 10px;
      font-size: 16px;
  }
  .general_fund_for_table_value li {
      padding: 14px 10px;
      font-size: 14px;
  }
  .header_right_logo,
  .header_left_logo {
      width: 25%;
  }

  .header_nav_block {
      width: 50%;
  }

  .newsong_title {
      font-size    : 38px;
      margin-bottom: 15px;
  }

  .newsong_barcode {
      width: 175px;
  }

  .newsong_info {
      padding-right: 185px;
      margin-bottom: 60px;
  }

  .newsong_info p {
      font-size: 44px;
      text-wrap: nowrap;
  }

  .general_fund_left_slider,
  .general_fund_right_slider_block {
      padding: 0;
  }

  .general_fund_right_slider_block {
      width: 65%;
  }

  .general_fund_left_slider {
      width: 35%;
  }

  .general_fund_nav_item_block {
      padding: 8px;
  }

  .general_fund_nav_info_block p {
      font-size: 12px;
  }

  .general_fund_nav_slider_arrow .slick-arrow {
      width : 28px;
      height: 28px;
  }

  .general_fund_nav_dots_list li {
      font-size: 14px;
  }

  .general_fund_for_slide_item_block {
      padding: 15px 15px 20px;
  }

  .general_fund_for_slide_title {
      font-size: 34px;
  }

  .general_fund_for_btn {
      font-size: 14px;
      padding  : 10px 13px;
      line-height: 2;
  }

  .general_fund_for_img {
      margin-bottom: 10px;
  }

  .general_fund_for_info_btn_block {
      gap          : 15px;
      margin-bottom: 15px;
  }

  .general_fund_for_info_btn {
      font-size: 14px;
      padding  : 8px 10px;
  }

  .general_fund_for_info_btn svg {
      width : 16px;
      height: auto;
  }

  .general_fund_for_social_icon {
      width : 37px;
      height: 37px;
  }

  .general_fund_for_social_icon svg {
      width : 17px;
      height: 25px;
  }

  .general_fund {
      margin-bottom: 60px;
      margin-top: 60px;
  }

  .video_slider_section {
      margin-bottom: 100px;
  }

  .video_slide_for_item {
      padding: 5px;
  }

  .video_slider_nav_item {
      padding: 5px;
  }

  .video_slide_for_item_block {
      padding: 10px;
  }

  .video_slider_nav_item_block {
      border-radius: 15px;
  }

  .video_slider_nav .slick-arrow {
      width : 35px;
      height: 35px;
      right : -45px;
  }
}

@media(max-width:1199px) {
  .header_left_logo_block {
      height: 20px;
  }

  .header_left_logo_block img{
    height: 35px;
    margin-top: -10px;
  }

  .header_right_logo_block {
      width: 110px;
  }

  .header_nav_block ul a {
      font-size: 14px;
  }

  .header_nav_block ul a.active::after,
  .header_nav_block ul a:hover::after {
      width: 20px;
  }

  .header_main {
      padding      : 15px 0;
      margin-bottom: 30px;
  }

  .newsong_title {
      font-size    : 32px;
      margin-bottom: 10px;
  }

  .newsong_barcode {
      width: 150px;
      margin-right: -1%;
  }

  .newsong_info p {
      font-size: 34px;
      width: 100%;
      text-wrap: nowrap;
  }

  .newsong_info {
      padding-right: 420px;
      margin-bottom: 50px;
  }

  .video_slider_for_block,
  .video_slider_nav_block,
  .general_fund_left_slider,
  .general_fund_right_slider_block {
      width: 100%;
      margin-top: 5%;
  }

  .general_fund_right_slider_block {
      order: -1;
  }

  .general_fund_for_item>p {
      font-size: 12px;
  }

  .video_slider_nav::after {
      display: none;
  }

  .video_slider_nav .slick-list {
      padding-left: 0 !important;
  }

  .video_slider_nav .slick-arrow {
      top   : auto !important;
      bottom: -50px;
      right : auto;
      left  : auto;
  }

  .video_slider_nav .slick-arrow.slick-prev {
      left     : calc(50% - 35px);
      transform: translateX(-50%) rotate(-90deg);
  }

  .video_slider_nav .slick-arrow.slick-next {
      left     : calc(50% + 35px);
      transform: translateX(-50%) rotate(-90deg);
  }

  .video_slider_for .slick-dots {
      display: none !important;
  }
}

@media(max-width:991px) {
  .video_slide_for_item {
      padding: 10px 5px;
  }

  .general_fund_nav_slider,
  .video_slider_for {
      margin-bottom: 15px !important;
  }

  .newsong_barcode {
      width: 125px;
      margin-right: -1%;
  }

  .newsong_info {
      padding-right: 320px;
  }

  .newsong_info p {
      font-size: 26px;
      width: 100%;
      text-wrap: nowrap;
  }

  .general_fund {
      margin-bottom: 40px;
      margin-top: 40px;
  }

  .header_nav_block ul {
      flex-direction: column;
      gap           : 20px;
  }

  .header_nav_block {
      position  : absolute;
      top       : 60px;
      left      : 0;
      right     : 0;
      width     : 100%;
      background: #fff;
      padding   : 20px 0;
      z-index   : 1;
      display   : none;
      box-shadow: 0px 22px 14px -14px rgba(15, 14, 91, 0.20);
  }

  .header_mobile_menu {
      display: block;
  }

  .header_mobile_menu_block span {
      width        : 30px;
      height       : 4px;
      display      : block;
      background   : #0f0e5b;
      border-radius: 5px;
      transition   : all .4s ease;
  }

  .header_mobile_menu_block span:not(:last-child) {
      margin-bottom: 5px;
  }

  .header_right_logo,
  .header_left_logo {
      width: auto;
  }

  .header_mobile_menu_block.menu-toggle span:nth-child(2) {
      opacity: 0;
  }

  .header_mobile_menu_block.menu-toggle span:nth-child(1) {
      transform: rotate(45deg) translate(10px, 9px);
      margin   : 0;
  }

  .header_mobile_menu_block.menu-toggle span:nth-child(3) {
      transform: rotate(-45deg);
      margin   : 0;
  }
}

@media(max-width:767px) {
  .header_main {
      padding: 12px 0;
      margin-bottom: 20px;
  }
  .general_fund {
      margin-bottom: 40px;
  }

  .newsong_barcode {
      width: 100px;
  }

  .newsong_info p {
      font-size: 22px;
      text-wrap: nowrap;
  }

  .newsong_info {
      padding-right: 105px;
  }

  .general_fund_for_btn {
      font-size: 10px;
      padding  : 9px 10px;
      line-height: 3;
  }

  .general_fund_for_slide_title {
      font-size: 24px;
  }

  .general_fund_for_slide_top_row {
      margin-bottom: 2px;
  }

  .general_fund_for_img {
      border-radius: 15px;
  }

  .general_fund_for_info_btn {
      font-size: 12px;
      padding  : 5px 10px;
  }

  .general_fund_for_info_btn_block {
      gap: 10px;
      margin: 10px 0;
  }

  .general_fund_for_info_btn svg {
      width: 12px;
  }

  .general_fund_for_social_icon {
      width : 30px;
      height: 30px;
  }

  .general_fund_for_social_icon svg {
      width : 13px;
      height: 20px;
  }

  .general_fund_for_social_btn {
      gap: 8px;
      cursor: pointer;
  }

  .general_fund_nav_slider .slick-list {
      padding-left: 0 !important;
  }
  .general_fund_for_table_heading h2 {
      font-size: 18px;
  }
  .general_fund_for_table_heading {
      padding: 10px 0;
  }
  .general_fund_for_table_label li {
      padding: 10px 5px;
      font-size: 14px;
  }
  .general_fund_for_table_value li {
      padding: 10px 5px;
      font-size: 12px;
  }
  .general_fund_for_item > div:not(:last-child) {
      margin-bottom: 10px;
  }
  .video_slider_nav_item_block,
  .video_slide_for_item_block,
  .video_slide_for_item_wrapper {
      border-radius: 12px;
  }

  .video_slide_for_button {
      width : 60px;
      height: 60px;
  }

  .video_slide_for_button svg {
      width: 25px;
  }
  .topslideractionBtn{
    position: relative;
    top: unset;
    right: unset;
    margin: 0;
    padding: 0;
    background: transparent;
    justify-content: unset !important;
    flex-wrap: nowrap !important;
    overflow-y: auto;
    margin-bottom: 10px;
  }
  .topslideractionBtn .custom-btn{
    border-bottom: 2px solid #ff6d00;
    color: var(--light-color);
    background: #ff6d00;
    white-space: nowrap;
  }
  a.btn.likeBtnsCount.custom-btn.VoiletBtn {
    width: 100%;
  }
  .main-footer .contact-list-ftr a{
    word-break: break-all;
  }
}

@media(max-width:575px) {
  .header_nav_block {
      top: 50px;
  }
  .header_left_logo_block h5{
      font-size: 10px;
  }
  .header_left_logo_block {
      height: 15px;
  }
  .header_left_logo_block img{
    height: 30px;
    margin-top: -10px;
  }
  .header_right_logo_block {
      width: 95px;
  }
  .header_mobile_menu_block span {
      width: 25px;
      height: 2px;
  }
  .header_mobile_menu_block.menu-toggle span:nth-child(1) {
      transform: rotate(45deg) translate(6px, 6px);
      margin: 0;
  }
  .newsong_title {
      font-size    : 28px;
      margin-bottom: 20px;
  }

  .newsong_info {
      padding-right: 160px;
      margin-bottom: 30px;
  }

  .newsong_info p {
      font-size: 16px;
      width: 100%;
      text-wrap: nowrap;
  }

  .newsong_barcode {
      width: 70px;
      margin-right: -1%;
      right: 3px;
  }

  .newsong_barcode_img {
      border-radius: 5px;
  }

  .general_fund_for_slide_item_block {
      padding: 10px 10px 15px;
  }

  .general_fund_for_slide_title {
      font-size: 22px;
  }

  .general_fund_for_btn_block {
      gap: 5px;
  }

  .general_fund_for_slide_top_row {
      justify-content: center;
      gap            : 10px;
  }

  .general_fund_for_info_btn {
      font-size: 13px;
      padding  : 5px 8px;

  }

  .general_fund_for_info_btn svg {
      width: 10px;
  }

  .general_fund_nav_item_block {
      padding      : 6px;
      border-radius: 15px;
  }

  .general_fund_nav_img_block {
      border-radius: 10px;
  }

  .general_fund_nav_info_block p {
      font-size: 10px;
  }

  .general_fund {
      margin-bottom: 30px;
  }

  .general_fund_nav_slider_arrow .slick-arrow {
      width : 25px;
      height: 25px;
  }

  .general_fund_nav_slider_arrow {
      gap: 12px;
  }

  .general_fund_nav_dots_list li {
      font-size: 12px;
  }

  .video_slide_for_item_block {
      padding: 5px;
  }

  .video_slide_for_item {
      padding: 5px 5px 8px;
  }

  .video_slider_nav .slick-arrow {
      width : 28px;
      height: 28px;
  }

  .video_slider_nav .slick-arrow.slick-next {
      left: calc(50% + 25px);
  }

  .video_slider_nav .slick-arrow.slick-prev {
      left: calc(50% - 25px);
  }
  .general_fund_for_table_heading h2 {
      font-size: 16px;
  }
  .general_fund_for_table_label li {
      padding: 8px 5px;
      font-size: 12px;
  }
  .general_fund_for_table_value li {
      padding: 10px 5px;
      font-size: 10px;
  }
  .checkoutselectedListsItems .storeListedImageView {
    object-fit: contain;
    background: #000;
    width: 100% !important;
    max-height: 110px !important;
    min-height: 110px !important;
  }
  .defaultViewBigTileImg--box {
    max-height: 270px;
  }
  .shoping-button.mt-4.d-flex.flex-lg-row.flex-sm-column-reverse {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  .general_fund_for_info_btn.general_fund_for_info_btn_1.btn {
    margin-left: 0px !important;
  }

}

@media(max-width:370px) {
  .header_left_logo_block {
      height: 12px;
  }
  .header_left_logo_block img{
    height: 30px;
    margin-top: -13px;
  }
  .header_right_logo_block {
      width: 85px;
  }
  .header_mobile_menu_block span {
      width: 22px;
      height: 2px;
  }
  .header_mobile_menu_block.menu-toggle {
      margin-top: -5px;
  }
  .newsong_title {
      font-size: 24px;
  }

  .newsong_info p {
      font-size: 14px;
      width: 100%;
      text-wrap: nowrap;
  }

  .newsong_info {
      padding-right: 135px;
      margin-bottom: 20px;
  }

  .newsong_barcode {
      width: 55px;
      margin-right: -1%;
  }

  .general_fund_for_img_block >p {
      font-size: 10px;
  }

  .general_fund_for_info_btn_block {
      gap: 5px;
  }

  .general_fund_for_slide_item {
      padding: 5px;
  }

  .general_fund {
      margin-bottom: 20px;
      margin-top: 20px;
  }
  .general_fund_for_table_heading h2 {
      font-size: 14px;
  }
  .general_fund_for_table_heading {
      padding: 8px 0;
  }
  .general_fund_for_table_label li {
      padding: 8px 2px;
  }
  .QRBox-content p{
    font-size: 12px;
    margin-right: 110px;
  }
  .scanQRImageShow{
    right: 0px;
  }
  .features-hero-section{
    padding: 29px 20px !important;
  }
  .top-navbar {
      padding: 0 !important;
  }
  .img-fluid.userprofileImg {
    max-width: 25px;
    min-width: 25px;
    min-height: 25px;
    max-height: 25px;
    object-fit: cover;
    border-radius: 100px;
    object-position: center;
    margin-left: 24px;
  }
  .banner-title{
    font-size: 22px;
  }
  .toggle-menu .btn.toggle-action-btn {
    background: transparent !important;
    margin-right: 0px !important;
    padding: 0px !important;
  }
  .brand-logo-link .img-fluid.brand-image.scanQrcodeImage {
    padding: 0px !important;
    width: 100% !important;
    max-width: 200px !important;
  }
  .social-footer-menu.d-flex.align-items-center {
    flex-wrap: wrap;
  }
  .scanQrcodeImage {
    max-width: 110px !important;
    width: 31%;
    padding: 5px !important;
    top: 40% !important;
  }
}



/* platform start */
footer .platform{
  background-color: var(--background-color); border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; width: 100%; margin: 0 auto;
  display: flex; justify-content: center; align-items: center;
   z-index: 1;  transform: translate(0%, -50%);
}

.platform-text { width: 50%; padding:40px 40px 40px 0px; }

.platform-text .title-part h2 { font-size: 44px; text-align: left;  }

.platform-text p { color: var(--dark-blue); text-align: left; margin-top: 15px; }

.platform img {
  width: 400px; height: auto; transform: scale(1.4); margin-bottom: -120px;
}

.platform-img { width: 40%; }

.platform .platform-text a {
  font-size: 18px; font-weight: 400; padding: 14px 35px; color: var(--purple);
  border:2px solid var(--purple); border-radius: 30px; float: right;
}

.platform .platform-text a:hover {
  background-color: var(--purple); color: #fff;

}

/* platform end */


/* footer start */

footer li{
  list-style: none;
}

footer ul li a, footer ul li, footer p{
  color: #fff;
}

footer .footer-inner{
  display: flex; justify-content: space-between; align-items: center;
  padding-bottom:50px; margin-top: -60px;
}

footer .footer-inner .about-footer p{
  color: #fff; text-align: left; margin-top: 30px; font-size: 18px; font-weight: 400;
}

footer .footer-inner .social-links ul{
  display: flex; justify-content: flex-start; align-items: center;
   gap:25px; margin-top: 30px; padding-inline-start: 0px;
}

.social-links .fa{
  color: var(--green); font-size: 20px;
}


footer .links {
  display: flex; align-items: center; justify-content: center; gap: 20px; width: 75%;
}

footer .about-footer { width: 25%; }

footer .contact-details ul li i { padding-right: 15px; }
footer .contact-details ul li { padding-top: 20px; }

footer h3 {
  color:var(--green); font-size: 16px; font-weight: 600; text-transform: capitalize; text-align: left;
}

footer .useful-links ul li {
  text-align: left; padding-top: 5px;
}


footer .about-company .btn-new {  margin-top: 40px;  }

footer .about-company .btn-new a {
  color:#fff; background-color:var(--purple) ; padding: 12px 16px; border-radius: 10px;
   font-size: 18px; font-weight: 500; line-height: 27px;
}

footer .about-company .btn-new a:hover {
 color:var(--purple); background-color: #FFF;
}

footer .about-company .btn-new a i {
  color: var(--green);
}

.footer-bottom { border-top: 1px solid #fff; padding:20px 0px;
  text-align: center;
}

.footer-bottom p, .about-company { font-size: 16px; }


footer .contact-details { width: 30%; margin: 0 auto; }
footer .about-company { width: 36%; margin: 0 auto; }

footer .links ul{
  padding-left: 0rem;
}
/* footer end */


/********************************************************************************/

.title-part h2 strong {
  position: relative;
}
.help .title-part h2 strong::after {
  right: 0;
  top: auto;
  bottom: 0;
  left: auto;
}

/* tablet start */
@media screen and (max-width: 991px) {


/* common start */

h2 { font-size: 30px; line-height: 42px; }
p { font-size: 16px; line-height: 28px; }
/* common end */


/* platform start */
footer .platform{ transform: translate(0%, -30%);  flex-direction: column; }

.platform-text { width: 100%; padding:70px 15px 40px 15px; }

.platform-text .title-part h2 { font-size: 32px; text-align: center;  }

.platform-text p {  text-align: center;  }

.platform-text .title-part h2 strong::after { width: 150px; right:0px; top:20px; }


.platform .btn-new { display: flex; justify-content: center; }

.platform img {
  width: unset; height: auto; transform: none;
}

.platform-img { width: unset; }

.platform .platform-text a { font-size: 16px; padding: 10px 18px; margin-top: 20px; }

/* platform end */



/* footer start */
footer {
  border-top-left-radius: 0%; border-top-right-radius: 0%;
}

footer .footer-inner{   flex-direction: column; }

footer .footer-inner .about-footer p{  font-size: 16px; }

footer .links { flex-direction: column; width: 100%; align-items: flex-start; }

footer .about-footer { width: 100%; }


footer .about-company .btn-new {  margin-top: 40px;  }

.footer-bottom p { font-size: 14px; }


footer .contact-details { width: 100%; margin: 0; }
footer .about-company { width: 100%; margin: 0; }
/* footer end */



}
/* tablet end */



/********************************************************************************/


/* mobile responsive start */
@media screen and (max-width: 480px) {


/* common start */

h2 { font-size: 28px; line-height: 36px; }
/* common end */


/* hero section start */
.hero-section h1 {font-size: 30px; line-height: 42px; }
.hero-section .left small { font-size: 20px; }
.hero-section  p { margin-top: 20px; }
/* hero section end  */


/* section2 start  */
.points .title-part h2 strong::after { width: 200px; top:62px; }
.points .cards{ flex-direction: column; }
/* section2 end  */



/* section4 start  */
.sponsoring .title-part h2 strong::after { width: 200px; right:0px; top:94px; }
/* section4 end  */


/* team section start */
.team { min-height: 100px; }
/* team section end */


/* help section start  */
.help .title-part h2 { font-size: 26px; }

.help .title-part h2 strong::after { width: 200px; right:0px; top:28px; }

.help .cards .card-inner h3 { font-size: 18px;  }
/* help section end  */



/* feature section start  */
.feature .title-part h2 strong::after { width: 200px; right:0px; top:96px; }
/* feature section end  */


/* platform start */
footer .platform{ transform: translate(0%, -30%);  flex-direction: column; }

.platform-text .title-part h2 { font-size: 28px;  }

.platform-text .title-part h2 strong::after { width: 150px; right:0px; top:20px; }


.platform img { width: 100% }
.team-2 img {margin: 0 !important;}
/* platform end */


}
/* mobile responsive end */

/* pricing page mobile responsive start */

@media screen and (max-width: 480px) {
  body {
      overflow-x: hidden !important;
  }

  .pricing-section {
      margin: 70px 0;
  }

  .pricing-section .left {
      width: auto;
      padding: 0;
  }

  .pricing-section .left small {
      font-size: 17px;
      font-weight: 300;
      text-align: center;
  }

  .pricing-section h1 {
      font-size: 35px;
      line-height: 70px;
      text-align: center;
  }

  .left h3 {
      font-size: 20px;
      padding-top: 20px;
      text-align: center;
  }

  .subscription ul li {
      font-size: 14px;
  }

  .pricing-section .right {
      margin-top: 30px;
  }

  .right h3 {
      font-size: 16px;
      font-weight: 400;
  }

  .box-3 h4 {
      position: relative;
      bottom: 16px;
      left: 0;
  }

  .organization {
      margin-bottom: 250px;
      margin-top: 100px;
  }

  h2 {
      font-size: 27px;
      line-height: 35px;
  }

  .organization .title-part h2 strong::after {
      right: 46px;
      top: 129px;
  }

  .title-part h2 strong::after {
      content: '';
      position: absolute;
      background: url(../src/assets/images/shape.png) no-repeat;
      width: 200px;
      height: 20px;
      z-index: -1;
  }

  p {
      font-size: 14px;
      line-height: 25px;
  }

  .organization .title-part p {
      margin-top: 20px;
      text-align: center;
  }

  .right .box-1 {
      width: auto;
      height: 70px;
      flex-shrink: 0;
      border-radius: 10px;
      margin-bottom: 20px;
      padding: 0 10px 0 75px;
  }

  .pricing-section .right {
      width: auto;
      position: relative;
      margin: 0 auto;
      margin-top: 60px;
  }

  footer .about-company .btn-new a {
      color: #fff;
      background-color: var(--purple);
      padding: 15px 20px;
      border-radius: 10px;
      font-size: 15px;
      font-weight: 500;
      line-height: 27px;
  }

  footer .useful-links ul{
      padding-left: 0rem;
  }

  footer .contact-details ul{
      padding-left: 0rem;
  }

  .right h3 {
      font-size: 14px;
      padding-top: 15px;
  }

  .right .box-1 {
      width: auto;
      padding: 0;
  }

  .right .box-2 {
      width: auto;
      padding: 0;
  }

  .right .box-3 {
      width: auto;
      padding: 0;
  }

  .right .box-4 {
      width: auto;
      padding: 0;
  }

  .right .box-5 {
      width: auto;
      padding: 0;
  }

  .right h3 {
      font-size: 14px;
  }

  h4 {
      font-size: 14px;
  }

  .box-3 h3 {
      padding-left: 10px;
  }

  .box-3 h4 {
      position: relative;
      left: 25px;
      bottom: 22px;
  }

  .box-4 h3 {
      padding-left: 10px;
  }

  .box-4 h4 {
      position: relative;
      bottom: 22px;
  }

  .main-box-2::before {
      content: "";
      position: absolute;
      top: 460px;
      left: 234px;
      height: 40px;
      width: 40px;
      border-radius: 10px;
      background: #5D3CD9;
  }

  .main-box::before {
      display: none;
  }

  .organization .organization {
      gap: 20px;
      flex-wrap: wrap;
  }
}



/* tour page css */

.tour-main {
  display: flex;
  justify-content: center;
  margin-top: 100px;
}

.tour-page {
  border-radius: 10px;
  background: #54FBDD;
  width: 834px;
  height: 85px;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
}

.btn-new {
  display: inline-flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 10px;

}

.btn-new button {
  color: #FFF;
  text-align: center;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 10px;
  background: #0F0E5B;
  padding: 10px 18px;
}

.btn-new-2 {
  display: flex;
  justify-content: center;
  margin: 15px;
}

.btn-new-2 button {
  color: #0F0E5B;
  text-align: center;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: transparent;
  border: none;
}

.btn-new-2 button:hover {
  color: #FFF;
  text-align: center;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 10px;
  background: #0F0E5B;
  padding: 5px 10px;
}

.login {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
}
.login-data {
  text-align: right;
}
.login-content h2 {
  color: #0F0E5B;
  font-size: 38px;
  font-family: Poppins;
  font-weight: 300;
  text-transform: capitalize;
  line-height: 80px;
  word-wrap: break-word;
  text-align: right;
}
.login-data input {
  border-radius: 17px;
  border: 1px solid #0F0E5B;
  background: #FDFDFD;
  height: 68px;
  width: 100%;
  padding: 15px;
  color: #0F0E5B;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.login-content {
  padding: 42px;
}
.login-data input.button {
  border-radius: 10px;
  border: 0.1px solid #5D3CD9;
  background: #5D3CD9;
  color: #FFF;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}
.login-data input.button:hover {
  background-color: #fff;
  color: #5D3CD9;
}
a.passcode {
  color: #0F0E5B;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}
.input-icon {
  position: relative;
}

.input-icon i {
  position: absolute;
  top: 50%;
  bottom: 0;
  right: 20px;
  margin: 0;
  font-size: 24px;
  color: #0F0E5B;
  line-height: 24px;
  transform: translateY(-50%);
}

/* common css start */

body {
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden;
}
a { text-decoration: none; font-family: 'Poppins', sans-serif;text-align: start; }
/* li { font-family: var(--font-family); } */
p { font-family: 'Poppins', sans-serif; font-size: 20px; font-weight: 400; line-height: 36px; }

.title-part { position: relative;}
.title-part h2 strong::after {
  content: ''; position: absolute;
  background: url(../src/assets/images/shape.png) no-repeat;
  width: 300px; height: 30px; z-index: -1;
}
/* common css end */


.e-carousel .e-carousel-indicators.e-default .e-indicator-bars {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  pointer-events: auto;
  margin-top: 25px;
}

.e-carousel .e-carousel-indicators.e-default .e-indicator-bar.e-active .e-indicator div {
  background-color: #00FFD1;
  border-color: #00FFD1;
}

.footer .about-company .btn-new a:hover {
  background-color: var(--purple);
  color: #fff;
}

.general_fund_for_btn.active:hover {
  background: transparent;
  color: #5D3CD9;
}

.e-carousel .e-carousel-indicators.e-default .e-indicator-bar.e-active .e-indicator div {
  background-color:#0F0E5B;
  border-color:#0F0E5B;
  width: 38px;
}

.e-carousel .e-carousel-indicators.e-default .e-indicator-bar .e-indicator div {
  background: #0F0E5B;
  border-color: #0F0E5B;
  font-size: 0;
  padding: 0;
  width: 10px;
  height: 10px;
  border: 0;
  border-radius: 50px;
  cursor: pointer;
}
/* css for promocode modal*/

.btn {
--bs-btn-padding-x: 0.75rem;
--bs-btn-padding-y: 0.375rem;
/* --bs-btn-font-family:; */
--bs-btn-font-size: 1rem;
--bs-btn-font-weight: 400;
--bs-btn-line-height: 1.5;
--bs-btn-color: var(--bs-body-color);
--bs-btn-bg: transparent;
--bs-btn-border-width: var(--bs-border-width);
--bs-btn-border-color: transparent;
--bs-btn-border-radius: var(--bs-border-radius);
--bs-btn-hover-border-color: transparent;
--bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
--bs-btn-disabled-opacity: 0.65;
--bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
display: inline-block;
font-family: var(--bs-btn-font-family);
font-weight: var(--bs-btn-font-weight);
line-height: var(--bs-btn-line-height);
text-align: center;
text-decoration: none;
vertical-align: middle;
cursor: pointer;
-moz-user-select: none;
user-select: none;
border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
border-radius: var(--bs-btn-border-radius);
transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
.form-control {
  border: 1px solid #dfdfdf !important;
  padding: 10px 15px !important;
  font-size: 14px !important;
  border-radius: 3px !important;
}

.form-control:focus,
.form-select:focus,
.btn:focus {
box-shadow: none !important;
}

.btn-apply {
font-size: 17px;
text-decoration: none;
margin: 3px;
}

.custom-btn.pinkBtn {
background-color: var(--pink-color);
}

.cursor-pointer {
  cursor: pointer;
}

.form-link:hover {
color: var(--primary-color);
}

.form-link {
font-size: 14px;
display: block;
margin: 10px 0px 20px;
text-align: right;
text-decoration: none;
color: var(--heading-color);
}

.modal-dialog-centered {
margin-right: auto !important;
margin-left: auto !important;
}



.custom-field::placeholder {
color: var(--placeholder-input);
}

.custom-field:focus,
.custom-field:hover {
border-color: var(--text-color);
color: var(--text-color);
background-color: var(--light-color);
box-shadow: none;
}

.custom-btn {
padding: 15px 40px;
font-size: clamp(0.8125rem, 0.7128rem + 0.3191vw, 1rem);
color: var(--light-color);
background-color: var(--violet-color);
}

.custom-btn:hover,
.custom-btn:focus {
color: var(--light-color);
background-color: var(--primary-color);
box-shadow: none;
}

.custom-btn.greenBtn {
background-color: var(--green-color);
}

.custom-btn.pinkBtn {
background-color: var(--pink-color);
}


.checkoutselectedListsItems, .cardProductViewSingle_item{
position: relative;
}
.checkoutselectedListsItems .storeListedImageView {
object-fit: cover;
background: #000;
width: 100% !important;
max-height: 250px;
min-height: 250px;
object-fit: contain;
object-position: center;
}
.checkoutselectedListsItems .nameCartandQuantity h2 {
font-size: 19px;
margin-bottom: 10px;
text-transform: capitalize;
font-weight: 500;
color: #663399;
margin-top: 15px;
max-width: 270px;
overflow: hidden;
text-overflow: ellipsis;
}
.checkoutselectedListsItems .cardProductViewSingle_item {
padding: 20px;
background: #f3f8fc;
border-radius: 10px;
margin-bottom: 10px;
}
.cardProductViewSingle_item  .AttrbuteItemextraAll {
display: flex;
flex-direction: column;
background: white;
padding: 10px;
margin-top: 20px;
}
.cardProductViewSingle_item  .shopping_expreience_cart_remove {
background: #fff;
color: #ee0101;
position: absolute;
top: 10px;
right: 10px;
border: 1px solid #dfdfdf;
box-shadow: 0px 0px 7px rgb(0 0 0 / 9%);
}
.shippingLoginBox .shopping_expreience_login_title {
color: #ff6d00;
font-size: 18px;
text-decoration: underline;
margin: 10px 0px 15px;
display: block;
}
.shippingLoginBox .shopping_expreience_login_list li {
list-style: none;
margin-bottom: 20px;
position: relative;

}
.shippingLoginBox .shopping_expreience_login_list li:last-child{
margin-bottom: 0px;
}
.shippingLoginBox .shopping_expreience_login_list li:before {
content: "\2605";
position: absolute;
left: -29px;
font-size: 24px;
top: 0px;
transform: translateY(-20%);
color: #ff6d00;
}
.shippingLoginBox .shopping_expreience_login_list {
background: #fafafa;
padding: 30px 30px 30px 60px;
margin-bottom: 10px;
}
.shippingLoginBox .shopping_expreience_login_list li p{
font-size: 20px;
line-height: 23px;
color: #01060f;
}
.actionslightBtnLinks {
background: #fafafa;
padding: 10px 20px;
border: 1px solid #dfdfdf;
border-radius: 10px;
margin: 10px 0px;
color: #663399;
cursor: pointer;
}
.actionslightBtnLinks:hover, .actionslightBtnLinks:focus{
background: #ff6d00;
color: #fff;
border-color: #ff6d00;
}
.checkoutselectedListsItems{
max-height: 850px;
overflow: auto;
}
.loginShopAuthForm .btn {
width: 100%;
padding: 10px 20px;
font-size: 18px;
}
.guesttotalView{
color: #4d1e80;
padding: 10px;
background: #fafafa;
margin: 10px 0px;
display: block;
border: 1px solid;
}
.loginShopAuthForm .login-conten{
padding: 20px 0px;
}
.loginShopAuthForm .login-content h2{
font-size: 20px;
color: #502488;
text-align: left;
}
.logoutoptionUser{
font-size: 14px;
color: #C70039;
}
.iframeVideoMW {
overflow: hidden;
}
.submitPay{
background: #1eaf61;
color: white;
display: block;
text-align: center;
}

.slick-list.draggable .cardBlogsTile{
  position: relative !important;
  min-height: 310px;
  height: 100%;
  justify-content: flex-start;
}
.slick-list.draggable .cardBlogsTile.smSizeCard{
  min-height: 250px;
}
.image-content-right-sides .iframeVideoMW:after {
  content: '';
  position: absolute;
  background: linear-gradient(0, transparent 50%, rgba(0, 0, 0, .5));
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.lefthandBoardContent .sub-para span {
  font-size: 14px;
  display: block;
  width: 100%;
}
.slick-list.draggable .ar-wl-card{
  border: 1px solid transparent;
}
.desciption-about-details{
  max-width: 1200px;
  margin: 50px auto !important;
  border: 1px solid #dfdfdf;
  border-radius: 10px;
  border-bottom-width: 4px;
}
.details-ab-content-box {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  color: #1f262e;
  border-bottom: 1px dashed #dfdfdf;
  padding-bottom: 10px;
}
.details-ab-content-box:last-child {

  border-bottom: none;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.slider-container-video.position-relative, .slider-container-images.position-relative {
  overflow-x: hidden;
}
.hrs-timer {
  text-align: center;
  background: white;
  padding: 20px;
  border-radius: 10px;
  margin: 3px 3px;
  border: 1px solid #dfdfdf;
  flex-grow: 1;
}
.timerformat {
  margin-bottom: 10px !important;
  font-size: 14px;
  color: var(--voilet-color);
  line-height: 0px;
}
.timevalue {
  font-family: inherit;
  margin-bottom: 13px;
  font-size: 40px;
}
.bidCoutdown{
  background: #f3f8fc;
  border: 1px solid #a3bdd2;

}
.bid-bx{
  background: #f3f8fc;
  border: 1px solid #a3bdd2;
  padding: 15px;
}
.showmore-desc{
  font-size: 14px;
  color: var(--text-color);
}
.asb-search-btn{
  position: relative;
  top: unset;
  transform: unset;
  right: 0px;
  border: none;
  background: #ececec;
  color: var(--voilet-color);
}
.asb-search-btn svg path{
  fill: var(--voilet-color);
}
.asb-search-btn:hover{
  background: var(--dark-color);
  color: #fff;
}
.asb-search-btn:hover svg path{
  fill: #fff;
}
.contactTitle {
  font-size: clamp(1.5625rem, 1.1828rem + 0.7911vw, 1.875rem);
}
.mailingForm ejs-textbox.form-control, .mailingForm ejs-maskedtextbox.form-control {
  border: none !important;
  padding: 0px !important;
}
.mailingForm input {
  padding: 10px 0px !important;
  background: transparent !important;
  border: none !important;
}
.mailingForm span.e-input-group.e-control-wrapper {
  border: 1px solid #ced4da !important;
}
.mailListBrand{
  background-color: #e4ebef;
}
.mailingContact_box{
  padding: 20px !important;
  background: #fafafa;
  border: 1px solid #f4f4f4;
}

button#icon-button {
  background: transparent;
}
#iframe{
  position: relative;
  top: 3px;
  left: 0;
  border: none;
  overflow: hidden;
  color: black;
  filter: hue-rotate(32deg) saturate(5) saturate(0.5);
}
#iframe:hover{
  filter: hue-rotate(187deg) saturate(5) saturate(1);
}
.referral-btn{
  color: white;
  display: block;
  text-align: center;
  background: #663399;
  padding: 10px 20px;
  border: 1px solid #dfdfdf;
  border-radius: 10px;
  margin: 10px 0px;
  cursor: pointer;
}

img.profile_cirle{
    border-radius:100%;
}

.wordWrapBody td{
  white-space : pre-wrap !important;
  vertical-align: text-top !important;
  min-width: 120px;
}

.card.auctionsCardsListItems {
  padding: 20px !important;
  min-height: 398px;
}
.card.auctionsCardsListItems .card-text.card-title-name {
  padding: 10px 0px 0px !important;
}
.card.auctionsCardsListItems p.currencyCost {
  padding: 10px 0px !important;
}
.card.auctionsCardsListItems .showmore-desc {
  min-height: 42px;
}
.checkoutselectedListsItems.eventCardCheckoutBoxes{
  max-height: unset;
}

.checkoutselectedListsItems.eventCardCheckoutBoxes .storeListedImageView{
  max-height: 170px;
  min-height: 170px;
}

.checkoutselectedListsItems.eventCardCheckoutBoxes .shopping_expreience_cart_remove{
  position: relative; 
  background: transparent;
  top: unset;
  right: unset;
  box-shadow: none;
  padding: 7px 10px;
  transition: all 400ms linear;
}
.checkoutselectedListsItems.eventCardCheckoutBoxes .shopping_expreience_cart_remove:hover{
  background: white;
  border-color: red;
  transition: all 400ms linear;
}
.descEventDetails{
  font-size: 14px;
  line-height: 17px;
  margin: 10px 0px;
}
.checkoutselectedListsItems.eventCardCheckoutBoxes .nameCartandQuantity h2 {
  font-size: 20px;
  margin-bottom: 15px;
  text-transform: capitalize;
  font-weight: 600;
  max-width: unset;
}
.checkoutselectedListsItems.eventCardCheckoutBoxes .text-danger{
  background: #dc3545;
  color: white !important;
  font-size: 15px;
  padding: 10px 10px;
  border-radius: 0px 0px 10px 10px;
  display: block;
  border: 1px solid #fff;
}

.hiddenError{
  display: none !important;
}
.hoverListItems{
  height: 0px;
  opacity: 0;
  visibility: hidden;
  padding: 0px 30px 00px 60px !important;
  transition: all 400ms linear;
}
.hoverListItems.show{
  height: auto;
  opacity: 1;
  padding: 30px 30px 30px 60px !important;
  visibility: visible;
  transition: all 400ms linear;
}
.auctionsItemsCheckList .card-title-name{
  max-width: unset;
  width: 100%;
}
@media screen and (max-width:592px){
  .campaignDetailsView{
    min-height: unset !important;
  }
  
}

.raffleCardCheckoutBoxes {
  max-height: unset;
}

.raffleCardCheckoutBoxes .storeListedImageView{
  max-height: 400px;
  min-height: 400px;
  object-fit: cover;
  object-position: center;
}
.raffleCardCheckoutBoxes .storeListedImageView-sm{
  max-height: 150px;
  min-height: 150px;
  object-fit: cover;
  object-position: center;
}

.AllPrizesRafflesLists h4.itemNameofDetails{
  font-size: 16px;
  font-weight: 700;
  color: #663399;
}
.AllPrizesRafflesLists .note_help_main {
  font-size: 14px;
    margin: 5px 0px;
    color: #783de5;
}
.AllPrizesRafflesLists .note_help_main:hover{
  color: var(--primary-color);
}

.AllPrizesRafflesLists  .extraDescrpt {
  position: fixed;
  bottom: 0px;
  right: 0px;
  width: 100%;
  padding: 20px 30px;
  background: #ffffffa1;
  backdrop-filter: blur(51px);
  z-index: 1;
  box-shadow: 0px -20px 30px -30px;
  
}

.AllPrizesRafflesLists  .extraDescrpt .extraDescrpt-box {
 
  font-size: 13px;
  line-height: 22px;
  color: var(--heading-color);
  margin: 0px;
  position: relative;
}
.AllPrizesRafflesLists .front-name-desc {
  display: block;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}
.AllPrizesRafflesLists .cls-icons {
  position: absolute;
  bottom: 100%;
  right: 10px;
  background: #1a1a1b;
  font-size: 19px;
  padding: 10px 20px;
  cursor: pointer;
  color: white;
}

.raffleCardCheckoutBoxes .text-danger{
  background: #dc3545;
  color: white !important;
  font-size: 15px;
  padding: 10px 10px;
  border-radius: 0px 0px 10px 10px;
  display: block;
  border: 1px solid #fff;
}


@media screen  and (max-width:900px){
  
  .flex-sm-column-reverse{
      flex-wrap: wrap;
      flex-direction: column-reverse;
  }
  .raffleCardCheckoutBoxes .storeListedImageView-sm {
      max-height: 100px;
      min-height: 100px;
      object-fit: cover;
      object-position: center;
  }
  .raffleCardCheckoutBoxes .storeListedImageView{
      max-height: 200px;
      min-height: 200px;
      object-fit: cover;
      object-position: center;
  }
}


@media screen and (max-width:768px){
  .AllPrizesRafflesLists .entireDetails {
    max-height: 240px;
    overflow: auto;
    display: block;
    position: relative;
    padding-bottom: 20px;
  }
  .AllPrizesRafflesLists .entireDetails::-webkit-scrollbar{
    display: none;
  }
  .AllPrizesRafflesLists .entireDetails::-moz-scrollbar{
    display: none;
  }
}
